import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  TableToolbarContent,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  DataTableSkeleton,
  TableProps,
  Pagination,
  TooltipDefinition,
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import {
  ApplicationData,
  DeploymentData,
  DeploymentInstanceData,
  Gateway,
} from '../../models/master';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../models/dataTable';
import images from '../../images/images';
import { VerticalEmptyState } from '../EmptyState/EmptyState';
import './DeploymentsTable.scss';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import Error403Card from '../ErrorState/Error403Card';

interface PropsInterface {
  appDetailsData?: ApplicationData | null;
  rows: DeploymentData[];
  headers: DataTableHeader[];
  instanceMap: Map<string, DeploymentInstanceData[]>;
  loading: boolean;
  style?: any;
  id: any;
  gateways: Gateway[] | null;
  fetchDeploymentsData?: (id: any, flag: string) => void;
  openAddAppDeployment?: () => void;
  onSearchCallBack: (searchVal: string) => void;
  sortRows(arg0: unknown, direction: string): void;
  error403Flag?: boolean;
}

const DeploymentsTable = (Props: PropsInterface) => {
  const {
    appDetailsData,
    rows,
    headers,
    gateways,
    instanceMap,
    loading,
    style,
    openAddAppDeployment,
    onSearchCallBack,
    sortRows,
    error403Flag,
  } = Props;
  const { t } = useTranslation('applicationDetails');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const { trackButtonClicked } = useAnalytics();

  //to merge gateways name with appDeployment rows
  const mergeGateWayNameRows = rows.map(row => {
    const matchedDeployment = gateways?.find(
      gateway => gateway.deployed_in_depl_env_id === row.environment.id
    );

    return {
      ...row,
      name: `${matchedDeployment?.name || '-'} ${row.environment.name}`,
    };
  });

  const getFilteredRows = () => {
    if (!searchText) {
      return [...mergeGateWayNameRows];
    }
    return mergeGateWayNameRows?.filter(deplEnv =>
      deplEnv?.environment.name
        ?.toLowerCase()
        .includes(searchText?.toLowerCase())
    );
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e && e.target) onSearchCallBack(e?.target?.value ?? '');
  };

  const getAppDeploymentClusterID = (deplID: string) => {
    return rows.find(row => row.id === deplID)?.environment.id;
  };

  const getAppDeploymentGatewayID = (deplID: string) => {
    return rows.find(row => row.id === deplID)?.gatewayId;
  };

  /**
   * @description - Sorting function which is called on clicking sortable table headers
   * @param index {number} - Index of the header which is clicked.
   */
  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
    sortRows(
      {
        id: headers[index].originalKey,
        text: headers[index].header,
      },
      newSortDirection
    );
  };

  const emptyState = () => {
    if (getFilteredRows().length === 0 && !loading) {
      return (
        <VerticalEmptyState
          icon={images.NotFoundLarge()}
          header={t('noDeployment')}
          description={t('noDeploymentDes', {
            applicationName: appDetailsData?.name,
          })}
          buttonText={t('notFound.text') ?? ''}
          link={false}
          additionalClassName='small'
          click={() => {}}
        />
      );
    } else {
      return null;
    }
  };

  const render403Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error403Card />
        </div>
      </div>
    );
  };

  if (loading && !error403Flag) {
    return (
      <div className='deployments-table-container'>
        <DataTableSkeleton
          showHeader
          showToolbar
          rowCount={3}
          columnCount={7}
        />
      </div>
    );
  }
  return (
    <div className='deployments-table-container'>
      <DataTable rows={getFilteredRows()} headers={headers} isSortable>
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }: {
          rows: DataTableRow[];
          headers: DataTableHeader[];
          getTableProps: () => TableProps;
          getHeaderProps: any;
          getRowProps: any;
          getToolbarProps: any;
          getTableContainerProps: any;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            {!error403Flag && (
              <TableToolbar {...getToolbarProps()}>
                <span className='application-deployments-description'>
                  {t('deployments.header.intro')}
                </span>
              </TableToolbar>
            )}
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: DataTableHeader, index: number) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({
                        header,
                        isSortable: Boolean(header.sort),
                      })}
                      onClick={() =>
                        header.sort ? handleChangeSort(index) : undefined
                      }
                      isSortHeader={Boolean(header.sort)}
                      sortDirection={
                        headers[index].originalKey === sortKey
                          ? sortDirection
                          : 'NONE'
                      }
                      data-testid={`app-depl-table-head-${header?.key}`}
                      style={header?.style ? { ...header.style } : {}}
                    >
                      {header.key === 'type' ? (
                        <TooltipDefinition
                          className='header-tooltip'
                          direction='top'
                          tooltipText={t(
                            'deployments.tableHeaders.typeTooltip'
                          )}
                        >
                          {header.header}
                        </TooltipDefinition>
                      ) : (
                        header.header
                      )}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              {rows.length > 0 && (
                <TableBody>
                  {rows.length > 0 &&
                    rows.map((row: DataTableRow, index: number) => (
                      <TableRow {...getRowProps({ row })} key={row.id}>
                        {row.cells.map((cell: DataTableCell) => {
                          if (cell.info.header === 'name') {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={'app-depl-table-value-environment'}
                              >
                                <Link
                                  className='gateway-link'
                                  to={`/gatewayDetails?gatewayId=${getAppDeploymentGatewayID(
                                    row.id
                                  )}`}
                                  onClick={() =>
                                    trackButtonClicked(
                                      analyticsData['Gateway Details'].events
                                        .gatewayInstanceLink.props,
                                      analyticsData['Gateway Details'].events
                                        .gatewayInstanceLink.event
                                    )
                                  }
                                >
                                  <div className='gateway-field'>
                                    <GenericTruncateString
                                      str={cell.value.split(' ')[0] || '-'}
                                      tableView={true}
                                    />
                                  </div>
                                </Link>

                                <Link
                                  className='depl-env-link'
                                  to={`/deploymentEnvironmentDetails?deplId=${getAppDeploymentClusterID(
                                    row.id
                                  )}`}
                                  state={{ from: 'applicationDetailsPAge' }}
                                  onClick={() =>
                                    trackButtonClicked(
                                      analyticsData[
                                        'Deployment Environment Details'
                                      ].events.deploymentInstanceLink.props,
                                      analyticsData[
                                        'Deployment Environment Details'
                                      ].events.deploymentInstanceLink.event
                                    )
                                  }
                                >
                                  <div className='appDeployment-field'>
                                    <GenericTruncateString
                                      str={
                                        cell.value.split(' ').slice(2) || '-'
                                      }
                                      tableView={true}
                                    />
                                  </div>
                                </Link>
                              </TableCell>
                            );
                          } else if (cell.info.header === 'location') {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={'app-depl-table-value-location'}
                              >
                                <Link
                                  className='depl-env-link'
                                  to={`/locationDetails?locationId=${cell.value}`}
                                  state={{ from: 'applicationDetailsPage ' }}
                                  onClick={() =>
                                    trackButtonClicked(
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.props,
                                      analyticsData['Application Details']
                                        .events.deploymentInstanceLink.event
                                    )
                                  }
                                >
                                  <GenericTruncateString
                                    str={cell.value}
                                    tableView={true}
                                  />
                                </Link>
                              </TableCell>
                            );
                          } else if (cell.info.header === 'updated_at') {
                            return (
                              <TableCell key={row.id + cell.id}>
                                {cell.value}
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell
                                key={row.id + cell.id}
                                data-testId={`app-depl-table-value-${cell.info.header}`}
                              >
                                {cell.value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
            {rows.length > 0 && (
              <Pagination
                totalItems={rows.length}
                itemsPerPageText={t('deployments.pagination.itemsPerPage')}
                itemRangeText={(min, max, totalLength) => {
                  return t('deployments.pagination.itemKey', {
                    min: min,
                    max: max,
                    total: totalLength,
                  });
                }}
                pageSize={pageSize}
                page={page}
                pageRangeText={(current, total) =>
                  t('deployments.pagination.pageKey', { sum: total })
                }
                pageSizes={[10, 25, 50, 100, 250]}
                onChange={change => handlePagination(change)}
              />
            )}
          </TableContainer>
        )}
      </DataTable>
      {error403Flag ? render403Container() : null}
      {!error403Flag && Props.rows.length === 0 ? emptyState() : null}
    </div>
  );
};

export default DeploymentsTable;
