import { axiosInstance } from './api';
import { MCNM_APP_API_URL } from './config';

//Get all of the applications that MCNM oversees

export async function getApplications(
  fetchDeployments = true,
  fetchServices = true
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application`,
    });
    // need to make separate API calls to get the services and deployments for each application
    const applicationsData = await Promise.all(
      response.data.apps.map(async app => {
        const appId = app.resource_id;
        let deployments = [];
        let services = [];
        let servicePermission = true;
        let deploymentPermission = true;
        if (fetchDeployments) {
          try {
            await getDeployments(appId, true).then(response => {
              deployments = response;
            });
          } catch (error) {
            if (error.response?.status === 403) {
              console.log(error);
              deploymentPermission = false;
            }
          }
        }
        if (fetchServices) {
          try {
            await getApplicationServices(appId, true).then(response => {
              services = response?.svcs;
            });
          } catch (error) {
            if (error.response?.status === 403) {
              console.log(error);
              servicePermission = false;
            }
          }
          app['serviceCount'] = !!services
            ? Array.isArray(services)
              ? services?.length
              : 0
            : '—';
        }

        return {
          ...app,
          services,
          deployments,
          servicePermission,
          deploymentPermission,
        };
      })
    );

    return applicationsData;
  } catch (error) {
    if (error.response.status === 404) {
      return [];
    }
    throw error;
  }
}

// get one of the apps that MCN oversees
export async function getApplication(
  application_id,
  fetchDeployments = true,
  fetchServices = true,
  throwError = false
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}`,
    });
    let deployments = [];
    let services = [];
    let servicePermission = true;
    let deploymentPermission = true;

    try {
      fetchServices &&
        (await getApplicationServices(application_id).then(response => {
          services = response.svcs;
        }));
    } catch (error) {
      if (
        error?.response?.status === 403 &&
        error?.response?.statusText === 'Forbidden'
      ) {
        servicePermission = false;
      }
    }

    try {
      fetchDeployments &&
        (await getDeployments(application_id).then(response => {
          deployments = response;
        }));
    } catch (error) {
      if (
        error?.response?.status === 403 &&
        error?.response?.statusText === 'Forbidden'
      ) {
        deploymentPermission = false;
      }
    }

    return {
      ...response.data,
      services,
      deployments,
      servicePermission,
      deploymentPermission,
    };
  } catch (error) {
    // Note: I added an extra throwError parameter here to make this function backward compatible.
    throw error;
    console.log(error);
  }
}

// add a new application that MCNM should oversee
export async function addApplication(app_data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/application`,
      data: app_data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// delete an existing application that MCNM oversees
export async function deleteApplication(application_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/application/${application_id}`,
    });
    console.log(response.data);
  } catch (error) {
    throw error;
  }
}

// update an existing app that MCNM oversees
export async function updateApplication(application_id, app_data) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_APP_API_URL}/application/${application_id}`,
      data: app_data,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// get all of the deployment for an application that MCNM that oversees
export async function getDeployments(application_id, throwError = false) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment`,
    });
    return response.data.deps;
  } catch (error) {
    console.log(error);
    if (throwError) {
      throw error;
    }
  }
}

//Add a new deployment to application that MCNM should oversee
export async function addDeployment(application_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment`,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

// Delete an existing deployment that MCNM oversees
export async function deleteDeployment(application_id, deployment_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}`,
    });
    console.log(response.data);
  } catch (error) {
    throw error;
  }
}

//Get one of the deployments that MCNM oversees
export async function getDeployment(application_id, deployment_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing deployment that MCNM oversees
export async function updateDeployment(
  application_id,
  deployment_id,
  dep_data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}`,

      data: dep_data,
    });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

//Get all of the Instances for the AppDeployment that MCNM oversees
export async function getAllDeploymentInstance(
  application_id,
  deployment_id,
  view = 'all'
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/instance?visibility=${view}`,
    });
    return response.data;
  } catch (error) {
    return { insts: [] };
    console.log(error);
  }
}

//Add a new instance to deployment that MCNM should oversee
export async function addDeploymentInstance(
  application_id,
  deployment_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/instance`,

      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing instance that MCNM oversees
export async function deleteDeploymentInstance(
  application_id,
  deployment_id,
  instance_id
) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/instance/${instance_id}`,
    });
    console.log(response.data);
  } catch (error) {
    throw error;
  }
}

//Get one of the insts that MCNM oversees
export async function getDeploymentInstance(
  application_id,
  deployment_id,
  instance_id
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/instance/${instance_id}`,
    });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

//Update an existing instance that MCNM oversees
export async function updateDeploymentInstance(
  application_id,
  deployment_id,
  instance_id,
  instance_data
) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/instance/${instance_id}`,

      data: instance_data,
    });
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

//Get all of the Services for the Application that MCNM oversees
export async function getApplicationServices(
  application_id,
  throwError = false
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service`,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (throwError) {
      throw error;
    }
  }
}

//Add a new service to application that MCNM should oversee
export async function addApplicationService(application_id, service_data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service`,

      data: service_data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing service that MCNM oversees
export async function deleteApplicationService(application_id, service_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service/${service_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get one of the service that MCNM oversees
export async function getApplicationService(
  application_id,
  service_id,
  throwError = false
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service/${service_id}`,
    });
    return response.data;
  } catch (error) {
    // Note: I added an extra throwError parameter here to make this function backward compatible.
    if (throwError) {
      throw error;
    }

    console.log(error);
  }
}

// Update an existing svc that MCNM oversees
export async function updateApplicationService(
  application_id,
  service_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_APP_API_URL}/application/${application_id}/service/${service_id}`,

      data: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

// Get all app deployments that MCNM oversees
export async function getApplicationsDeployments() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/deployment`,
    });
    if (response.data.deps === null) {
      return [];
    } else {
      return response.data?.deps;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getDeploymentServiceEndPoints(
  application_id,
  deployment_id,
  view = 'all'
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/service_endpoint?visibility=${view}`,
    });
    return response.data;
  } catch (error) {
    return { svc_endpoints: [] };
  }
}

export async function addDeploymentServiceEndPoint(
  application_id,
  deployment_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/service_endpoint`,

      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing instance that MCNM oversees
export async function deleteDeploymentServiceEndPoint(
  application_id,
  deployment_id,
  svc_endpoint_id
) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/service_endpoint/${svc_endpoint_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateDeploymentServiceEndPoint(
  application_id,
  deployment_id,
  svc_endpoint_id,
  data
) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_APP_API_URL}/application/${application_id}/deployment/${deployment_id}/service_endpoint/${svc_endpoint_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
