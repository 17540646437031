import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import MultiStepTearsheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import DefineEnvironmentDetails from './DefineEnvironmentDetails/DefineEnvironmentDetails';
import CloudSelect from './CloudSelect/CloudSelect';
import isEqual from 'lodash/isEqual';

import {
  addCloudCluster,
  addCloudNode,
  addCloudVpc,
  updateCloudCluster,
  updateCloudNode,
  updateCloudVpc,
} from '../../../controllers/cloudApis';
import { getAllLocations } from '../../../controllers/locationApis';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import {
  Cloud,
  DeploymentEnvironment,
  DeploymentEnvironmentSubtype,
  Location,
  ResourceGroup,
} from '../../../models/master';

import './RegisterDeploymentEnv.scss';
import images from '../../../images/images';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import {
  DEFAULT_NETWORK_SEGMENT_ID,
  orderListAWS,
  orderListAzure,
  orderListGoogle,
  orderListIBM,
  orderListOther,
} from '../../../lib/constants';
import {
  CloudTypes,
  EnvironmentTypes,
  PartitionTypes,
  QueryKeys,
  VisibilityFlags,
} from '../../../lib/enums';
import { inValidateListCacheFn } from '../../../lib/invalidateQueriesFunctions';
import ConnectGateway, {
  ConnectedGatewayStatus,
  DeploymentEnvType,
} from './ConnectGateway/ConnectGateway';
import { getThirdPartyCloudIconsDropdown } from '../../Cloud/ThirdPartyCloudIcons';
import { getPartitions } from '../../../controllers/deploymentEnv';

interface Props {
  open: boolean;
  onClose: () => void;
  refreshData: (deplEnv: DeploymentEnvironment) => void;
  isEditMode: boolean;
  editDeploymentEnvData?: DeploymentEnvironment | null;
  editFormData?: FormData;
  selectTypeId?: string;
  deplEnvSubtypesList: DeploymentEnvironmentSubtype[] | null;
  deploymentEnvList: DeploymentEnvironment[] | null;
  cloudList?: Cloud[];
  hasCloudAuthorization?: boolean;
  selectedLocationProps?: Location | null;
  selectedCloudProps?: Cloud | null;
  deploymentType?: string;
  isManageMode?: boolean;
  disableLocationDropdown?: boolean;
}

export interface ModifiedCloud extends Cloud {
  itemToRender: JSX.Element;
}

export interface FormData {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  location: {
    id: string | null;
    name: string | null;
    type: string | null;
  } | null;
  resource_group: {
    id: string;
    name: string;
  } | null;
  unmanaged: boolean;
  labels: string[];
  description: string;
  type: string;
  subtype: string;
  cloud_id: string;
  is_discovered: boolean;
  auto_discover: boolean;
  deployed_in_vpc: {
    id: string | null;
    name: string | null;
  } | null;
}

interface DataObject {
  [key: string]: any;
}

const defaultFormData = {
  name: {
    value: '',
    error: false,
    errorMessage: '',
  },
  description: '',
  location: {
    id: '',
    name: '',
    type: '',
  },
  resource_group: null,
  unmanaged: false,
  labels: [],
  cloud_id: '',
  type: '',
  subtype: '',
  deployed_in_vpc: {
    id: '',
    name: '',
  },
  is_discovered: false,
  auto_discover: false,
};

const RegisterDeploymentEnv: React.FC<Props> = ({
  open,
  onClose,
  refreshData,
  isEditMode = false,
  editDeploymentEnvData,
  editFormData,
  selectTypeId,
  deplEnvSubtypesList,
  deploymentEnvList,
  cloudList,
  hasCloudAuthorization = true,
  selectedLocationProps,
  selectedCloudProps,
  deploymentType,
  isManageMode = false,
  disableLocationDropdown = false,
}) => {
  const defaultPermissionMap = {
    location: true,
    resourceGroup: true,
  };
  // Note :
  // Please pass clouds with auto_discover off
  const { t } = useTranslation('registerDeploymentEnv');
  const [initialFormDataEdit, setInitialFormDataEdit] =
    useState<FormData>(defaultFormData);
  const [formData, setFormData] = useState<FormData>(
    JSON.parse(JSON.stringify(defaultFormData))
  );

  const [isLoading, setIsLoading] = useState(false);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);

  const [selectedDeplEnvSubtype, setSelectedDeplEnvSubtype] =
    useState<DeploymentEnvironmentSubtype | null>(null);
  const [selectedCloud, setSelectedCloud] = useState<ModifiedCloud | null>(
    null
  );
  const [envResourceType, setEnvResourceType] = useState<
    DeploymentEnvType | string
  >('');

  const [infrastructureGroups, setInfrastructureGroups] = useState<
    ResourceGroup[] | null
  >(null);
  const [locations, setLocations] = useState<Location[] | null>(null);
  const [modifiedCloudList, setModifiedCloudList] = useState<
    ModifiedCloud[] | null
  >(null);

  const [filteredDeplEnvSubtypes, setFilteredDeplEnvSubtypes] = useState<
    DeploymentEnvironmentSubtype[] | null
  >(null);
  const [filteredDeploymentEnvs, setFilteredDeploymentEnvs] = useState<
    DeploymentEnvironment[] | null
  >(null);

  const [hasFailNotification, toggleFailNotification] = useState(false);
  const [isAuthError, setIsAuthError] = useState(false);
  const [inlineNotificationTitle, setInlineNotificationTitle] =
    useState<string>('');
  const [inlineNotificationSubTitle, setInlineNotificationSubTitle] =
    useState<string>('');
  const [inlineNotificationAuthTitle, setInlineNotificationAuthTitle] =
    useState<string>('');
  const [inlineNotificationAuthSubTitle, setInlineNotificationAuthSubTitle] =
    useState<string>('');

  const [firstStepComplete, setFirstStepComplete] = useState<boolean>(false);

  const [isGatewayInstalled, setIsGatewayInstalled] = useState<boolean>(false);
  const [isDefault_Network_Segment, setIsDefault_Network_Segment] =
    useState<boolean>(false);
  const [isNameSpaceRegister, setIsNameSpaceRegister] =
    useState<boolean>(false);
  const [isPartitionMangaed, setIsPartitionMangaed] = useState<boolean>(false);
  const [isSelectedLocationUnmanaged, setIsSelectedLocationUnmanaged] =
    useState<boolean>(false);
  const notification = useContext(NotificationContext);
  const navigate = useNavigate();

  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {
    if (open) {
      getSelectedCloud();
    }
  }, [
    modifiedCloudList,
    open,
    isEditMode,
    isManageMode,
    editFormData,
    selectedCloudProps,
  ]);

  useEffect(() => {
    if (open) {
      if (selectedLocationProps) {
        setIsSelectedLocationUnmanaged(selectedLocationProps.unmanaged);
        selectedLocationProps?.unmanaged === false &&
          handleOnChange('location', {
            id: selectedLocationProps?.resource_id ?? '',
            name: selectedLocationProps?.name ?? '',
            type: selectedLocationProps?.type ?? '',
          });
      }
    }
  }, [open, isEditMode, isManageMode, selectedLocationProps]);

  useEffect(() => {
    if (open) {
      try {
        setIsLoading(true);
        fetchInfrastructureGroups();
        fetchLocations();
      } catch (error) {
        console.log(error);
      }

      if (Array.isArray(cloudList)) {
        let filteredCloudList = cloudList?.filter(
          (cloud: Cloud) => cloud.auto_discover === false
        );
        if (Array.isArray(filteredCloudList)) {
          let newCloudList = filteredCloudList.map((cloud: Cloud) => ({
            ...cloud,
            itemToRender: (
              <div className='option'>
                <span className='cloud-icon'>
                  {getThirdPartyCloudIconsDropdown(cloud?.type)}
                </span>
                <span className='cloud-name'>{cloud?.name ?? '—'}</span>
              </div>
            ),
          }));
          setModifiedCloudList(newCloudList);
        }
      } else {
        setModifiedCloudList([]);
      }
    }
  }, [open, cloudList]);

  useEffect(() => {
    if (editFormData && deplEnvSubtypesList && (isEditMode || isManageMode)) {
      if (editFormData?.subtype) {
        if (Array.isArray(deplEnvSubtypesList)) {
          deplEnvSubtypesList.find((envSubType: any) => {
            if (envSubType?.type_code === editFormData.subtype) {
              handleCloudSubTypeSelect(envSubType);
            }
          });
        }
      }
    }
  }, [editFormData, deplEnvSubtypesList, isEditMode, isManageMode]);

  useEffect(() => {
    if (editFormData && (isEditMode || isManageMode)) {
      let updatedFormData = editFormData;

      // Need to do JSON parse/stringify to resolve mutation issue with labels field
      setInitialFormDataEdit(JSON.parse(JSON.stringify(updatedFormData)));
      setFormData(JSON.parse(JSON.stringify(updatedFormData)));
    }
  }, [editFormData, isEditMode, isManageMode]);

  useEffect(() => {
    if (selectedCloud) {
      filterDeploymentEnvList(selectedCloud);
      filterDeploymentEnvSubTypeList(selectedCloud);
      selectTypeId && fetchPartitions(selectTypeId ?? '');
      handleOnChange('cloud_id', selectedCloud);
    }
  }, [selectedCloud, deplEnvSubtypesList, deploymentEnvList]);

  useEffect(() => {
    Array.isArray(infrastructureGroups) &&
      Array.isArray(locations) &&
      Array.isArray(deplEnvSubtypesList) &&
      Array.isArray(modifiedCloudList) &&
      setIsLoading(false);
  }, [infrastructureGroups, locations, deplEnvSubtypesList, modifiedCloudList]);

  const filterDeploymentEnvList = (cloud: Cloud | null) => {
    let filteredDeploymentEnvsArr: any = [];
    if (Array.isArray(deploymentEnvList)) {
      filteredDeploymentEnvsArr =
        deploymentEnvList?.filter(
          (deplLists: { cloud_id: string }) =>
            deplLists.cloud_id === cloud?.resource_id
        ) ?? [];
    }
    setFilteredDeploymentEnvs(filteredDeploymentEnvsArr);
  };

  const filterDeploymentEnvSubTypeList = (cloud: Cloud | null) => {
    let filteredDeploymentEnvSubTypesArr: any = [];
    if (Array.isArray(deplEnvSubtypesList)) {
      filteredDeploymentEnvSubTypesArr =
        deplEnvSubtypesList?.filter(
          (type: DeploymentEnvironmentSubtype) =>
            type.cloud_type_code === cloud?.type ||
            type.cloud_type_code === 'other'
        ) ?? [];
    }

    sortSubTypesList(filteredDeploymentEnvSubTypesArr);
  };

  const sortSubTypesList = (
    filteredDeplEnvSubtypes: DeploymentEnvironmentSubtype[]
  ) => {
    if (selectedCloud?.type) {
      let sortedSubtypesList: DeploymentEnvironmentSubtype[] = [];

      const fetchListOrder: any = {
        [CloudTypes.AWS]: orderListAWS,
        [CloudTypes.IBM]: orderListIBM,
        [CloudTypes.AZURE]: orderListAzure,
        [CloudTypes.GCP]: orderListGoogle,
        [CloudTypes.OTHER]: orderListOther,
      };

      let orderList = fetchListOrder[selectedCloud?.type] || null;
      if (orderList?.order) {
        orderList?.order.forEach((order: string) => {
          const item = filteredDeplEnvSubtypes?.find(
            (subType: { type_code: string }) => subType.type_code === order
          );
          if (item) sortedSubtypesList.push(item);
        });
      }
      setFilteredDeplEnvSubtypes(sortedSubtypesList);
    }
  };

  const fetchInfrastructureGroups = async () => {
    try {
      await getResourceGroups(true).then(response => {
        const infrastructureGroups = response?.resource_groups?.filter(
          (r: { type: string | undefined }) => r.type === 'infrastructure'
        );
        setInfrastructureGroups(infrastructureGroups ?? []);
      });
    } catch (error) {
      const err = error as AxiosError;
      console.log(err);
      setInfrastructureGroups([]);
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          resourceGroup: false,
        }));
      }
    }
  };

  const fetchLocations = async () => {
    let locations;
    try {
      locations = await getAllLocations(VisibilityFlags.MANAGED);
      setLocations(locations ?? []);
    } catch (error) {
      const err = error as AxiosError;
      console.log(err);
      setLocations([]);
      if (err.response?.status === 403) {
        setPermissionMap(permissionMap => ({
          ...permissionMap,
          location: false,
        }));
      }
    }
  };

  const handleCloudSelect = (cloud: ModifiedCloud | null) => {
    if (cloud) {
      if (selectedCloud?.resource_id !== cloud?.resource_id) {
        setSelectedCloud(cloud);
        setFormData({
          ...formData,
          cloud_id: cloud?.resource_id ?? '',
        });

        !(isEditMode || isManageMode) && handleCloudSubTypeSelect(null);
      }
    }
  };

  const handleCloudSubTypeSelect = (env: any) => {
    setSelectedDeplEnvSubtype(env);
    setEnvResourceType(env?.resource_type ?? '');
  };

  const handleOnChange = (name: string, value: any) => {
    if (name === 'name') {
      // TODO - Check if name should be unique within location
      const valueEmpty = value.trim() !== '' ? false : true;
      let notUnique;
      notUnique =
        Array.isArray(filteredDeploymentEnvs) &&
        filteredDeploymentEnvs?.filter(
          (filteredDeplEnv: any) => filteredDeplEnv.name === value.trim()
        ).length > 0
          ? true
          : false;
      const error =
        valueEmpty ||
        (editFormData?.name.value === value && (isEditMode || isManageMode))
          ? !notUnique
          : notUnique;
      const message = valueEmpty
        ? t('nameEmpty')
        : editFormData?.name.value === value && (isEditMode || isManageMode)
        ? ''
        : t('nameExists');

      setFormData({
        ...formData,
        [name]: {
          value,
          error: error,
          errorMessage: message,
        },
      });
    } else if (name === 'location') {
      setFormData({
        ...formData,
        deployed_in_vpc: { ...defaultFormData.deployed_in_vpc },
        [name]: {
          id: value?.id ?? '',
          name: value?.name ?? '',
          type: value?.type ?? '',
        },
      });
    } else if (name === 'resource_group') {
      setFormData({
        ...formData,
        [name]: {
          id: value?.resource_id ?? null,
          name: value?.name ?? '',
        },
      });
    } else if (name === 'cloud_id') {
      setFormData({
        ...formData,
        cloud_id: value?.resource_id ?? '',
      });
    } else if (name === 'deployed_in_vpc') {
      setFormData({
        ...formData,
        [name]: {
          id: value?.resource_id ?? '',
          name: value?.name ?? '',
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    let payload: any;

    if (isEditMode || isManageMode) {
      payload = {
        ...editDeploymentEnvData,
        unmanaged: formData?.unmanaged ?? '',
        subtype: '',
        type: selectedDeplEnvSubtype?.type_code ?? '',
        name: formData?.name.value.trim(),
        resource_group_id: formData?.resource_group?.id ?? '',
        labels: formData?.labels ?? '',
        description: formData?.description.trim(),
        deployed_in_vpc: formData?.deployed_in_vpc?.id
          ? formData?.deployed_in_vpc?.id
          : null,
      };
    } else {
      payload = {
        name: formData?.name.value.trim(),
        type: selectedDeplEnvSubtype?.type_code ?? '',
        subtype: '',
        location_id: formData?.location?.id ?? '',
        cloud_id: (formData?.cloud_id || selectedCloud?.resource_id) ?? '',
        unmanaged: formData?.unmanaged ?? '',
        description: formData?.description.trim(),
        resource_group_id: formData?.resource_group?.id ?? '',
        deployed_in_vpc: formData?.deployed_in_vpc?.id
          ? formData?.deployed_in_vpc?.id
          : null,
        labels: formData?.labels ?? '',
      };
    }

    try {
      toggleFailNotification(false);
      setIsAuthError(false);
      setInlineNotificationAuthSubTitle('');
      setInlineNotificationAuthTitle('');
      setInlineNotificationTitle('');
      setInlineNotificationSubTitle('');

      const type = selectedDeplEnvSubtype?.resource_type;

      if (isEditMode || isManageMode) {
        if (
          (window?.location?.pathname ?? '').includes(
            '/deploymentEnvironmentDetails'
          )
        ) {
          trackButtonClicked(
            analyticsData['Deployment Environment Details'].events.editDepEnv
              .props,
            analyticsData['Deployment Environment Details'].events.editDepEnv
              .event
          );
        }
      } else {
        trackButtonClicked(
          analyticsData['Deployment Environment'].events.registerDepEnv.props,
          analyticsData['Deployment Environment'].events.registerDepEnv.event
        );
      }
      switch (type) {
        case EnvironmentTypes.CLUSTER:
          const cluster =
            isEditMode || isManageMode
              ? await updateCloudCluster(
                  payload.cloud_id,
                  selectTypeId,
                  payload
                )
              : await addCloudCluster(selectedCloud?.resource_id, payload);
          handleSuccess(cluster);
          break;
        case EnvironmentTypes.VPC:
          const vpc =
            isEditMode || isManageMode
              ? await updateCloudVpc(payload.cloud_id, selectTypeId, payload)
              : await addCloudVpc(selectedCloud?.resource_id, payload);
          handleSuccess(vpc);
          break;
        case EnvironmentTypes.NODE:
          const node =
            isEditMode || isManageMode
              ? await updateCloudNode(payload.cloud_id, selectTypeId, payload)
              : await addCloudNode(selectedCloud?.resource_id, payload);
          handleSuccess(node);
      }
    } catch (error: any) {
      const err = error as AxiosError;
      let errorMessage: string = '';
      if (err.response?.status === 403) {
        setIsAuthError(true);
        setInlineNotificationAuthTitle(t('defineEnvironmentDetails.authTitle'));
        setInlineNotificationAuthSubTitle(
          t('defineEnvironmentDetails.authSubtitle')
        );
      }
      const errorData = err.response?.data as any;
      if (errorData?.error ?? errorData?.status) {
        if (errorData?.error != null) {
          errorMessage = errorData?.error.length > 0 ? errorData?.error : '';
        } else if (errorData?.status != null) {
          errorMessage = errorData?.status.length > 0 ? errorData?.status : '';
        }
      }

      setInlineNotificationTitle(
        isEditMode || isManageMode
          ? (t('editDetails.errorMessage') as string)
          : (t('defineEnvironmentDetails.errorMessage') as string)
      );
      errorMessage.length > 0 &&
        setInlineNotificationSubTitle(
          errorMessage[0].toUpperCase() + errorMessage.slice(1) + '.'
        );
      toggleFailNotification(true);
      return Promise.reject(() => console.log(error));
    }
  };

  const handleSuccess = (response: any) => {
    refreshData(response);
    isEditMode || isManageMode
      ? // Trigger success toastbar Edit Flow
        notification.onTrigger('TOAST', {
          title: t('editDetails.successNotification.title'),
          subtitle: t('editDetails.successNotification.subtitle'),
        })
      : // Trigger success toastbar Register Flow
        notification.onTrigger('TOAST', {
          title: t('successNotification.title'),
          subtitle: t('successNotification.subtitle', {
            environment: formData.name.value,
          }),
          action: t('successNotification.button'),
          onActionClick: () => {
            navigate(
              `/deploymentEnvironmentDetails?deplId=${response?.resource_id}`
            );
          },
        });

    inValidateListCacheFn(QueryKeys.DEPLOYMENTENVIRONMENTS);
    closeTearsheet();
  };

  const handleErrorBarClose = () => {
    toggleFailNotification(false);
    setIsAuthError(false);
    setInlineNotificationAuthSubTitle('');
    setInlineNotificationAuthTitle('');
    setInlineNotificationTitle('');
    setInlineNotificationSubTitle('');
  };

  const closeTearsheet = () => {
    (isEditMode || isManageMode) &&
      editFormData &&
      handleOnChange('unmanaged', !formData.unmanaged);
    setSelectedCloud(null);
    setSelectedDeplEnvSubtype(null);
    setEnvResourceType('');
    (isEditMode || isManageMode) && editFormData
      ? setFormData(editFormData)
      : setFormData(prevState => ({
          ...prevState,
          ...defaultFormData,
        }));
    setFirstStepComplete(false);
    toggleFailNotification(false);
    onClose();
  };

  const getSelectedCloud = () => {
    if (selectedCloudProps) {
      handleCloudSelect({
        ...selectedCloudProps,
        itemToRender: (
          <div className='option'>
            <span className='cloud-icon'>
              {getThirdPartyCloudIconsDropdown(selectedCloudProps?.type)}
            </span>
            <span className='cloud-name'>
              {selectedCloudProps?.name ?? '—'}
            </span>
          </div>
        ),
      });
    } else {
      if (
        (isEditMode || isManageMode) &&
        editFormData &&
        Array.isArray(modifiedCloudList)
      ) {
        const selectedCloud = modifiedCloudList?.find(
          (cloud: Cloud) => cloud.resource_id === editFormData?.cloud_id
        );
        if (selectedCloud != null) {
          handleCloudSelect(
            {
              ...selectedCloud,
              itemToRender: (
                <div className='option'>
                  <span className='cloud-icon'>
                    {getThirdPartyCloudIconsDropdown(selectedCloud?.type)}
                  </span>
                  <span className='cloud-name'>
                    {selectedCloud?.name ?? '—'}
                  </span>
                </div>
              ),
            } ?? null
          );
        }
      }
    }
  };

  const connectGatewayStatus = () => {
    let status: ConnectedGatewayStatus = 'registerNamespace';

    if (isEditMode || isManageMode) {
      if (isGatewayInstalled && !isDefault_Network_Segment) {
        status = 'gatewaysInstalled';
      }
      if (!isGatewayInstalled && isNameSpaceRegister) {
        status = 'nameSpaceExists';
      }
    }
    return status;
  };

  const showEmptyState = () => {
    const emptyObj = hasCloudAuthorization
      ? {
          header: t('noCloudsDefined.header'),
          desc: t('noCloudsDefined.desc'),
          icon: images.noCloudDefinedIcon(),
        }
      : {
          header: t('noCloudAuthorization.header'),
          desc: t('noCloudAuthorization.desc'),
          icon: images.noCloudAccessIcon(),
        };
    return (
      <div className='no-cloud-empty-state'>
        <VerticalEmptyState
          icon={emptyObj.icon}
          header={emptyObj.header}
          description={emptyObj.desc}
        />
      </div>
    );
  };

  const fetchPartitions = async (deployment_env_id: string) => {
    if (!deployment_env_id) {
      return;
    }

    //function to check at least 1 partition has a gateway
    const checkKeyValueExist = (arr: DataObject[], key: string): boolean => {
      return arr.some(obj => obj.hasOwnProperty(key) && obj[key] !== null);
    };
    //function to check all partion at least 1 that unmanaged : false
    const checkKeyValueUnmanaged = (
      arr: DataObject[],
      key: string
    ): boolean => {
      return arr.some(obj => obj.hasOwnProperty(key) && obj[key] === false);
    };

    try {
      const partitionData = await getPartitions(deployment_env_id);
      if (
        Array.isArray(partitionData?.partitions) &&
        partitionData?.partitions.length > 0
      ) {
        if (checkKeyValueExist(partitionData?.partitions, 'gateway_id')) {
          setIsGatewayInstalled(true);
        } else {
          setIsGatewayInstalled(false);
        }
        if (
          Object.keys(partitionData?.partitions[0]).includes(
            'network_segment_id'
          ) &&
          partitionData?.partitions[0].network_segment_id ===
            DEFAULT_NETWORK_SEGMENT_ID
        ) {
          setIsDefault_Network_Segment(true);
        }
        if (
          Object.keys(partitionData?.partitions[0]).includes('type') &&
          partitionData?.partitions[0].type === PartitionTypes.NAMESPACE
        ) {
          setIsNameSpaceRegister(true);
        }
        if (
          checkKeyValueUnmanaged(
            partitionData?.partitions,
            VisibilityFlags.UNMANAGED
          )
        ) {
          setIsPartitionMangaed(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkFormValid = () => {
    return selectedDeplEnvSubtype?.type_code &&
      (formData?.cloud_id || selectedCloud?.resource_id) &&
      formData.resource_group?.id &&
      formData.location?.id &&
      !formData.name.error &&
      formData.name.value
      ? true
      : false;
  };

  const enableSaveButton = () => {
    if (isEqual(initialFormDataEdit, formData)) {
      return false;
    } else {
      return checkFormValid();
    }
  };

  return ((Array.isArray(modifiedCloudList) && modifiedCloudList.length > 0) ||
    isEditMode ||
    isManageMode ||
    selectedCloud) &&
    hasCloudAuthorization ? (
    <>
      <MultiStepTearsheet
        className={
          isEditMode || isManageMode
            ? 'edit-env-tearsheet'
            : 'register-env-tearsheet'
        }
        submitButtonText={
          isEditMode || isManageMode
            ? t('editDetails.submitButtonText')
            : t('submitButtonText')
        }
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        description={isEditMode || isManageMode ? '' : t('description')}
        title={
          isManageMode
            ? t('manageDetails.title') + ' ' + initialFormDataEdit?.name?.value
            : isEditMode
            ? t('editDetails.title') + ' ' + initialFormDataEdit?.name?.value
            : t('title')
        }
        open={open}
        onClose={closeTearsheet}
        onRequestSubmit={handleSubmit}
        dataTestId={'register-edit-deployment-env-tearsheet'}
      >
        <CloudSelect
          subtypeList={filteredDeplEnvSubtypes}
          selectedDeplEnvSubtype={selectedDeplEnvSubtype}
          selectSubtypeCallback={handleCloudSubTypeSelect}
          toggleComplete={setFirstStepComplete}
          complete={firstStepComplete}
          cloudList={modifiedCloudList ?? []}
          selectCloudCallback={(cloud: ModifiedCloud | null) =>
            handleCloudSelect(cloud)
          }
          selectedCloud={selectedCloud}
          locations={locations}
          onChange={handleOnChange}
          formData={formData}
          loading={isLoading}
          isEditMode={isEditMode || isManageMode}
          hasLocationAuthorization={permissionMap?.location}
          disableLocationDropdown={disableLocationDropdown}
        />

        <DefineEnvironmentDetails
          selectedCloud={selectedCloud}
          selectedType={selectedDeplEnvSubtype}
          onChange={handleOnChange}
          formData={formData}
          groups={infrastructureGroups}
          isEditMode={isEditMode || isManageMode}
          isManageMode={isManageMode}
          showError={hasFailNotification}
          onCloseRegisterError={handleErrorBarClose}
          titleErrorMessage={
            isAuthError ? inlineNotificationAuthTitle : inlineNotificationTitle
          }
          subTitleErrorMessage={
            isAuthError
              ? inlineNotificationAuthSubTitle
              : inlineNotificationSubTitle
          }
          open={open}
          cloudList={modifiedCloudList ?? []}
          locations={locations}
          selectCloudCallback={(cloud: ModifiedCloud | null) =>
            handleCloudSelect(cloud)
          }
          isFormValid={
            open
              ? isEditMode || isManageMode
                ? enableSaveButton()
                : checkFormValid()
              : true
          }
          isPartitionMangaed={isPartitionMangaed}
          isSelectedLocationUnmanaged={isSelectedLocationUnmanaged}
          hasLocationAuthorization={permissionMap?.location}
          hasResourceGroupAuthorization={permissionMap?.resourceGroup}
        />
        <ConnectGateway
          connectGatewayStatus={connectGatewayStatus()}
          deploymentEnvType={
            isEditMode || isManageMode
              ? deploymentType === EnvironmentTypes.CLUSTER &&
                !isDefault_Network_Segment
                ? EnvironmentTypes.CLUSTER
                : ''
              : envResourceType
          }
          formData={formData}
          showError={hasFailNotification}
          onCloseRegisterError={handleErrorBarClose}
          titleErrorMessage={
            isAuthError ? inlineNotificationAuthTitle : inlineNotificationTitle
          }
          subTitleErrorMessage={
            isAuthError
              ? inlineNotificationAuthSubTitle
              : inlineNotificationSubTitle
          }
        />
      </MultiStepTearsheet>
    </>
  ) : (
    <>
      <WideTearsheet
        className={
          isEditMode || isManageMode
            ? 'edit-env-tearsheet'
            : 'register-env-tearsheet'
        }
        title={
          isEditMode
            ? t('editDetails.title')
            : isManageMode
            ? t('manageDetails.title')
            : t('title')
        }
        description={
          cloudList
            ? hasCloudAuthorization
              ? t('noCloudsDefined.mainDesc')
              : t('noCloudAuthorization.mainDesc')
            : ''
        }
        open={open}
        actions={[
          {
            kind: 'primary',
            label: t('nextButtonText'),
            onClick: () => {},
            disabled: true,
          },
          {
            kind: 'secondary',
            label: t('cancelButtonText'),
            onClick: () => {
              closeTearsheet();
            },
          },
        ]}
      >
        {showEmptyState()}
      </WideTearsheet>
    </>
  );
};

export default RegisterDeploymentEnv;
