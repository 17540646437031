// config file for storing environment variables used across the UI

export const SHOW_MAINTENANCE_BANNER =
  process.env.REACT_APP_ENABLE_MAINTENANCE_BANNER ?? false;

export const SHOW_TECH_PREVIEW_BANNER =
  process.env.REACT_APP_ENABLE_TECH_PREVIEW_BANNER ?? false;

export const MAINTENANCE_START_WINDOW =
  process.env.REACT_APP_MAINTENANCE_START_WINDOW ?? '';

export const MAINTENANCE_END_WINDOW =
  process.env.REACT_APP_MAINTENANCE_END_WINDOW ?? '';

export const SHOW_MESH_AGENT_UPGRADE_BANNER =
  process.env.REACT_APP_ENABLE_MESH_AGENT_UPGRADE_BANNER ?? false;

export const ENABLE_TOPOLOGY_GRAPH_CONFIG =
  process.env.REACT_APP_ENABLE_TOPOLOGY_GRAPH_CONFIG ?? false;

export const SEGMENT_WRITE_KEY = 
  process.env.REACT_APP_SEGMENT_WRITE_KEY ?? "";
