import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tile, Button, Modal } from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import {
  PolicyData,
  Service,
  ServiceData,
  Port,
  ApplicationData,
  NetworkSegment,
  ResourceGroup,
  Deployment,
  DeploymentData,
  ServiceEndPoint,
  Event,
  Gateway,
} from '../../../models/master';
import images from '../../../images/images';
import ServiceTile from './ServiceTile/ServiceTile';
import { VerticalEmptyState } from '../../../components/EmptyState/EmptyState';
import './ServicesTile.scss';
import { useLocation } from 'react-router-dom';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import debounce from 'lodash/debounce';
import RegisterExternalService from '../../ApplicationsContainer/RegisterExternalService/RegisterExternalService';
import EditExternalService from '../EditExternalService/EditExternalService';

type LocationState = {
  resourceType: string;
};

interface Props {
  applicationData: ApplicationData | null;
  deploymentsData: DeploymentData[] | null;
  serviceEndpointsData: ServiceEndPoint[] | null;
  services: any[];
  allEventsData: Event[] | null;
  applicationId: string | null;
  removeService: (id: string) => void;
  onCloseServiceTearsheet: () => void;
  policiesList: PolicyData[] | null;
  networkSegment: NetworkSegment;
  disableDeleteServiceButton: boolean;
  onRegisterService: (service?: Service, deployments?: Deployment[]) => void;
  resourceGroupsData: ResourceGroup[] | null;
  deployments: DeploymentData[] | null;
  externalEndpointMap: Map<string, ServiceEndPoint[]> | null;
  clusterEndpointMap: Map<string, ServiceEndPoint[]> | null;
  gateways: Gateway[] | null;
}

const ServicesTile = (props: Props) => {
  const { t } = useTranslation('applicationDetails');
  const {
    applicationData,
    deploymentsData,
    serviceEndpointsData,
    services,
    allEventsData,
    applicationId,
    removeService,
    onCloseServiceTearsheet,
    policiesList,
    networkSegment,
    disableDeleteServiceButton,
    onRegisterService,
    resourceGroupsData,
    deployments,
    externalEndpointMap,
    clusterEndpointMap,
    gateways,
  } = props;

  const [selectedService, setSelectedService] = useState<ServiceData | null>(
    null
  );
  const [deleteServiceModalOpen, toggleDeleteServiceModal] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState<string | null>(null);
  const [deleteServiceName, setDeleteServiceName] = useState<string | null>(
    null
  );
  const [registerServiceTearsheet, toggleRegisterServiceTearsheet] =
    useState(false);
  const [editServiceTearsheet, toggleEditServiceTearsheet] = useState(false);

  const location = useLocation();
  const state = location.state as LocationState;

  const { trackButtonClicked } = useAnalytics();

  const allPortNumbers: Port[] = services.reduce((acc, service) => {
    return acc.concat(
      service.ports.map((port: Port) => {
        return {
          port_number: port.port_number,
          protocol: port.protocol,
        };
      })
    );
  }, []);

  const openEditServiceModal = (serviceId: string) => {
    const service = services.find(service => service.id === serviceId);

    if (service) {
      setSelectedService(service);
      toggleEditServiceTearsheet(true);
    }
  };

  const confirmDeleteService = () => {
    trackButtonClicked(
      analyticsData['Application Details'].events.deleteService.props,
      analyticsData['Application Details'].events.deleteService.event
    );
    toggleDeleteServiceModal(false);
    deleteServiceId && removeService(deleteServiceId);
  };

  const requestDeleteService = (id: string, name: string) => {
    setDeleteServiceId(id);
    setDeleteServiceName(name);
    toggleDeleteServiceModal(true);
  };

  const closeEditServiceModal = () => {
    onCloseServiceTearsheet();
    setSelectedService(null);
    toggleEditServiceTearsheet(false);
  };

  return (
    <>
      <Tile className='services-tile'>
        <div className='header-container'>
          <div className='header'>
            {services.length > 0
              ? t('servicesTile.header.1', { count: services.length })
              : t('servicesTile.header.0')}
          </div>

          <Button
            className='add-service-button'
            kind='ghost'
            onClick={() => {
              trackButtonClicked(
                analyticsData['Application Details'].events.registerServiceLink
                  .props,
                analyticsData['Application Details'].events.registerServiceLink
                  .event
              );
              toggleRegisterServiceTearsheet(true);
            }}
          >
            {t('servicesTile.addService')}
            <Add16 />
          </Button>
        </div>
        {services.length > 0 ? (
          <div className='services'>
            {services.map(service => (
              <ServiceTile
                key={service.id}
                serviceId={service.id}
                name={service.name}
                ports={service.ports}
                labels={service.labels}
                removeService={requestDeleteService}
                openEditModal={openEditServiceModal}
                onCloseService={closeEditServiceModal}
                policiesList={policiesList}
                deploymentsData={deploymentsData}
                serviceEndpointsData={serviceEndpointsData}
                allEventsData={allEventsData}
              />
            ))}
          </div>
        ) : (
          <VerticalEmptyState
            icon={images.noServicesSmallIcon()}
            header={t('servicesTile.emptyState.header')}
            description={`${t('servicesTile.emptyState.description')}`}
          />
        )}
        {applicationId && registerServiceTearsheet && (
          <RegisterExternalService
            applicationData={applicationData}
            open={registerServiceTearsheet}
            onClose={() => {
              toggleRegisterServiceTearsheet(false);
              onCloseServiceTearsheet();
            }}
            successCallback={(service?: Service, deployments?: Deployment[]) =>
              onRegisterService(service, deployments)
            }
            selectedNetworkSegment={networkSegment}
            mode='addFromDetailsPage'
            resourceGroupData={resourceGroupsData}
          />
        )}
        {editServiceTearsheet && applicationId && selectedService && (
          <EditExternalService
            gateways={gateways ?? []}
            deployments={deployments ?? []}
            applicationId={applicationData?.resource_id ?? ''}
            applicationName={applicationData?.name ?? ''}
            service={selectedService}
            networkSegmentName={networkSegment?.name}
            labels={selectedService?.labels ?? []}
            externalEndpoints={
              externalEndpointMap?.get(selectedService?.id) ?? []
            }
            clusterEndpoints={
              clusterEndpointMap?.get(selectedService?.id) ?? []
            }
            open={editServiceTearsheet}
            closeTearsheet={() => {
              onCloseServiceTearsheet();
              closeEditServiceModal();
            }}
          />
        )}
      </Tile>
      <Modal
        className='delete-service-modal'
        danger
        modalHeading={t('servicesTile.deleteModal.header')}
        onRequestClose={() => toggleDeleteServiceModal(false)}
        onRequestSubmit={debounce(confirmDeleteService, 1000)}
        primaryButtonText={t('servicesTile.deleteModal.confirm')}
        secondaryButtonText={t('servicesTile.deleteModal.cancel')}
        open={deleteServiceModalOpen}
        size='sm'
        primaryButtonDisabled={disableDeleteServiceButton}
      >
        {t('servicesTile.deleteModal.body', {
          serviceName: deleteServiceName,
        })}
      </Modal>
    </>
  );
};

export default ServicesTile;
