import React from 'react';
import {
  TextInput,
  ComboBox,
  Column,
  Row,
  PasswordInput,
  InlineNotification,
  TextArea,
} from 'carbon-components-react';

import { useTranslation } from 'react-i18next';
import { typeList, secretManagerList } from '../constants';
import { Launch16 } from '@carbon/icons-react';
import { SecretManagerConfig } from '../../../models/master';
import { secretStorageTypes } from './RegisterSecret';

const CreateSecretForm = (props: {
  onChange: any;
  formData: any;
  resourceGroupList: any;
  modeType: any;
  secretManagerConfig: string | null | '';
}) => {
  const {
    onChange,
    formData,
    resourceGroupList,
    modeType,
    secretManagerConfig,
  } = props;
  const { t } = useTranslation('secretManager');
  return (
    <div className='create-secrets-form'>
      {secretManagerConfig === secretStorageTypes[1] && (
        <Row className='row'>
          <Column md={8}>
            <div className='secret-details-doc'>
              <div className='doc-info-header'>{t('docLink.header')}</div>
              <div className='doc-info-details'>
                <div className='info-details'>{t('docLink.info')}</div>
                <a
                  href={
                    navigator.language === 'fr' ||
                    navigator.language === 'fr-FR' ||
                    navigator.language === 'fr-BE' ||
                    navigator.language === 'fr-CA' ||
                    navigator.language === 'fr-CH'
                      ? 'https://ibm.biz/mesh-reg-secret-fr'
                      : 'https://ibm.biz/mesh-reg-secret'
                  }
                  rel='noopener noreferrer'
                  className='section-link secret-info-link'
                  target='_blank'
                >
                  <span className='link-text'>{t('docLink.linkText')}</span>
                  <span className='link-icon'>
                    {' '}
                    <Launch16 />
                  </span>
                </a>
              </div>
            </div>
          </Column>
        </Row>
      )}
      <Row className='row'>
        <Column md={3}>
          {modeType === 'EDIT' ? (
            <TextInput
              labelText={t('createSecret.name.label') as string}
              id='create-secret-name-edit'
              value={formData.name.value}
              name='secret-name'
              placeholder={t('createSecret.name.placeholder')}
              readOnly
            />
          ) : (
            <TextInput
              labelText={t('createSecret.name.label') as string}
              id='create-secret-name'
              value={formData.name.value}
              name='secret-name'
              placeholder={t('createSecret.name.placeholder')}
              onChange={e => onChange('name', e.target.value)}
              invalid={formData.name.error}
              invalidText={formData.name.errorMessage}
              autoComplete='off'
              maxLength={60}
            />
          )}
        </Column>
        <Column md={3}>
          <TextInput
            labelText={t('createSecret.secretManagerType.label') as string}
            id='create-secret-manager-type'
            value={
              secretManagerConfig === secretStorageTypes[1]
                ? t(secretManagerList[0]?.label)
                : t(secretManagerList[1]?.label)
            }
            name='secretManagerType'
            placeholder={t('createSecret.secretManagerType.placeholder')}
            readOnly
          />
        </Column>
      </Row>
      <Row className='row'>
        <Column md={3}>
          <ComboBox
            id='create-secret-resourceGroup'
            name='resourceGroup'
            className='form-field'
            selectedItem={formData.resourceGroup?.value as any}
            onChange={data => onChange('resourceGroup', data.selectedItem)}
            items={resourceGroupList ?? []}
            itemToString={item => (item ? item.name : '')}
            translateWithId={t}
            titleText={t('createSecret.resourceGroup.label')}
            placeholder={t('createSecret.resourceGroup.placeholder')}
            invalid={formData.resourceGroup.error}
            invalidText={formData.resourceGroup.errorMessage}
          />
        </Column>
        <Column md={3}>
          {modeType === 'EDIT' ? (
            <TextInput
              labelText={t('createSecret.secretType.label') as string}
              id='create-secret-secretType-edit'
              value={formData?.secretType?.value?.label}
              name='secretType'
              placeholder={t('createSecret.secretType.placeholder')}
              readOnly
            />
          ) : (
            <ComboBox
              id='create-secret-type'
              name='secretType'
              className='form-field'
              selectedItem={formData.secretType?.value as any}
              onChange={data => onChange('secretType', data.selectedItem)}
              items={typeList ?? []}
              itemToString={item => (item ? item.label : '')}
              translateWithId={t}
              titleText={t('createSecret.secretType.label')}
              placeholder={t('createSecret.secretType.placeholder')}
              invalid={formData.secretType.error}
              invalidText={formData.secretType.errorMessage}
            />
          )}
        </Column>
      </Row>
      {secretManagerConfig === secretStorageTypes[1] &&
        formData.secretType?.value?.value &&
        formData.secretType?.value?.value === 'credentials-k8s' && (
          <React.Fragment>
            {' '}
            <Row className='row kubernetesKeyContainer'>
              <Column md={6} className={'formData'}>
                <div className='secretDetailsLabel'>
                  {t('createSecret.secretDetails')}
                </div>
                <div className='info-details docSec'>
                  {t('createSecret.see')}
                  <a
                    href={
                      navigator.language === 'fr' ||
                      navigator.language === 'fr-FR' ||
                      navigator.language === 'fr-BE' ||
                      navigator.language === 'fr-CA' ||
                      navigator.language === 'fr-CH'
                        ? 'https://ibm.biz/mesh-reg-secret-fr'
                        : 'https://ibm.biz/mesh-reg-secret'
                    }
                    rel='noopener noreferrer'
                    className='section-link secret-info-link'
                    target='_blank'
                  >
                    {t('createSecret.userDoc')}
                  </a>
                  {t('createSecret.detailsText')}
                </div>
                <TextArea
                  id='create-secret-k8sCA'
                  labelText={t('createSecret.k8sCA.label')}
                  name='k8sCA'
                  autoComplete='off'
                  placeholder={t('createSecret.k8sCA.placeholder')}
                  onChange={(e: { target: { value: any } }) =>
                    onChange('k8sCA', e.target.value)
                  }
                  value={formData?.k8sCA?.value}
                  maxCount={300}
                  enableCounter={true}
                  readOnly={modeType === 'EDIT'}
                  disabled={modeType === 'EDIT'}
                />
              </Column>
            </Row>
            <Row className='row kubernetesKeyContainer'>
              <Column md={6} className={'formData'}>
                <TextArea
                  id='create-secret-k8sClientCert'
                  labelText={t('createSecret.k8sClientCert.label')}
                  name='k8sClientCert'
                  autoComplete='off'
                  placeholder={t('createSecret.k8sClientCert.placeholder')}
                  onChange={(e: { target: { value: any } }) =>
                    onChange('k8sClientCert', e.target.value)
                  }
                  value={formData?.k8sClientCert?.value}
                  maxCount={300}
                  enableCounter={true}
                  readOnly={modeType === 'EDIT'}
                  invalid={formData?.k8sClientKey?.error}
                  invalidText={formData?.k8sClientCert?.errorMessage}
                  disabled={modeType === 'EDIT'}
                />
              </Column>
            </Row>
            <Row className='row kubernetesKeyContainer'>
              <Column md={6} className={'formData'}>
                <TextArea
                  id='create-secret-k8sClientKey'
                  labelText={t('createSecret.k8sClientKey.label')}
                  name='k8sClientKey'
                  autoComplete='off'
                  placeholder={t('createSecret.k8sClientKey.placeholder')}
                  onChange={(e: { target: { value: any } }) =>
                    onChange('k8sClientKey', e.target.value)
                  }
                  value={formData?.k8sClientKey?.value}
                  maxCount={300}
                  enableCounter={true}
                  readOnly={modeType === 'EDIT'}
                  invalid={formData?.k8sClientCert?.error}
                  invalidText={formData?.k8sClientKey?.errorMessage}
                  disabled={modeType === 'EDIT'}
                />
              </Column>
            </Row>
            <Row className='row kubernetesKeyContainer'>
              <Column md={6} className={'formData'}>
                <TextInput
                  disabled={modeType === 'EDIT'}
                  id='create-secret-k8sClientToken'
                  labelText={t('createSecret.k8sClientToken.label')}
                  name='k8sClientToken'
                  autoComplete='off'
                  placeholder={t('createSecret.k8sClientToken.placeholder')}
                  onChange={(e: { target: { value: any } }) =>
                    onChange('k8sClientToken', e.target.value)
                  }
                  value={formData?.k8sClientToken?.value}
                />
              </Column>
            </Row>
          </React.Fragment>
        )}
      {secretManagerConfig === secretStorageTypes[1] &&
        formData.secretType?.value?.value !== 'cloud-ibm' &&
        formData.secretType?.value?.value !== 'credentials-k8s' &&
        formData.secretType?.value?.value && (
          <Row className='row kubernetesKeyContainer'>
            <div className='secret-accessKeyId'>
              <Column
                md={6}
                className={`${
                  formData?.accessKeyId?.value ? '' : 'password-empty'
                }`}
              >
                <div className='secretDetailsLabel'>
                  {t('createSecret.secretDetails')}
                </div>

                <PasswordInput
                  labelText={
                    t('createSecret.accessKey.accessKeyId.label') as string
                  }
                  id={
                    modeType === 'EDIT'
                      ? 'edit-secret-accessKeyId'
                      : 'create-secret-accessKeyId'
                  }
                  name='accessKeyId'
                  placeholder={
                    modeType === 'EDIT'
                      ? '● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ●'
                      : t('createSecret.accessKey.accessKeyId.placeholder')
                  }
                  autoComplete='off'
                  maxLength={45}
                  value={formData?.accessKeyId?.value}
                  onChange={e => onChange('accessKeyId', e.target.value)}
                  invalid={formData?.accessKeyId?.error}
                  invalidText={formData?.accessKeyId?.errorMessage}
                  showPasswordLabel={t('show')}
                  hidePasswordLabel={t('hide')}
                />
              </Column>
            </div>
            <Column
              md={6}
              className={`secretAccessKeyContainer ${
                formData?.secretAccessKey?.value ? '' : 'password-empty'
              }`}
            >
              <PasswordInput
                labelText={
                  t('createSecret.accessKey.secretAccessKey.label') as string
                }
                id={
                  modeType === 'EDIT'
                    ? 'edit-secret-secretAccessKey'
                    : 'create-secret-secretAccessKey'
                }
                name='secretAccessKey'
                placeholder={
                  modeType === 'EDIT'
                    ? '● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ●'
                    : t('createSecret.accessKey.secretAccessKey.placeholder')
                }
                autoComplete='off'
                maxLength={45}
                value={formData?.secretAccessKey?.value}
                onChange={e => onChange('secretAccessKey', e.target.value)}
                invalid={formData?.secretAccessKey?.error}
                invalidText={formData?.secretAccessKey?.errorMessage}
                showPasswordLabel={t('show')}
                hidePasswordLabel={t('hide')}
              />
            </Column>
          </Row>
        )}
      {secretManagerConfig === secretStorageTypes[1] &&
        formData.secretType?.value?.value === 'cloud-ibm' &&
        formData.secretType?.value?.value && (
          <Row className='row acessKeyContainer'>
            <Column
              md={6}
              className={`${formData?.apiKey?.value ? '' : 'password-empty'}`}
            >
              <div className='secretDetailsLabel'>
                {t('createSecret.secretDetails')}
              </div>
              <PasswordInput
                labelText={t('createSecret.apiKey.label') as string}
                id={
                  modeType === 'EDIT'
                    ? 'edit-secret-apiKey'
                    : 'create-secret-apiKey'
                }
                name='apiKey'
                placeholder={
                  modeType === 'EDIT'
                    ? '● ● ● ● ● ● ● ● ● ● ● ● ● ● ● ●'
                    : t('createSecret.apiKey.placeholder')
                }
                autoComplete='off'
                maxLength={45}
                value={formData?.apiKey?.value}
                onChange={e => onChange('apiKey', e.target.value)}
                invalid={formData?.apiKey?.error}
                invalidText={formData?.apiKey?.errorMessage}
                showPasswordLabel={t('show')}
                hidePasswordLabel={t('hide')}
              />
            </Column>
          </Row>
        )}
      {secretManagerConfig === secretStorageTypes[0] && (
        <React.Fragment>
          <Row className='row path-docs-header'>
            <Column md={8}>
              <div className='secret-details-doc'>
                <div className='doc-info-header'>
                  {t('externalDocLink.header')}
                </div>
                <div className='doc-info-details'>
                  <div className='info-details'>
                    {t('externalDocLink.info')}
                  </div>
                  <a
                    href={
                      navigator.language === 'fr' ||
                      navigator.language === 'fr-FR' ||
                      navigator.language === 'fr-BE' ||
                      navigator.language === 'fr-CA' ||
                      navigator.language === 'fr-CH'
                        ? 'https://ibm.biz/mesh-reg-secret-fr'
                        : 'https://ibm.biz/mesh-reg-secret'
                    }
                    rel='noopener noreferrer'
                    className='section-link secret-info-link'
                    target='_blank'
                  >
                    <span className='link-text'>
                      {t('externalDocLink.linkText')}
                    </span>
                    <span className='link-icon'>
                      {' '}
                      <Launch16 />
                    </span>
                  </a>
                </div>
              </div>
            </Column>
          </Row>
          <Row className='row'>
            <Column md={6}>
              <TextInput
                labelText={t('createSecret.path.label') as string}
                id='create-secret-path'
                name='path'
                placeholder={t('createSecret.path.placeholder')}
                autoComplete='off'
                value={formData?.path?.value}
                onChange={e => onChange('path', e.target.value)}
                invalid={formData?.path?.error}
                invalidText={formData?.path?.errorMessage}
                helperText={t('createSecret.path.helperText')}
              />
            </Column>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
};
export default CreateSecretForm;
