import React, { useEffect, useState } from 'react';
import {
  TextInput,
  FormLabel,
  Dropdown,
  Button,
  Tooltip,
  Grid,
  Column,
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { Add16, Information16, TrashCan16 } from '@carbon/icons-react';

import { EndpointEntry } from './RegisterExternalService';
import { Gateway } from '../../../models/master';

import './AddServiceEndpoint.scss';

let defaultEndpoint = {
  index: 0,
  deploymentId: '',
  isHostname: true,
  gateway: {
    id: '',
    name: '',
    depl_env_id: '',
    partition_id: '',
  },
  targets: '',
  ports: '',
  portsError: false,
  portsErrorMessage: '',
  gatewayError: false,
  targetsError: false,
  targetsErrorMessage: '',
};

const AddServiceEndpoint = ({
  serviceEndpoints,
  gatewayList,
  updateEndpoints,
  editMode = false,
}: {
  serviceEndpoints: EndpointEntry[];
  gatewayList: Gateway[];
  updateEndpoints: (seps: EndpointEntry[]) => void;
  editMode?: boolean;
}) => {
  const { t } = useTranslation('registerExternalService');
  const [availableGateways, setAvailableGateways] = useState<Gateway[]>([]);

  const handleRowChange = (type: string, index: number, payload: any) => {
    const updatedEndpoints = [...serviceEndpoints];
    if (type === 'gateway') {
      setAvailableGateways(prev =>
        prev.filter(
          (gw: Gateway) =>
            gw.deployed_in_depl_env_id !== payload.deployed_in_depl_env_id &&
            gw.deployed_in_partition_id !== payload.deployed_in_partition_id
        )
      );
      updatedEndpoints[index] = {
        ...updatedEndpoints[index],
        [type]: {
          id: payload.resource_id,
          name: payload.name,
          depl_env_id: payload.deployed_in_depl_env_id,
          partition_id: payload.deployed_in_partition_id,
        },
      };
    } else {
      updatedEndpoints[index] = {
        ...updatedEndpoints[index],
        [type]: payload,
      };
    }
    updateEndpoints(updatedEndpoints);
  };

  const handleRemoveRow = (sepToRemove: EndpointEntry) => {
    // add back gateway to list of available gateways
    const gatewayRemoved = gatewayList.find(
      (gw: Gateway) => gw.resource_id === sepToRemove.gateway.id
    );
    if (gatewayRemoved) {
      setAvailableGateways(prev => [...prev, gatewayRemoved]);
    }
    const updatedEndpoints: EndpointEntry[] = [];
    serviceEndpoints.map((sep: EndpointEntry) => {
      if (sep.index !== sepToRemove.index) {
        // we must shift index to the left to account for removed object
        updatedEndpoints.push({
          ...sep,
          index: sep.index > sepToRemove.index ? sep.index - 1 : sep.index,
        });
      }
    });
    updateEndpoints(updatedEndpoints);
  };

  const handleAddRow = () => {
    const updatedEndpoints = [
      ...serviceEndpoints,
      {
        ...defaultEndpoint,
        index: serviceEndpoints?.length ?? 0,
      },
    ];

    updateEndpoints(updatedEndpoints);
  };

  useEffect(() => {
    let gatewayIds: string[] = [];
    serviceEndpoints.forEach((sep: EndpointEntry) => {
      if (sep.gateway.id !== '') {
        gatewayIds.push(sep.gateway.id);
      }
    });
    const availableGws = gatewayList.filter(
      (gw: Gateway) =>
        !gatewayIds.includes(gw.resource_id) &&
        gw.deployed_in_depl_env_id !== '' &&
        gw.deployed_in_partition_id !== ''
    );
    setAvailableGateways(availableGws);
  }, [gatewayList]);

  return (
    <Grid className='add-svc-endpoint-component'>
      <Column lg={12}>
        {serviceEndpoints &&
          serviceEndpoints.length > 0 &&
          serviceEndpoints.map((sep: EndpointEntry, index: number) => (
            <Grid narrow className='endpoint-row' key={index}>
              <Column lg={4}>
                {sep.index === 0 && (
                  <FormLabel className='input-label'>
                    {t('serviceDetails.endpoints.gateways.label')}
                  </FormLabel>
                )}
                <Dropdown
                  readOnly={editMode && sep.resource_id}
                  id='select-gw-dropdown'
                  light
                  titleText=''
                  invalid={sep.gatewayError}
                  invalidText={t('serviceDetails.endpoints.emptyError')}
                  label={t('serviceDetails.endpoints.gateways.placeholder')}
                  selectedItem={sep.gateway.id !== '' ? sep.gateway : null}
                  size='md'
                  items={availableGateways}
                  itemToString={(item: Gateway) => item?.name ?? ''}
                  onChange={(e: any) =>
                    handleRowChange('gateway', sep.index, e.selectedItem)
                  }
                />
              </Column>
              <Column lg={4}>
                {sep.index === 0 && (
                  <FormLabel className='input-label'>
                    {t('serviceDetails.endpoints.targets.label')}
                    <Tooltip
                      label={t('serviceDetails.endpoints.targets.tooltip')}
                      className='info-tooltip'
                    >
                      <Information16 />
                    </Tooltip>
                  </FormLabel>
                )}
                <TextInput
                  light
                  invalid={sep.targetsError}
                  invalidText={sep.targetsErrorMessage}
                  id='targets-input'
                  value={sep.targets}
                  labelText={''}
                  onChange={(e: any) => {
                    handleRowChange('targets', sep.index, e?.target?.value);
                  }}
                  placeholder={t(
                    'serviceDetails.endpoints.targets.placeholder'
                  )}
                  size='md'
                  type='text'
                />
              </Column>
              <Column lg={serviceEndpoints?.length > 1 || editMode ? 3 : 4}>
                {sep.index === 0 && (
                  <FormLabel className='input-label'>
                    {t('serviceDetails.endpoints.ports.label')}
                    <Tooltip
                      label={
                        editMode
                          ? t('serviceDetails.endpoints.ports.editTooltip')
                          : t('serviceDetails.endpoints.ports.tooltip')
                      }
                      className='info-tooltip'
                    >
                      <Information16 />
                    </Tooltip>
                  </FormLabel>
                )}
                <TextInput
                  light
                  readOnly={editMode}
                  disabled={index !== 0}
                  invalid={sep.portsError}
                  invalidText={sep.portsErrorMessage}
                  id='ports-input'
                  labelText={''}
                  onChange={(e: any) =>
                    handleRowChange('ports', sep.index, e?.target?.value)
                  }
                  value={serviceEndpoints[0].ports}
                  placeholder={t('serviceDetails.endpoints.ports.placeholder')}
                  size='md'
                  type='text'
                />
              </Column>
              {(serviceEndpoints?.length > 1 || editMode) && (
                <Column lg={1} className='delete-button-column'>
                  <Button
                    className='delete-endpoint-button'
                    size='md'
                    hasIconOnly
                    kind='ghost'
                    iconDescription={t('serviceDetails.endpoints.removeText')}
                    renderIcon={TrashCan16}
                    onClick={() => handleRemoveRow(sep)}
                  />
                </Column>
              )}
            </Grid>
          ))}
        <div
          className={
            editMode && serviceEndpoints?.length === 0
              ? 'add-endpoint-button'
              : 'add-endpoint-row'
          }
        >
          <Button
            size='sm'
            kind={
              editMode && serviceEndpoints?.length === 0 ? 'tertiary' : 'ghost'
            }
            onClick={() => handleAddRow()}
            renderIcon={Add16}
          >
            {t('serviceDetails.endpoints.addText')}
          </Button>
        </div>
      </Column>
    </Grid>
  );
};

export default AddServiceEndpoint;
