import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import APIKeysTable from './APIKeysTable';
import Header from '../../components/Header/Header';
import { AppliedFilter, APIKey, Error500Type } from '../../models/master';

import {
  getAPIKeys,
  addAPIKey,
  deleteAPIKey,
} from '../../controllers/APIKeyApis';

import './APIKeyManager.scss';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { InlineNotification } from 'carbon-components-react';
import APIKeyCustomModal from '../../components/APIKeyCustomModal/APIKeyCustomModal';

const APIKeyManager = () => {
  const { t } = useTranslation('apiKeyManager');
  const [APIKeysData, setAPIKeysData] = useState<APIKey[] | null>(null);

  const [generatedKey, setGeneratedKey] = useState('');
  const [showCreateAPIKey, setShowCreateAPIKey] = useState(false);

  const [loading, toggleLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [filteredData, setFilteredData] = useState<APIKey[] | null>(null);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const { pageViewed, trackButtonClicked } = useAnalytics();

  useEffect(() => {
    refreshData();
    pageViewed('Admin API Keys');
  }, []);

  const resetState = () => {
    hasError && setErrorMessage('');
    setHasError(false);
    setAPIKeysData(null);
    setFilterApplied([]);
    setFilteredData(null);
    setSortKey('');
    setSortDirection('NONE');
  };

  const refreshData = async () => {
    try {
      resetState();
      toggleLoading(true);

      const response = await getAPIKeys();

      setAPIKeysData(response as APIKey[]);

      if (error500) {
        setError500(null);
      }
    } catch (error: any) {
      console.error(error);
      setAPIKeysData([]);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      }

      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      toggleLoading(false);
    }
  };

  const handleGenerateKey = async (keyName: string) => {
    try {
      trackButtonClicked(
        analyticsData['Admin API Keys'].events.generateAPIKey.props,
        analyticsData['Admin API Keys'].events.generateAPIKey.event
      );
      setHasError(false);
      setKeyLoading(true);

      const response = await addAPIKey(keyName);

      const generatedKey = response.apiKey;

      setGeneratedKey(generatedKey);
      refreshData();
    } catch (error: any) {
      console.error(error);
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      }
      setHasError(true);
      setErrorMessage(errorMessage);
    } finally {
      setKeyLoading(false);
    }
  };

  const handleDeleteKey = async (key: string) => {
    try {
      trackButtonClicked(
        analyticsData['Admin API Keys'].events.deleteAPIKey.props,
        analyticsData['Admin API Keys'].events.deleteAPIKey.event
      );
      await deleteAPIKey(key);

      const newAPIKeysData =
        APIKeysData?.filter(data => data.apiKey !== key) ?? null;
      setAPIKeysData(newAPIKeysData);

      const newFilteredData =
        filteredData?.filter(data => data.apiKey !== key) ?? null;
      setFilteredData(newFilteredData);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;
      if (err.response?.status === 403) {
        navigate('/403');
      }
    }
  };

  const handleOnClose = () => {
    setShowCreateAPIKey(false);
    setGeneratedKey('');
  };

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  return (
    <div className='apikey-container'>
      <Header
        title={t('header')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        actions={[
          {
            kind: 'primary',
            onClick: () => {
              trackButtonClicked(
                analyticsData['Admin API Keys'].events.createAPIKey.props,
                analyticsData['Admin API Keys'].events.createAPIKey.event
              );
              setShowCreateAPIKey(true);
            },
            text: t('createAPIKey'),
            disabled: loading,
          },
        ]}
      />
      <div className='page-content'>
        <div className='body'>
          <APIKeysTable
            loading={loading}
            elementCount={
              Array.isArray(APIKeysData)
                ? filterApplied.length > 0 && Array.isArray(filteredData)
                  ? filteredData.length
                  : APIKeysData.length
                : 0
            }
            filteredDataSet={
              Array.isArray(APIKeysData)
                ? filterApplied.length > 0 && Array.isArray(filteredData)
                  ? filteredData
                  : APIKeysData
                : null
            }
            filteredDataCallback={data => {
              data && setFilteredData(data as APIKey[] | []);
              setPageNumber(1);
            }}
            data={APIKeysData}
            filtersSelected={filterApplied as any}
            filtersAppliedCallback={data => {
              setFilterApplied(data);
            }}
            onRefresh={refreshData}
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            sortRows={handleTableSort}
            onPageChange={(pageData: any) => setPageChange(pageData)}
            filters={[]}
            sortKey={sortKey}
            sortDirection={sortDirection}
            onDeleteKey={handleDeleteKey}
            error500Flag={!!error500}
          />
        </div>
      </div>
      <APIKeyCustomModal
        open={showCreateAPIKey}
        customClassName='create-api-key-modal'
        apiKey={generatedKey}
        apiKeyLabel={t('form.apiKeyLabel')}
        showAPIKeyLabel={t('form.showAPIKeyLabel')}
        hideAPIKeyLabel={t('form.hideAPIKeyLabel')}
        copyButtonText={t('form.copyButtonText')}
        copyIconTooltipText={t('form.tooltipText')}
        hasAPIKeyVisibilityToggle={true}
        closeButtonText={t('form.closeButtonText')}
        cancelButtonText={t('form.cancelButtonText')}
        generateSuccessTitle={t('form.generateSuccessTitle')}
        generateButtonText={t('form.generateButtonText')}
        generateSuccessBody={
          <InlineNotification
            className='generate-key-success-message'
            lowContrast
            kind='info'
            title={t('form.successTitle')}
            subtitle={t('form.successText')}
            hideCloseButton
          />
        }
        modalTitle={t('form.title')}
        modalBody={t('form.body')}
        nameLabel={t('form.nameLabel')}
        namePlaceholder={t('form.namePlaceHolder')}
        nameRequired={true}
        onRequestGenerate={handleGenerateKey}
        loading={keyLoading}
        loadingText={t('form.loadingText')}
        onClose={handleOnClose}
        onCancel={() => {
          setShowCreateAPIKey(false);
          setGeneratedKey('');
          setHasError(false);
        }}
        error={hasError}
        errorText={errorMessage}
      />
    </div>
  );
};

export default APIKeyManager;
