import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import GenericManageButton from '../../../components/GenericManageButton/GenericManageButton';
import {
  Button,
  Column,
  Dropdown,
  Row,
  SkeletonPlaceholder,
  TextArea,
  TextInput,
} from 'carbon-components-react';
import { NetworkSegment, ResourceGroup } from '../../../models/master';
import { Add16, Edit16 } from '@carbon/icons-react';

import './GatewayDetails.scss';
import { Cluster } from '../DeployGateway';
import { Accordion } from '@carbon/react';
import { AccordionItem } from '@carbon/react';
import AddLabels from '../../../components/AddLabels/AddLabels';
import images from '../../../images/images';

interface Props {
  gatewayData: any;
  formData: any;
  id: string;
  onChange: (name: string, value: any, subKey?: string) => void;
  resourceGroupList: ResourceGroup[];
  networkSegments: NetworkSegment[];
  openNetworkSegmentTearsheet: () => void;
  openDeploymentEnvTable: () => void;
  onSelectNetworkSegment: (data: NetworkSegment) => void;
  selectedNetworkSegment: any;
  selectedDepEnv: Cluster | null;
  isDropDownListDataLoading: boolean;
  connectClusterDataLoading: boolean;
}

const GatewayDetails: React.FC<Props> = ({
  gatewayData,
  formData,
  id,
  onChange,
  resourceGroupList,
  networkSegments,
  openNetworkSegmentTearsheet,
  onSelectNetworkSegment,
  openDeploymentEnvTable,
  selectedNetworkSegment,
  selectedDepEnv,
  isDropDownListDataLoading,
  connectClusterDataLoading,
}) => {
  const { t } = useTranslation('deployGateway');

  const nextFn = () => {};

  return (
    <CreateTearsheetStep
      className='deploy-gateway-details'
      fieldsetLegendText={
        <div className='tearsheet-header'>
          {t('gatewayDetails')}
          <GenericManageButton
            isCheckboxDisabled={true}
            checked={true}
            showTooltip={false}
            key='managedStatus'
            id={`managedBtn-${id}`}
            handleCheckbox={(checked: boolean) => null}
          />
        </div>
      }
      secondaryLabel={t('gatewayDetails')}
      disableSubmit={
        isDropDownListDataLoading ||
        connectClusterDataLoading ||
        !selectedDepEnv ||
        !selectedNetworkSegment ||
        !formData?.name?.value ||
        formData?.name?.error
      }
      onNext={nextFn}
      // onMount={onMount}
    >
      <Row className='row'>
        <Column md={4} className='deploy-gateway-col'>
          <TextInput
            labelText={t('gatewayDetailsForm.name.label') as string}
            id='gateway-name'
            value={formData?.name.value}
            name='name'
            placeholder={t('gatewayDetailsForm.name.placeholder')}
            onChange={e => onChange('name', e.target.value)}
            invalid={formData?.name.error}
            invalidText={formData?.name.errorMessage}
            autoComplete='off'
            maxLength={60}
            onBlur={e => onChange('name', e.target.value)}
          />
        </Column>
        <Column md={4} className='deploy-gateway-col'>
          <TextInput
            labelText={t('gatewayDetailsForm.compactibility.label') as string}
            id='network-seg-compactibility'
            value={t('gatewayDetailsForm.compactibility.rhsi')}
            name='compactibility'
            onChange={e => null}
            readOnly={true}
          />
        </Column>
      </Row>
      <Row>
        <Column md={4}>
          {isDropDownListDataLoading ? (
            <>
              <div className='form-field-segment'>
                {t('gatewayDetailsForm.networkSegment.label')}
              </div>
              <SkeletonPlaceholder className='deploy-gateway-skelton' />
            </>
          ) : (
            <Dropdown
              id='gateway-network-segment'
              className='form-field'
              items={
                networkSegments
                  ? [
                      ...networkSegments?.filter(
                        networkSegment =>
                          networkSegment?.compatibility_set === 'RHSI'
                      ),
                      {
                        name: t('createNetworkSegmentButton'),
                        resource_id: 'create-network-segment',
                        labels: [],
                        resource_group_id: '',
                        compatibility_set: 'RHSI',
                      },
                    ]
                  : []
              }
              itemToString={item => item?.name}
              renderSelectedItem={item =>
                item?.resource_id !== 'create-network-segment'
                  ? `${item?.name}`
                  : t('gatewayDetailsForm.networkSegment.placeholder')
              }
              selectedItem={selectedNetworkSegment}
              itemToElement={(item: NetworkSegment) => {
                if (item?.resource_id !== 'create-network-segment') {
                  return (
                    <span>{`${item?.name} (${t(
                      item?.compatibility_set
                    )})`}</span>
                  );
                } else {
                  return (
                    <div
                      className='create-network-segment-button'
                      onClick={openNetworkSegmentTearsheet}
                    >
                      {`${item?.name} +`}
                    </div>
                  );
                }
              }}
              onChange={(e: any) => {
                e.selectedItem.resource_id !== 'create-network-segment' &&
                  onSelectNetworkSegment(e.selectedItem);
              }}
              label={t('gatewayDetailsForm.networkSegment.placeholder')}
              titleText={t('gatewayDetailsForm.networkSegment.label')}
            />
          )}
        </Column>
        <Column md={4}>
          {isDropDownListDataLoading ? (
            <>
              <div className='form-field-segment'>
                {t('gatewayDetailsForm.resourceGroup.label')}
              </div>
              <SkeletonPlaceholder className='deploy-gateway-skelton' />
            </>
          ) : (
            <Dropdown
              id='gateway-resource-group'
              className='resourceGroup'
              selectedItem={formData?.resourceGroup?.value as any}
              onChange={data => onChange('resourceGroup', data.selectedItem)}
              items={resourceGroupList ?? []}
              itemToString={item => (item ? item.name : '')}
              titleText={t('gatewayDetailsForm.resourceGroup.label')}
              label={''}
              placeholder={t('gatewayDetailsForm.resourceGroup.placeholder')}
              translateWithId={t}
            />
          )}
        </Column>
      </Row>
      {!selectedDepEnv ? (
        <Row>
          <Column md={4}>
            <div className='deploy-in-cluster-section'>
              <div className='heading'>
                {t('gatewayDetailsSection.deployButtonSectionHeading')}
              </div>
              <div className='sub-heading'>
                {t('gatewayDetailsSection.deployButtonSectionSubHeading')}
              </div>
              {connectClusterDataLoading ? (
                <SkeletonPlaceholder className='deploy-gateway-skelton' />
              ) : (
                <div className='select-cluster-section'>
                  <div className='namespaceLabel'>
                    {t('gatewayDetailsSection.namespace')}
                  </div>
                  <div className='namespaceVal'>
                    {gatewayData?.namespace_name ?? '_'}
                  </div>
                  <Button
                    kind='primary'
                    className='add-deployment-button'
                    renderIcon={Add16}
                    onClick={openDeploymentEnvTable}
                    disabled={selectedNetworkSegment == null}
                  >
                    <span className='text'>
                      {t('gatewayDetailsSection.selectClusterBtn')}
                    </span>
                  </Button>
                </div>
              )}
            </div>
          </Column>
        </Row>
      ) : (
        <div className='deploy-in-cluster-section'>
          <Row>
            <Column md={8}>
              <div className='heading'>
                {t('gatewayDetailsSection.deployButtonSectionHeading')}
              </div>
              <div className='sub-heading'>
                {t('gatewayDetailsSection.deployButtonSectionSubHeading')}
              </div>
            </Column>
          </Row>
          <div className='select-cluster-section'>
            <Row>
              <Column md={6}>
                <div className='namespaceLabel'>
                  {t('gatewayDetailsSection.namespace')}
                </div>
                <div className='namespaceVal'>
                  {gatewayData?.namespace_name ?? '_'}
                </div>
              </Column>
              <Column md={2} className='editBtnContainer'>
                <Button
                  kind='tertiary'
                  className='edit-deployment-button'
                  renderIcon={Edit16}
                  onClick={() => {
                    openDeploymentEnvTable();
                  }}
                >
                  <span className='text'>
                    {t('gatewayDetailsSection.form.editBtnLabel')}
                  </span>
                </Button>
              </Column>
            </Row>
            <Row>
              <Column md={2}>
                <div className='namespaceLabel'>
                  {t('gatewayDetailsSection.form.clusterNameLabel')}
                </div>
                <div className='subDetailsVal'>
                  {formData.deployed_in_depl_env_id.value.name}
                </div>
              </Column>
              <Column md={2}>
                <div className='namespaceLabel'>
                  {t('gatewayDetailsSection.form.cloudNameLabel')}
                </div>
                <div className='subDetailsVal'>{formData.cloud.value.name}</div>
              </Column>
              <Column md={2}>
                <div className='namespaceLabel'>
                  {t('gatewayDetailsSection.form.locationNameLabel')}
                </div>
                <div className='subDetailsVal'>
                  {formData.location.value.name}
                </div>
              </Column>

              <Column md={2}>
                <div className='namespaceLabel'>
                  {t('gatewayDetailsSection.form.appResourceGroupLabel')}
                </div>
                <div className='subDetailsVal'>
                  {formData.appResourceGroup.value.name}
                </div>
              </Column>
            </Row>
          </div>
        </div>
      )}

      {/* {depEnvList && (
        <div className='deploy-in-agent-cluster-section'>
          <Row>
            <Column md={3}>
              <div className='heading'>
                {t('skupperEdgeGateway.deployButtonSectionHeading')}
              </div>
              <div className='empty-depl-env-container'>
                <div className='no-deployment-env-img'>
                  {images.noDeploymentEnvsIcon()}
                </div>
                <div className='empty-message-heading'>
                  {t('skupperEdgeGateway.noDeplEnv.heading')}
                </div>
                <div className='empty-message-description'>
                  {t('skupperEdgeGateway.noDeplEnv.description')}
                </div>
              </div>
            </Column>
          </Row>
        </div>
      )} */}

      <div className='gateway-optional-settngs-section'>
        <div className='optional-settngs-heading'>
          {t('gatewayDetailsForm.optionalSettings.heading')}
        </div>
        <Accordion className='optional-settings-accordion' align='env'>
          <AccordionItem title='Gateway options'>
            <div className='optional-details'>
              <Row className='row'>
                <Column md={8}>
                  <AddLabels
                    id='gateway-labels'
                    labelText={t('gatewayDetailsForm.labelName')}
                    placeholder={t('gatewayDetailsForm.labelPlaceholder')}
                    onChange={data => onChange('labels', data)}
                    btnText={t('gatewayDetailsForm.labelbtnText')}
                    btnKind='secondary'
                    tagType='green'
                    defaultValues={formData?.labels?.value}
                  />
                </Column>
              </Row>
              <Row className='row'>
                <Column md={8}>
                  <TextArea
                    id='gateway-description'
                    name='description'
                    autoComplete='off'
                    labelText={t('gatewayDetailsForm.description.label')}
                    placeholder={t(
                      'gatewayDetailsForm.description.placeholder'
                    )}
                    onChange={(e: any) =>
                      onChange('description', e?.target?.value)
                    }
                    value={formData?.description?.value}
                    maxLength={300}
                  />
                </Column>
              </Row>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
    </CreateTearsheetStep>
  );
};

export default GatewayDetails;
