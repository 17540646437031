import React, { useEffect, useState } from 'react';
import { Location, Position, Resouce } from '../types';
import { ComponentConfig, getComponentConfig } from './config';

import './TooltipContainer.scss';
import { getDeployments } from '../../../controllers/applicationApis';
import { Deployment } from '../../../models/master';
import { AxiosError } from 'axios';

interface Props {
  node: Location | Resouce;
  position: Position;
  namespaces: Resouce[];
}

const TooltipContainer: React.FC<Props> = ({ node, position, namespaces }) => {
  const [componentConfig, setComponentConfig] =
    useState<ComponentConfig | null>(null);
  const [serviceExternalType, setServiceExternalType] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (node) {
      const config = getComponentConfig(node._type, node.label);

      if (config && config.component) {
        setComponentConfig(config as any);
      }
      if (node.application_id) {
        setServiceExternalType(getServiceExternalType());
      }
    }
  }, [node]);

  const getServiceExternalType = () => {
    for (const item of namespaces) {
      // check if the partition_id matches the uniqueID
      if (item.uniqueId === node.partition_id) {
        // search in the item's children array recursively
        const matchedNode = findTypeInchildren(item.children);
        if (matchedNode) return matchedNode.type;
      }
    }
    return null;
  };

  const findTypeInchildren = (children: Resouce[] | undefined) => {
    if (!children || children.length === 0) return null;

    for (const child of children) {
      if (child.type === 'external' || child.type === 'cluster') return child;
      // recursively search deeper in the nested children
      const nestedMatch: any = findTypeInchildren(child.children);
      if (nestedMatch) return nestedMatch;
    }
    return null;
  };

  const getPosition = () => {
    const container = document.querySelector('.topology-container');
    let left = position.x + 75;
    let top = position.y + 30;

    if (container) {
      const { width, height } = container.getBoundingClientRect();

      // If tooltip has no space to render at right
      if (width - position.x < 200) {
        left = position.x - 150;
      }

      // If tooltip has no space to render at bottom
      if (height - position.y < 50) {
        top = position.y;
      }

      // If tooltip has no space to render at top
      if (position.y < 50) {
        top = position.y + 75;
      }
    }

    return {
      left: left + 'px',
      top: top + 'px',
    };
  };

  const Component = componentConfig ? componentConfig.component : null;

  return (
    <>
      {node && Component && (
        <div className='topology-tooltip-container' style={getPosition()}>
          <Component
            node={node}
            appDeploymentType={node?.type}
            deploymentType={serviceExternalType}
          />
        </div>
      )}
    </>
  );
};

export default TooltipContainer;
