import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '@carbon/react';
import TopologyContainer from '../../components/Topology/TopologyContainer';

import './TopologyPage.scss';
import { getNetworkSegments } from '../../controllers/networksegmentsApi';
import { NetworkSegment } from '../../models/master';
import useAnalytics from '../../lib/useAnalytics';

const TopologyPage: React.FC = () => {
  const { t } = useTranslation('topologyPage');
  // const [view, setView] = useState<'map' | 'canvas'>('canvas');
  const topologyHeaderRef = useRef<HTMLInputElement | null>(null);
  const [headerWidth, setHeaderWidth] = useState<number | null>(null);
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);
  const [initialHeaderWidth, setinitialHeaderWidth] = useState<number | null>(
    null
  );
  const [initialHeaderHeight, setinitialHeaderHeight] = useState<number | null>(
    null
  );
  const [zoomContainerCustomStyle, setZoomContainerCustomStyle] = useState<{
    [key: string]: string;
  }>({});
  const [graphParamsCustomStyle, setGraphParamsCustomStyle] = useState<{
    [key: string]: string;
  }>({});
  const [legendCustomStyle, setLegendCustomStyle] = useState<{
    [key: string]: string;
  }>({});
  const [legendContainerStyle, setLegendContainerStyle] = useState<{
    [key: string]: string;
  }>({});
  //State open sets boolean value when sidepanel opens and close
  const [open, setOpen] = useState(false);
  const [legendOpen, setLegendOpen] = useState(false);
  //State sidePanelWidth gives the widthe of the side panel in
  const [sidePanelWidth, setSidePanelWidth] = useState<string>('320px');
  const [legendHeight, setLegendHeight] = useState<string>('322px');
  const [networkSegments, setNetworkSegments] = useState<
    NetworkSegment[] | null
  >([]);
  const [selectedNetworkSegment, setSelectedNetworkSegment] =
    useState<NetworkSegment | null>(null);
  const [networkSegPlaceholderText, setNetworkSegPlaceholderText] =
    useState<string>('');
  const { pageViewed } = useAnalytics();

  const breadcrumbs = [
    {
      url: '/',
      label: t('home'),
    },
  ];

  //Get the window width on initial render and set it to initialHeaderWidth
  useEffect(() => {
    pageViewed('Topology');
    if (!topologyHeaderRef.current) return;
    setinitialHeaderWidth(window.innerWidth);
    setinitialHeaderHeight(window.innerHeight);
  }, []);

  //To get the window width if the browser get resized and the resized window width is set to initialHeaderWidth
  useEffect(() => {
    function handleWindowResize() {
      setinitialHeaderWidth(window.innerWidth);
      setinitialHeaderHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleNetworkSegmentChange = (e: any) => {
    setSelectedNetworkSegment(e.selectedItem);

    open && setOpen(false);
  };

  const fetchData = async () => {
    try {
      const networkSegments = await getNetworkSegments();
      setNetworkSegments(networkSegments);
      setNetworkSegPlaceholderText(
        `${t('networkSegments')} (${networkSegments.length})`
      );
    } catch (error) {
      console.error(error);
      setNetworkSegPlaceholderText(`${t('networkSegments')} (0)`);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!topologyHeaderRef.current) return;
    topologyHeaderRef.current.style.width = `${headerWidth}px`;

    if (headerWidth) {
      if (headerHeight) {
        setZoomContainerCustomStyle({
          left: `${headerWidth - 32}px`,
          right: 'auto',
          top: `${headerHeight - 108}px`,
          bottom: 'auto',
        });
        setGraphParamsCustomStyle({
          left: 'auto',
          right: open ? `${parseInt(sidePanelWidth) + 50}px` : '110px',
          top: `110px`,
          bottom: 'auto',
        });
        setLegendCustomStyle({
          left: `${headerWidth - 66}px`,
          right: 'auto',
          top: `${headerHeight + 6}px`,
          bottom: 'auto',
        });

        setLegendContainerStyle({
          left: open
            ? `${Math.min(
                Math.max(headerWidth - (1696 - parseInt(sidePanelWidth)), 48),
                48
              )}px`
            : `${Math.min(Math.max(headerWidth - 1696, 48), 48)}px`,
          right: open ? `${parseInt(sidePanelWidth) + 1}px` : '0px',
          top: `${headerHeight + 96}px`,
          bottom: 'auto',
        });
      }
    }
  }, [headerWidth, headerHeight, open, sidePanelWidth]);

  useEffect(() => {
    if (
      !legendHeight ||
      !sidePanelWidth ||
      !initialHeaderWidth ||
      !initialHeaderHeight
    )
      return;
    if (!open && !legendOpen) {
      setHeaderWidth(initialHeaderWidth - 48);
      setHeaderHeight(initialHeaderHeight - 90);
    } else if (open && !legendOpen) {
      setHeaderWidth(initialHeaderWidth - 48 - parseInt(sidePanelWidth));
      setHeaderHeight(initialHeaderHeight - 90);
    } else if (legendOpen && !open) {
      setHeaderWidth(initialHeaderWidth - 48);
      setHeaderHeight(initialHeaderHeight - 90 - parseInt(legendHeight));
    } else {
      setHeaderWidth(initialHeaderWidth - 48 - parseInt(sidePanelWidth));
      setHeaderHeight(initialHeaderHeight - 90 - parseInt(legendHeight));
    }
  }, [open, legendOpen, initialHeaderWidth, initialHeaderHeight]);

  const handleHeaderWidth = (width: number) => {
    setHeaderWidth(width);
  };

  const handleSidePanelWidth = (width: string) => {
    setSidePanelWidth(width);
  };

  return (
    <div className='topology-page'>
      <div className='header-wrapper' ref={topologyHeaderRef}>
        <Dropdown
          className='select-network-segment-dropdown'
          id='network-segment-filter-dropdown'
          titleText={t('view')}
          selectedItem={selectedNetworkSegment}
          items={networkSegments ?? []}
          translateWithId={t}
          itemToString={(item: any) => item?.name}
          onChange={handleNetworkSegmentChange}
          label={t('networkSegmentPlaceholder')}
          type='inline'
          size='lg'
        />
      </div>
      <div className='page-content'>
        <TopologyContainer
          handleHeaderWidth={handleHeaderWidth}
          initialHeaderWidth={initialHeaderWidth}
          zoomContainerCustomStyle={zoomContainerCustomStyle}
          graphParamsCustomStyle={graphParamsCustomStyle}
          legendCustomStyle={legendCustomStyle}
          headerWidth={headerWidth}
          setOpen={setOpen}
          open={open}
          setLegendOpen={setLegendOpen}
          legendOpen={legendOpen}
          handleSidePanelWidth={handleSidePanelWidth}
          sidePanelWidth={sidePanelWidth}
          selectedNetworkSegment={selectedNetworkSegment}
          legendContainerStyle={legendContainerStyle}
          networkSegments={networkSegments}
        />
      </div>
    </div>
  );
};

export default TopologyPage;
