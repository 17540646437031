import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Column,
  Row,
  TextInput,
  ComboBox,
  FormLabel,
  InlineNotification,
  Checkbox,
  Dropdown as Dropdown1,
} from 'carbon-components-react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import { Dropdown, TextArea } from '@carbon/react';

import AddLabels from '../../../../components/AddLabels/AddLabels';
import images from '../../../../images/images';

import {
  DeploymentEnvironmentSubtype,
  Cloud,
  Vpc,
  Location,
} from '../../../../models/master';
import { getCloudVpcs } from '../../../../controllers/cloudApis';
import { EnvironmentTypes } from '../../../../lib/enums';
import { FormData, ModifiedCloud } from '../RegisterDeploymentEnv';

import './DefineEnvironmentDetails.scss';
import GenericManageButton from '../../../../components/GenericManageButton/GenericManageButton';
import { getThirdPartyCloudIconsDropdown } from '../../../Cloud/ThirdPartyCloudIcons';
import GenericStatusField from '../../../../components/GenericStatusField/GenericStatusField';

interface Props {
  selectedType: DeploymentEnvironmentSubtype | null;
  selectedCloud: ModifiedCloud | null;
  onChange: any;
  formData: FormData;
  groups: any;
  showError: boolean;
  onCloseRegisterError: () => void;
  titleErrorMessage: string;
  subTitleErrorMessage: string;
  isEditMode?: boolean;
  isManageMode?: boolean;
  open: boolean;
  cloudList: Cloud[];
  locations: Location[] | null;
  selectCloudCallback: (cloud: ModifiedCloud | null) => void;
  isFormValid: boolean;
  isPartitionMangaed: boolean;
  isSelectedLocationUnmanaged: boolean;
  hasLocationAuthorization: boolean;
  hasResourceGroupAuthorization: boolean;
}

const DefineEnvironmentDetails: React.FC<Props> = ({
  selectedType,
  selectedCloud,
  formData,
  onChange,
  groups,
  showError,
  onCloseRegisterError,
  isEditMode = false,
  isManageMode = false,
  open,
  titleErrorMessage,
  subTitleErrorMessage,
  cloudList,
  locations,
  selectCloudCallback,
  isFormValid,
  isPartitionMangaed,
  isSelectedLocationUnmanaged,
  hasLocationAuthorization = true,
  hasResourceGroupAuthorization = true,
}) => {
  const { t } = useTranslation('registerDeploymentEnv');
  const { name, labels, is_discovered } = formData;
  const errorRef = useRef<HTMLDivElement>(null);

  const [deployedVPCS, setDeployedVPCS] = useState<Vpc[] | []>([]);
  const type = selectedType ? selectedType.resource_type : '';

  const [filteredLocationList, setFilteredLocationList] = useState<
    any[] | null
  >(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      open &&
      isEditMode &&
      formData?.unmanaged &&
      !isSelectedLocationUnmanaged
    ) {
      timer = setTimeout(() => {
        onChange('unmanaged', !formData.unmanaged);
      }, 1000);
    }

    // Clear the timer if component unmounts or if the dependency changes before timeout
    return () => clearTimeout(timer);
  }, [isEditMode, open, isSelectedLocationUnmanaged]);

  useEffect(() => {
    if (open) {
      if (
        selectedType &&
        selectedType.resource_type === EnvironmentTypes.CLUSTER
      ) {
        getDeployedVpcs();
      }
    }
    if (selectedCloud != null && locations != null) {
      let filteredLocations = locations?.filter(
        (location: Location) => location?.cloud_id === selectedCloud.resource_id
      );
      if (Array.isArray(filteredLocations)) {
        setFilteredLocationList([...filteredLocations]);
      }
    }
  }, [selectedType, isEditMode, open, selectedCloud, locations]);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.scrollIntoView(true);
    }
  }, [showError]);

  const getDeployedVpcs = async () => {
    try {
      // the deployed VPCs fetching is filtered based on the infra_only field
      const deployedVPC = await getCloudVpcs(selectedCloud?.resource_id);
      setDeployedVPCS(deployedVPC?.vpcs);
    } catch (error) {
      console.error(error);
    }
  };

  const filterDeployedVPCS = (location_id: any) => {
    const list = deployedVPCS.filter(
      (vpc: Vpc) => vpc.location_id === location_id
    );
    return list;
  };

  const renderSelectedItem = (item: Cloud) => (
    <div className='selected-cloud-item'>
      <span className='selected-cloud-icon'>
        {getThirdPartyCloudIconsDropdown(item.type)}
      </span>
      <span className='selected-cloud-name'>{item?.name}</span>
    </div>
  );

  return (
    <>
      <CreateTearsheetStep
        className='define-environment-details'
        title={t('defineEnvironmentDetails.title')}
        disableSubmit={!isFormValid}
        hasFieldset={false}
        data-testid='deployment-env-define-details-step'
      >
        {showError && type !== EnvironmentTypes.CLUSTER && (
          <div ref={errorRef}>
            <InlineNotification
              kind='error'
              aria-label=''
              title={titleErrorMessage}
              subtitle={subTitleErrorMessage}
              onClose={() => onCloseRegisterError() as any}
              lowContrast
            />
          </div>
        )}
        <Row className='step-title'>
          <span>
            {isEditMode || isManageMode
              ? t('defineEnvironmentDetails.editStepTitle')
              : t('defineEnvironmentDetails.stepTitle')}
          </span>
          <div className='manage-checkbox-section'>
            <GenericManageButton
              isCheckboxDisabled={
                !isEditMode
                  ? true
                  : isEditMode && formData.unmanaged
                  ? isSelectedLocationUnmanaged
                  : isPartitionMangaed
              }
              checked={!formData.unmanaged}
              showTooltip={
                formData.unmanaged
                  ? isSelectedLocationUnmanaged
                  : isPartitionMangaed
              }
              toolTipText={
                formData.unmanaged
                  ? t(
                      'defineEnvironmentDetails.manageCheckbox.tooltipUnmanaged'
                    )
                  : t('defineEnvironmentDetails.manageCheckbox.tooltip', {
                      partition:
                        selectedType?.resource_type === 'vpc'
                          ? t('securityGroup')
                          : t('nameSpace'),
                    })
              }
              id='managedBtn-depEnv'
              handleCheckbox={() => {
                onChange('unmanaged', !formData.unmanaged);
              }}
            />
          </div>
        </Row>
        <Row className='row'>
          <Column>
            {isEditMode && (
              <Dropdown
                className={`select-cloud-dropdown disabled`}
                id={`select-cloud-dropdown-id disabled`}
                selectedItem={selectedCloud}
                renderSelectedItem={(item: any) => renderSelectedItem(item)}
                items={cloudList.filter(
                  (cloud: Cloud) => cloud?.auto_discover === false
                )}
                translateWithId={t}
                itemToString={(item: any) => (item ? item.name : '')}
                label={t('cloudSelect.selectCloudDropdown.label')}
                titleText={t('cloudSelect.selectCloudDropdown.titleText')}
                disabled={isEditMode}
                invalidText={t('cloudSelect.selectCloudDropdown.invalidText')}
              />
            )}
          </Column>
          <Column className='select-location-dropdown-column'>
            {isEditMode &&
              (hasLocationAuthorization ? (
                <ComboBox
                  id='select-location-dropdown-id disabled'
                  className='select-location-dropdown disabled'
                  selectedItem={formData?.location}
                  items={filteredLocationList ?? []}
                  translateWithId={t}
                  itemToString={item => item?.name || ''}
                  itemToElement={(location: Location) => (
                    <div
                      className='location-option'
                      key={location?.resource_id}
                    >
                      <span className='location-name'>{location?.name}</span>
                      <span className='location-type'>
                        {location?.type
                          ? location?.type[0].toUpperCase() +
                            location?.type.slice(1)
                          : ''}
                      </span>
                    </div>
                  )}
                  placeholder={t(
                    'cloudSelect.selectLocationDropdown.placeHolder'
                  )}
                  onChange={() => {}}
                  titleText={t('cloudSelect.selectLocationDropdown.titleText')}
                  readOnly={isEditMode}
                />
              ) : (
                <div className='generic-notAuthorised-container'>
                  <span className='label'>
                    {t('cloudSelect.selectLocationDropdown.titleText')}
                  </span>
                  <div className='generic-status-container'>
                    <GenericStatusField status='notAuthorised'></GenericStatusField>
                  </div>
                </div>
              ))}
          </Column>
        </Row>
        <Row className='row'>
          <Column className='deployment-env-name'>
            <TextInput
              labelText={t('defineEnvironmentDetails.name.label')}
              placeholder={t('defineEnvironmentDetails.name.placeholder')}
              id={
                selectedCloud?.auto_discover && isEditMode
                  ? 'readOnly-field'
                  : 'register-env-name'
              }
              name='name'
              onChange={e => onChange('name', e.target.value)}
              autoComplete='off'
              size='md'
              value={isEditMode ? formData?.name?.value : name?.value}
              invalid={name.error}
              invalidText={name.errorMessage}
              maxLength={60}
              disabled={is_discovered}
              readOnly={selectedCloud?.auto_discover && isEditMode}
              data-testid='name-field'
            />
          </Column>
          <Column className='select-resourcegroup-type'>
            {hasResourceGroupAuthorization ? (
              <Dropdown1
                id='select-resourcegroup-dropdown'
                items={groups ?? []}
                translateWithId={t}
                selectedItem={formData.resource_group}
                itemToString={(item: any) => (item ? item.name : '')}
                onChange={(e: any) => {
                  onChange('resource_group', e.selectedItem);
                }}
                label={t('defineEnvironmentDetails.group.label')}
                titleText={t('defineEnvironmentDetails.group.titleText')}
                invalid={Array.isArray(groups) && groups.length === 0}
                invalidText={t('defineEnvironmentDetails.group.invalidText')}
                data-testid='resource-group-dropdown-field'
              />
            ) : (
              <div className='generic-notAuthorised-container'>
                <span className='label'>
                  {t('defineEnvironmentDetails.group.label')}
                </span>
                <div className='generic-status-container'>
                  <GenericStatusField status='notAuthorised'></GenericStatusField>
                </div>
              </div>
            )}
          </Column>
        </Row>
        {type === EnvironmentTypes.CLUSTER && (
          <Row className='row'>
            <Column className='deployed-in-vpc-column'>
              {selectedCloud?.auto_discover && isEditMode ? (
                <>
                  <FormLabel>
                    {t('defineEnvironmentDetails.deployedVPC.label')}
                  </FormLabel>
                  <TextInput
                    labelText={''}
                    id='readOnly-field'
                    name='edit-depVPC'
                    autoComplete='off'
                    size='md'
                    value={formData?.deployed_in_vpc?.name ?? ''}
                    readOnly
                  />
                </>
              ) : (
                <ComboBox
                  id='deployed-in-vpc-dropdown'
                  light
                  titleText={t('defineEnvironmentDetails.deployedVPC.label')}
                  items={
                    formData?.location?.id
                      ? filterDeployedVPCS(formData?.location?.id)
                      : []
                  }
                  itemToString={(item: any) => (item ? item.name : '')}
                  placeholder={t(
                    'defineEnvironmentDetails.deployedVPC.placeholder'
                  )}
                  onChange={(e: any) => {
                    onChange('deployed_in_vpc', e?.selectedItem);
                  }}
                  size='md'
                  selectedItem={formData?.deployed_in_vpc}
                  data-testid='deployed-in-vpc-dropdown-field'
                />
              )}
            </Column>
          </Row>
        )}

        <Row className='row label-field-row'>
          <AddLabels
            id='add-labels'
            labelText={t('defineEnvironmentDetails.labels.label')}
            placeholder={t('defineEnvironmentDetails.labels.placeholder')}
            onChange={labels => onChange('labels', labels)}
            btnText={t('defineEnvironmentDetails.labels.btnText')}
            defaultValues={labels}
          />
        </Row>
        <Row className='row description-field-row'>
          <TextArea
            id='description-field'
            name='description'
            className='form-field-description'
            autoComplete='off'
            labelText={t('defineEnvironmentDetails.description.label')}
            placeholder={t('defineEnvironmentDetails.description.placeholder')}
            onChange={(e: any) => onChange('description', e?.target?.value)}
            value={formData?.description}
            maxCount={300}
            enableCounter={true}
            data-testid='add-description-field'
          />
        </Row>
      </CreateTearsheetStep>
    </>
  );
};

export default DefineEnvironmentDetails;
