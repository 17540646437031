import { MCNM_NETWORK_API_URL, MCNM_TOPOLOGY_API_URL } from './config';
import { axiosInstance } from './api';

// get all topology resouces
export async function getTopologyResources(visibility = 'managed') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TOPOLOGY_API_URL}/topology?visibility=${visibility}`,
      headers: {
        'Cache-Control': 'no-store',
        Pragma: 'no-cache',
        Expires: 0,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getTopologyResourceDetails(resourceId) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_TOPOLOGY_API_URL}/topology/resource/${resourceId}?include_metrics=true&active_flows=true`,
      headers: {
        'Cache-Control': 'no-store',
        Pragma: 'no-cache',
        Expires: 0,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getGatewayCompatibilitySet() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/gwcompatibilityset`,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
