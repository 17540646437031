import React, { useState, useContext, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { useQueryClient } from '@tanstack/react-query';
import {
  SkeletonText,
  Row,
  Column,
  ButtonSkeleton,
  TextInputSkeleton,
  ClickableTile,
  Button,
  NumberInput,
  TextInput,
} from 'carbon-components-react';
import { EditInPlace, SidePanel } from '@carbon/ibm-products';

import { Link } from 'react-router-dom';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import { inValidateListCacheFn } from '../../../lib/invalidateQueriesFunctions';

import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import './OnboardNetworkSegment.scss';
import AccordionCard from '../../../components/AccordionCard/AccordionCard';
import { Edit16, Launch16, Warning16 } from '@carbon/icons-react';
import { faqSectionsData } from './Faq';
import { GatewayCustomData, NetworkSegment } from '../../../models/master';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import images from '../../../images/images';
import GatewayListView from './GatewayListView';
import ProceduralActivity from '../../../components/ProceduralActivity/ProceduralActivity';
import GatewayProcedure from '../../../components/GatewayStatus/GatewayProcedure';
import { ProceduralStatus } from '../../../lib/enums';
import GenericTruncateString from '../../../components/GenericTruncateString/GenericTruncateString';
import {
  updateNetworkSegmentName,
  updateNetworkSegments,
} from '../../../controllers/networksegmentsApi';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { removeExtraSpace } from '../../../lib/utils';

interface Props {
  open: boolean;
  onClose: () => void;
  networkSegments: NetworkSegment[] | null;
  refreshNetworkListData: () => void;
}

interface NameValidation {
  [networkSegmentName: string]: boolean;
}

const OnboardingNetworkSegment: React.FC<Props> = ({
  open,
  onClose,
  networkSegments,
  refreshNetworkListData,
}) => {
  const [openInstallGuide, setOpenInstallGuide] = useState(true);
  const [openGatewayList, setOpenGatewayList] = useState(false);
  const [allGateways, setAllGateways] = useState([]);
  const [selectedNetworkSegment, setSelectedNetworkSegment] =
    useState<NetworkSegment | null>(null);
  const [isUnknownNetworkSegmentSelected, setIsUnknownNetworkSegmentSelected] =
    useState<boolean>(false);
  const [openFaq, setFaq] = useState(false);
  const { t } = useTranslation('networkSegments');
  const notification = useContext(NotificationContext);
  const [formNetworkSegmentName, setFormNetworkSegmentName] = useState('');
  const [isNetworkSegmentNameInvalid, setIsNetworkSegmentNameInvalid] =
    useState<NameValidation>({});
  const [isEditing, setIsediting] = useState(false);
  const inlineEditRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && Array.isArray(networkSegments) && networkSegments?.length > 0)
      setSelectedNetworkSegment(null);
  }, [open]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsieInlineEdit);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsieInlineEdit);
    };
  });

  const handleClickOutsieInlineEdit = (e: any) => {
    if (inlineEditRef.current && !inlineEditRef.current.contains(e.target)) {
      setFormNetworkSegmentName('');
      setIsediting(false);
      setIsNetworkSegmentNameInvalid({});
    }
  };

  const getNetworkSegmentGatewaysLength = (
    gateways: GatewayCustomData[],
    network_segment_id: string
  ) => {
    //add all gateways here
    return gateways?.filter(
      gateway => gateway.network_segment_id === network_segment_id
    ).length;
  };

  const getProceduralStatus = (row: NetworkSegment) => {
    if (row?.procedural_activity) {
      if (row.procedural_activity === 'waiting-nseg-completion') {
        return (
          <div className='waiting-nseg-completion-status'>
            <Warning16 className='warning-icon'></Warning16>
            <ProceduralActivity status={row.procedural_activity} />
          </div>
        );
      } else {
        return <ProceduralActivity status={row?.procedural_activity} />;
      }
    } else if (
      row?.procedural_status === ProceduralStatus.IN_PROGRESS &&
      row?.procedure
    ) {
      return <GatewayProcedure status={row.procedure} />;
    } else return <>—</>;
  };

  const getUnknowNetworkSegmentGateways = (gateways: GatewayCustomData[]) => {
    return gateways?.filter(gateway => gateway.network_segment_id === '');
  };

  const handleOnchange = (val: string) => {
    setFormNetworkSegmentName(val);
    setIsediting(true);
  };

  const inPlaceEditInvalid = (isEditing: boolean, resourceId: string) => {
    return isEditing && isNetworkSegmentNameInvalid[resourceId];
  };

  const isUniqueName = (value: string, currentNetworkSegment: string) => {
    let notUnique = false;

    if (networkSegments && value) {
      notUnique =
        networkSegments?.filter(
          networkSegment => networkSegment.name.trim() === value.trim()
        ).length > 0 && value.trim() !== currentNetworkSegment
          ? false
          : true;
      return notUnique;
    }

    return notUnique;
  };

  const handleSubmit = async (resourceID: string, name: string) => {
    // const errorMessage = checkFieldValidation(name, value);
    if (!isUniqueName(formNetworkSegmentName, name)) {
      setIsNetworkSegmentNameInvalid(preData => ({
        ...preData,
        [resourceID]: true,
      }));
      setIsediting(true);
    } else {
      try {
        let changeNetworkSegmentName = {
          name: removeExtraSpace(formNetworkSegmentName),
        };

        const NewNetworkSegmentName: NetworkSegment =
          await updateNetworkSegmentName(resourceID, changeNetworkSegmentName);
        // setFormNetworkSegmentName(name);
        refreshNetworkListData();
        // setIsediting(false);
      } catch (error: any) {
        setFormNetworkSegmentName(name);
      }
    }
  };

  return (
    <SidePanel
      open={open}
      onRequestClose={onClose}
      title={t('onboardingNetworkSegment.onBoardingTitle')}
      className={'onboard-network-segment-modal'}
    >
      <div className='onBoardModalContainer'>
        <div className='onboardingSubtitle'>
          {t('onboardingNetworkSegment.subTitle')}
          <a
            href={
              navigator.language === 'fr' ||
              navigator.language === 'fr-FR' ||
              navigator.language === 'fr-BE' ||
              navigator.language === 'fr-CA' ||
              navigator.language === 'fr-CH'
                ? 'https://ibm.biz/mesh-onboard-netseg-fr'
                : 'https://ibm.biz/mesh-onboard-netseg'
            }
            rel='noopener noreferrer'
            className='learnMoreLink'
            target='_blank'
          >
            {t('onboardingNetworkSegment.learnMore')}
          </a>
        </div>
        <AccordionCard
          accordionCardTitle={t('onboardingNetworkSegment.installAgentTitle')}
          accordionCardSubTitle={t(
            'onboardingNetworkSegment.installAgentSubTitle'
          )}
          expandText={t('onboardingNetworkSegment.readMore')}
          collapseText={t('onboardingNetworkSegment.readLess')}
          isReadMoreExpanded={openInstallGuide}
          handleReadMore={value => setOpenInstallGuide(value)}
        >
          <div className='installAgentContainer'>
            <div className='installAgent'>
              <div className='content-title'>
                {t('onboardingNetworkSegment.openGuideContentTitle')}
              </div>
              <div className='content-data'>
                {t('onboardingNetworkSegment.openGuideContentData')}
              </div>
            </div>
            <div className='docsSection'>
              <div className='content-title'>
                {t('onboardingNetworkSegment.documentTitle')}
              </div>
              <div className='content-title'>
                <a
                  href={
                    navigator.language === 'fr' ||
                    navigator.language === 'fr-FR' ||
                    navigator.language === 'fr-BE' ||
                    navigator.language === 'fr-CA' ||
                    navigator.language === 'fr-CH'
                      ? 'https://ibm.biz/mesh-open-horizon-agent-fr'
                      : 'https://ibm.biz/mesh-open-horizon-agent'
                  }
                  rel='noopener noreferrer'
                  className='section-link agent-info-link'
                  target='_blank'
                >
                  <span className='link-text'>
                    {t('onboardingNetworkSegment.documentLink')}
                  </span>
                  <span className='link-icon'>
                    {' '}
                    <Launch16 />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </AccordionCard>

        <AccordionCard
          accordionCardTitle={t('onboardingNetworkSegment.viewGatewaysTitle')}
          accordionCardSubTitle={t(
            'onboardingNetworkSegment.viewGatewaysSubTitle'
          )}
          expandText={t('onboardingNetworkSegment.readMore')}
          collapseText={t('onboardingNetworkSegment.readLess')}
          isReadMoreExpanded={openGatewayList}
          handleReadMore={value => setOpenGatewayList(value)}
        >
          <div className='gatewayListViewContainer'>
            {networkSegments && networkSegments.length > 0 ? (
              <div className='networkListContainer'>
                <ClickableTile
                  className={`networkCard ${
                    isUnknownNetworkSegmentSelected ? 'clicked' : 'unClicked'
                  }`}
                  visited={isUnknownNetworkSegmentSelected}
                  onClick={() => {
                    setIsUnknownNetworkSegmentSelected(
                      !isUnknownNetworkSegmentSelected
                    );
                    setSelectedNetworkSegment(null);
                  }}
                >
                  <div className='tile-title'>
                    <span className='unknown-network-segment-name'>
                      {t(
                        'onboardingNetworkSegment.gateways.unknown_network_segment'
                      )}
                    </span>
                    <div
                      className={`${
                        isUnknownNetworkSegmentSelected
                          ? 'selected-icon'
                          : 'unselected-icon'
                      }`}
                    >
                      {images.selectedTickIcon()}
                    </div>
                  </div>
                  <span className='unknown-count'>
                    {` (${
                      getUnknowNetworkSegmentGateways(allGateways)?.length
                    }) ${t(
                      'onboardingNetworkSegment.gateways.unassociated_gateways'
                    )}`}
                  </span>
                  <div className='unknown-status'>
                    <Warning16 className='warning-icon'></Warning16>
                    <div className='statusVal'></div>
                    <div className='statusVal'>{t('deployagent')}</div>
                  </div>
                </ClickableTile>
                {networkSegments.map(
                  (networkSegment: NetworkSegment, index: number) => (
                    <ClickableTile
                      className={`networkCard ${
                        networkSegment.resource_id ===
                          selectedNetworkSegment?.resource_id &&
                        !isUnknownNetworkSegmentSelected
                          ? 'clicked'
                          : 'unClicked'
                      }`}
                      visited={isUnknownNetworkSegmentSelected}
                      onClick={index => {
                        setSelectedNetworkSegment(
                          selectedNetworkSegment?.resource_id ===
                            networkSegment.resource_id
                            ? null
                            : networkSegment
                        );
                        setIsUnknownNetworkSegmentSelected(false);
                      }}
                    >
                      <div className='tile-title'>
                        <span className='known-network-segment-name'>
                          {networkSegment.procedural_activity ===
                          'finalizing-onboarding' ? (
                            <Link
                              className='no-underline-link'
                              to={`/networkSegmentDetails?nwSegId=${networkSegment.resource_id}`}
                            >
                              <GenericTruncateString
                                str={networkSegment?.name}
                                tableView={true}
                              />
                            </Link>
                          ) : (
                            <div className='inline-edit' ref={inlineEditRef}>
                              <EditInPlace
                                cancelLabel={t('editInPlaceButton.cancel')}
                                editLabel={t('editInPlaceButton.edit')}
                                id='story-id'
                                size='sm'
                                editAlwaysVisible={true}
                                //will fix the tooltip broken issue once carbon libray is updated
                                tooltipAlignment='bottom'
                                invalid={
                                  isEditing &&
                                  isNetworkSegmentNameInvalid[
                                    networkSegment?.resource_id
                                  ]
                                }
                                invalidText={t('nameValidationMessege')}
                                onCancel={(
                                  initialVal: React.SetStateAction<string>
                                ) => {
                                  setFormNetworkSegmentName(initialVal);
                                  setIsNetworkSegmentNameInvalid(preData => ({
                                    ...preData,
                                    [networkSegment?.resource_id]: false,
                                  }));
                                  setIsediting(false);
                                }}
                                onChange={handleOnchange}
                                onSave={() => {
                                  handleSubmit(
                                    networkSegment?.resource_id,
                                    networkSegment?.name
                                  );
                                }}
                                saveLabel='Save'
                                value={
                                  // isEditing
                                  //   ? formNetworkSegmentName
                                  //   :
                                  networkSegment?.name
                                }
                              />
                            </div>
                          )}
                        </span>
                        <div
                          className={`${
                            networkSegment.resource_id ===
                            selectedNetworkSegment?.resource_id
                              ? networkSegment.procedural_activity ===
                                'finalizing-onboarding'
                                ? 'selected-icon'
                                : 'inline-edit-selected-icon'
                              : 'unselected-icon'
                          }`}
                        >
                          {images.selectedTickIcon()}
                        </div>
                      </div>
                      <span
                        className={
                          isEditing ? 'editing-count' : 'non-editing-count'
                        }
                      >
                        {isEditing
                          ? ''
                          : ` (${getNetworkSegmentGatewaysLength(
                              allGateways,
                              networkSegment.resource_id
                            )}) ${t(
                              'onboardingNetworkSegment.gateways.tab_name'
                            )}`}
                      </span>
                      <div className='known-status'>
                        <div>{getProceduralStatus(networkSegment)}</div>
                      </div>
                    </ClickableTile>
                  )
                )}
              </div>
            ) : (
              <EmptyState
                icon={images.emptyNetworkSegmentSvg()}
                header={t('emptyState.emptyContainerHeader')}
                description={t('todeployagent')}
              />
            )}

            {openGatewayList ? (
              <GatewayListView
                selectedNetworkSegment={
                  selectedNetworkSegment as NetworkSegment
                }
                networkSegmentList={networkSegments as NetworkSegment[]}
                refreshNetworkSegmentDetails={refreshNetworkListData}
                setAllGateways={setAllGateways}
                unknownNetworkSegmentSelected={isUnknownNetworkSegmentSelected}
              />
            ) : null}
          </div>
        </AccordionCard>

        <AccordionCard
          accordionCardTitle={t('onboardingNetworkSegment.faqTitle')}
          accordionCardSubTitle={t('onboardingNetworkSegment.faqSubTitle')}
          expandText={t('onboardingNetworkSegment.readMore')}
          collapseText={t('onboardingNetworkSegment.readLess')}
          isReadMoreExpanded={openFaq}
          handleReadMore={value => setFaq(value)}
        >
          <div className='faqContentContainer'>
            {faqSectionsData.map((eachFaq: any, index: number) => (
              <div key={index} className='faq-section'>
                <div className='faq-qn'>{t(eachFaq.question)}</div>
                <div className='faq-ans'>{t(eachFaq.answer)}</div>
              </div>
            ))}
          </div>
        </AccordionCard>
      </div>
    </SidePanel>
  );
};
export default OnboardingNetworkSegment;
