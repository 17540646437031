import React from 'react';
import { CreateTearsheetStep } from '@carbon/ibm-products';
import {
  Column,
  Row,
  TextInput,
  TextArea,
  ComboBox,
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { FormDataStep1 } from '../config';

import AddLabels from '../../../../components/AddLabels/AddLabels';
import './PolicyDefineDetails.scss';
import { ResourceGroup } from '../../../../models/master';

interface Props {
  formData: FormDataStep1;
  onChange: (e: { target: { name: string; value: any } }) => void;
  formValid: boolean;
  resourceGroupList: ResourceGroup[];
  heading: string;
}

const filterAppGroups = (list: ResourceGroup[]) => {
  return list.filter(item => item.type === 'application');
};

const PolicyDefineDetails: React.FC<Props> = ({
  formData,
  onChange,
  formValid,
  children,
  resourceGroupList,
  heading,
}) => {
  const { name, description, resourceGroup, labels } = formData;
  const { t } = useTranslation('createPolicy');

  return (
    <>
      {/* Render error snackbar */}
      {children}
      <CreateTearsheetStep
        className='policy-define-details'
        title={t('policyDefineDetails.header')}
        disableSubmit={!formValid}
      >
        <div className='policy-define-details-title'>{heading}</div>

        <Row className='row'>
          <Column md={4} sm={4}>
            <TextInput
              id='policyDefineDetailsName'
              name='name'
              className='policy-define-details-textInput'
              value={name.value}
              labelText={t('policyDefineDetails.name') as string}
              placeholder={t('policyDefineDetails.PolicyNamePlaceholder')}
              onChange={onChange}
              autoComplete='off'
              maxLength={60}
              invalid={name.error}
              invalidText={name.errorMessage}
            />
          </Column>
          <Column md={4} sm={4}>
            <ComboBox
              id='policyDefineDetailsResourceGroup'
              name='resourceGroup'
              className='policy-define-details-dropdown policy-define-details-resourceGroup'
              titleText={t('policyDefineDetails.appGroup')}
              placeholder={t('policyDefineDetails.resourceGroupText')}
              onChange={data =>
                onChange({
                  target: { name: 'resourceGroup', value: data.selectedItem },
                })
              }
              selectedItem={resourceGroup?.value as any}
              translateWithId={t}
              items={filterAppGroups(resourceGroupList) ?? []}
              itemToString={item => (item ? item.name : '')}
              invalid={formData.resourceGroup.error}
              invalidText={formData.resourceGroup.errorMessage}
              data-testid={'policy-define-details-resource-group'}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <AddLabels
              id='create-policy-labels'
              labelText={t('policyDefineDetails.labelName')}
              placeholder={t('policyDefineDetails.labelPlaceholder')}
              onChange={data =>
                onChange({ target: { name: 'labels', value: data } })
              }
              btnText={t('policyDefineDetails.labelbtnText')}
              btnKind='secondary'
              tagType='green'
              defaultValues={labels.value}
            />
          </Column>
        </Row>
        <Row className='row'>
          <Column md={8}>
            <TextArea
              id='create-policy-desciption'
              name='description'
              autoComplete='off'
              labelText={t('policyDefineDetails.description')}
              placeholder={t('policyDefineDetails.descriptionPlaceholder')}
              onChange={onChange}
              value={description.value}
              maxCount={300}
              enableCounter={true}
            />
          </Column>
        </Row>
      </CreateTearsheetStep>
    </>
  );
};

export default PolicyDefineDetails;
