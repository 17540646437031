import React, { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import {
  EdgeClassType,
  Location,
  Locations,
  Position,
  Resouce,
  TopologyResponse,
  Visibility,
} from './types';
import { generateToplogyHierarchy } from './transformer';
import TopologyCanvasView from './TopologyCanvasView/TopologyCanvasView';
import TooltipContainer from './Tooltips/TooltipContainer';
import { getTopologyResources } from '../../controllers/topologyApis';
import SidePanelContainer from './SidePanels/SidePanelContainer';
import { HierarchyLink } from 'd3';
import { VisibilityFlags } from '../../lib/enums';
import { Error500Type, NetworkSegment } from '../../models/master';
import { Loading } from '@carbon/react';
import Error500 from '../../pages/Errors/Error500';

interface Props {
  scenario?: 'scenario-1' | 'scenario-2'; // Temporary props for switching between different test scenario data's for experimental release
  handleHeaderWidth: (width: number) => void;
  initialHeaderWidth: number | null;
  zoomContainerCustomStyle: {
    [key: string]: string;
  };
  graphParamsCustomStyle: {
    [key: string]: string;
  };
  legendCustomStyle: {
    [key: string]: string;
  };
  legendContainerStyle: {
    [key: string]: string;
  };
  headerWidth: number | null;
  setOpen: (open: boolean) => void;
  open: boolean;
  setLegendOpen: (open: boolean) => void;
  legendOpen: boolean;
  sidePanelWidth: string;
  handleSidePanelWidth: (width: string) => void;
  selectedNetworkSegment: NetworkSegment | null;
  networkSegments: NetworkSegment[] | null;
}

const TopologyContainer: React.FC<Props> = ({
  scenario = 'scenario-1',
  handleHeaderWidth,
  initialHeaderWidth,
  zoomContainerCustomStyle,
  graphParamsCustomStyle,
  legendCustomStyle,
  legendContainerStyle,
  headerWidth,
  setOpen,
  open,
  setLegendOpen,
  legendOpen,
  sidePanelWidth,
  handleSidePanelWidth,
  selectedNetworkSegment,
  networkSegments,
}) => {
  const canvasViewRef = useRef<any>();

  const [data, setData] = useState<any>(null);
  const [filteredData, setFilteredData] = useState<any>(null);
  const [hoveredNode, setHoveredNode] = useState<Resouce | null>(null);
  const [hoveredPosition, setHoveredPosition] = useState<Position | null>(null);
  const [selectedNode, setSelectedNode] = useState<Resouce | null>(null);
  const [selectedEdge, setSelectedEgde] = useState<{
    edge: HierarchyLink<Resouce>;
    type: EdgeClassType;
  } | null>(null);
  const [selectedEdgeLabel, setSelectedEgdeLabel] = useState<{
    edge: HierarchyLink<Resouce>;
    type: EdgeClassType;
  } | null>(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [error500, setError500] = useState<null | Error500Type>(null);
  const [visibility, setVisibility] = useState<Visibility>(
    VisibilityFlags.MANAGED
  );

  useEffect(() => {
    fetchData();
  }, [scenario, visibility]);

  useEffect(() => {
    if (data) {
      setFilteredData(null);
      // Need to add timeout to refresh the canvas when filtered with new network segment
      let timeout = setTimeout(() => {
        setFilteredData(getDataFilteredByNetworkSegment(data));
      });
      return () => clearTimeout(timeout);
    }
  }, [selectedNetworkSegment]);

  const fetchData = async () => {
    try {
      setDataLoading(true);
      setFilteredData(null);
      const response: TopologyResponse = await getTopologyResources(visibility);
      const topologyHeirarchy = generateToplogyHierarchy(response._items);

      setData(topologyHeirarchy);
      setFilteredData(getDataFilteredByNetworkSegment(topologyHeirarchy));
    } catch (error: any) {
      console.error(error);

      const err = error as AxiosError;
      if (err.response!?.status >= 500) {
        setError500(err.response!?.status?.toString() as Error500Type);
      }
    } finally {
      setDataLoading(false);
    }
  };

  /**
   * Filters the topology data based on network segments
   */
  const getDataFilteredByNetworkSegment = (mainData: Locations) => {
    const filteredData: Locations = JSON.parse(JSON.stringify(mainData));
    const resourceMap = filteredData.resouceMap;
    const networkSegmentLocationMap = filteredData.networkSegmentLocationMap;
    const networkSegmentDeplEnvMap = filteredData.networkSegmentDeplEnvMap;
    if (selectedNetworkSegment !== null) {
      const traverse = (data: Resouce) => {
        if (data.children) {
          const children: Resouce[] = [];
          data.children?.forEach((d, i) => {
            let inNetworkSegment = true;
            if (
              d._type === 'partition' ||
              d._type === 'application' ||
              d._type === 'gateway'
            ) {
              const networkSegmentId =
                d._type === 'application'
                  ? resourceMap[d.application_id as string].network_segment_id
                  : d.network_segment_id;

              if (networkSegmentId !== selectedNetworkSegment?.resource_id) {
                inNetworkSegment = false;
              }
            } else if (d._type === 'deployment_env') {
              const nsClusterMap = networkSegmentDeplEnvMap[d.resource_id];
              if (
                !nsClusterMap.includes(
                  selectedNetworkSegment?.resource_id ?? ''
                )
              ) {
                inNetworkSegment = false;
              }
            }

            if (inNetworkSegment) {
              children.push(d);
              traverse(d as any);
            }
          });
          data.children = children;
        }
        return data;
      };
      filteredData.locations = filteredData.locations?.filter(location =>
        networkSegmentLocationMap[location.resource_id]?.includes(
          selectedNetworkSegment?.resource_id
        )
      );
      for (const location of filteredData.locations) {
        traverse(location as any);
      }
      filteredData.policies = filteredData.policies?.filter(
        policy =>
          policy.network_segment_id === selectedNetworkSegment?.resource_id
      );
    }
    return filteredData;
  };

  const handleNodeHover = (node: Resouce, position: Position) => {
    if (hoveredNode !== node) {
      setHoveredNode(node);
      setHoveredPosition(position);
    }
  };

  const handleNodeLeave = () => {
    setHoveredNode(null);
    setHoveredPosition(null);
  };

  const handleNodeClick = (node: Resouce) => {
    setSelectedNode(node);
    setSelectedEgde(null);
  };

  const handleEdgeClick = (
    edge: HierarchyLink<Resouce>,
    edgeType: EdgeClassType = 'relation-line'
  ) => {
    setSelectedEgde({ edge, type: edgeType });
    setSelectedNode(null);
  };

  const handleEdgeLabelClick = (
    edge: HierarchyLink<Resouce>,
    edgeType: EdgeClassType = 'edge-labels'
  ) => {
    setSelectedEgdeLabel({ edge, type: edgeType });
    setSelectedNode(null);
  };

  const handleSidePanelClose = () => {
    setSelectedNode(null);
    setSelectedEgde(null);
  };

  const handleTriggerEdgeFocus = (edge: string) => {
    if (canvasViewRef.current) {
      canvasViewRef.current.toggleFocusedEdge(edge);
    }
  };

  const handleTriggerNodeFocus = (node: string) => {
    if (canvasViewRef.current) {
      canvasViewRef.current.toggleFocusedNode(node);
    }
  };

  const handleVisibilityChange = (visibility: Visibility) => {
    setVisibility(visibility);
  };

  if (error500) {
    return <Error500 />;
  }

  return (
    <>
      <span className='topology-container'>
        {!dataLoading && filteredData ? (
          <TopologyCanvasView
            ref={canvasViewRef}
            data={filteredData}
            onNodeHover={handleNodeHover}
            onNodeLeave={handleNodeLeave}
            onNodeClick={handleNodeClick}
            onEdgeClick={handleEdgeClick}
            onEdgeLabelClick={handleEdgeLabelClick}
            zoomContainerCustomStyle={zoomContainerCustomStyle}
            graphParamsCustomStyle={graphParamsCustomStyle}
            legendCustomStyle={legendCustomStyle}
            onVisibilityChange={handleVisibilityChange}
            visibility={visibility}
            selectedNode={selectedNode}
            setOpen={setOpen}
            open={open}
            setLegendOpen={setLegendOpen}
            legendOpen={legendOpen}
            legendContainerStyle={legendContainerStyle}
          />
        ) : (
          <Loading />
        )}
        {hoveredNode && hoveredPosition && (
          <TooltipContainer
            node={hoveredNode}
            position={hoveredPosition}
            namespaces={filteredData.namespaces}
          />
        )}
      </span>
      <SidePanelContainer
        node={selectedNode}
        edge={selectedEdge}
        edgeLabel={selectedEdgeLabel}
        onClose={handleSidePanelClose}
        onTriggerEdgeFocus={handleTriggerEdgeFocus}
        onTriggerNodeFocus={handleTriggerNodeFocus}
        sidePanelWidth={sidePanelWidth}
        handleSidePanelWidth={handleSidePanelWidth}
        handleHeaderWidth={handleHeaderWidth}
        initialHeaderWidth={initialHeaderWidth}
        visibility={visibility}
        headerWidth={headerWidth}
        setOpen={setOpen}
        open={open}
        networkSegments={networkSegments}
      />
    </>
  );
};

export default TopologyContainer;
