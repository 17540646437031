import { MCNM_NETWORK_API_URL } from './config';
import { axiosInstance } from './api';

//Get all of the clouds that MCNM oversees
export async function getClouds() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
    });
    return response.data?.clouds ?? [];
  } catch (error) {
    console.log('error in GET clouds', error);

    throw error;
  }
}

//Add a new cloud that MCNM should oversee
export async function addCloud(data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud`,
      data,
    });
    console.log('SUCCESS IN POST CLOUD');
    return response.data;
  } catch (error) {
    console.log('Error in POST cloud', error);
    throw error;
  }
}
///cloud/{cloud_id}:
// Delete an existing cloud that MCNM oversees
export async function deleteCloud(cloud_id) {
  try {
    const response = await axiosInstance({
      method: 'DELETE',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
    });
    return response;
  } catch (error) {
    console.log('error in DELETE clouds', error);
    throw error;
  }
}

//Get one of the clouds that MCNM oversees
export async function getCloud(cloud_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
    });
    return response.data;
  } catch (error) {
    console.log('error in GET cloud', error);
    throw error;
  }
}

//Update an existing cloud that MCNM oversees
export async function updateCloud(cloud_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}`,
      data,
    });
    console.log('Update Cloud Success');
    return response.data;
  } catch (error) {
    console.log('Error in Update cloud', error);
    throw error;
  }
}

//Get all of the clusters that MCNM oversees
export async function getCloudClusters(cloud_id) {}

//Add a new cluster that MCNM should oversee
export async function addCloudCluster(cloud_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing cluster that MCNM oversees
export async function deleteCloudCluster(cloud_id, cluster_id) {}

//Get one of the clusters that MCNM oversees
export async function getCloudCluster(cloud_id, cluster_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing cluster that MCNM oversees
export async function updateCloudCluster(cloud_id, cluster_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PATCH',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Get all of the namespaces that MCNM oversees
export async function getAllCloudNamespaces(
  cloud_id,
  cluster_id,
  view = 'all'
) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/cluster/${cluster_id}/namespace?visibility=${view}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Add a new namespace that MCNM should oversee
export async function addCloudNamespace(cloud_id, cluster_id) {}

//Delete an existing namespace that MCNM oversees
export async function deleteCloudNamespace(
  cloud_id,
  cluster_id,
  namespace_id
) {}

//Get one of the namespaces that MCNM oversees
export async function getCloudNamespace(cloud_id, cluster_id, namespace_id) {}

//Update an existing namespace that MCNM oversees
export async function updateCloudNamespace(
  cloud_id,
  cluster_id,
  namespace_id
) {}

//Get all of the nodes
export async function getCloudNodes(cloud_id) {}

//Add a new node that MCNM should oversee
export async function addCloudNode(cloud_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/node`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing node that MCNM oversees
export async function deleteCloudNode(cloud_id, node_id) {}

//Get one of the nodes that MCNM oversees
export async function getCloudNode(cloud_id, node_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/node/${node_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing node that MCNM oversees
export async function updateCloudNode(cloud_id, node_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/node/${node_id}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Get all of the vpcs that MCNM oversees
export async function getCloudVpcs(cloud_id, includeInfraOnly = 'all') {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc?include_infra_only=${includeInfraOnly}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Add a new vpc that MCNM should oversee
export async function addCloudVpc(cloud_id, data) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc`,
      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Delete an existing vpc that MCNM oversees
export async function deleteCloudVpc(cloud_id, vpc_id) {}

//Get one of the vpcs that MCNM oversees
export async function getCloudVpc(cloud_id, vpc_id) {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc/${vpc_id}`,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Update an existing vpc that MCNM oversees
export async function updateCloudVpc(cloud_id, vpc_id, data) {
  try {
    const response = await axiosInstance({
      method: 'PUT',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/vpc/${vpc_id}`,

      data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

//Get all of the securitygroups that MCNM oversees
export async function getCloudSecuritygroups(cloud_id, vpc_id) {}

//Add a new securitygroup that MCNM should oversee
export async function addCloudSecuritygroup(cloud_id, vpc_id) {}

//Delete an existing securitygroup that MCNM oversees
export async function deleteCloudSecuritygroup(cloud_id, securitygroup_id) {}

//Get one of the securitygroup that MCNM oversees
export async function getCloudSecuritygroup(cloud_id, securitygroup_id) {}

//Update an existing securitygroup that MCNM oversees
export async function UpdateCloudSecuritygroup(cloud_id, securitygroup_id) {}

export async function getCloudTypes() {
  try {
    const response = await axiosInstance({
      method: 'GET',
      url: `${MCNM_NETWORK_API_URL}/cloudtypes`,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function reRunInfraDiscovery(cloud_id) {
  try {
    const response = await axiosInstance({
      method: 'POST',
      url: `${MCNM_NETWORK_API_URL}/cloud/${cloud_id}/re_discover`,
    });
    return response.data;
  } catch (error) {
    console.log('error in POST re discover', error);
    throw error;
  }
}
