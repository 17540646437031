import React from 'react';
import {
  Tag,
  Tile,
  Link as CarbonLink,
  TooltipDefinition,
} from 'carbon-components-react';

import './ServiceTile.scss';
import { Edit16, TrashCan16 } from '@carbon/icons-react';
import {
  DeploymentData,
  PolicyData,
  ServiceEndPoint,
  Event,
} from '../../../../models/master';
import { useTranslation } from 'react-i18next';
import images from '../../../../images/images';
import { Link } from 'react-router-dom';
import GenericTruncateString from '../../../../components/GenericTruncateString/GenericTruncateString';

interface ports {
  port_number: string;
  protocol: string;
}
interface Props {
  serviceId: string;
  name: string;
  ports: ports[];
  labels: string[] | null;
  openEditModal: (id: string) => void;
  removeService: (id: string, name: string) => void;
  onCloseService: () => void;
  policiesList: PolicyData[] | null;
  deploymentsData: DeploymentData[] | null;
  serviceEndpointsData: ServiceEndPoint[] | null;
  allEventsData: Event[] | null;
}

const ServiceTile = (props: Props) => {
  const {
    serviceId,
    name,
    ports,
    labels,
    allEventsData,
    openEditModal,
    removeService,
    onCloseService,
    policiesList,
    deploymentsData,
    serviceEndpointsData,
    ...rest
  } = props;

  const { t } = useTranslation('applicationDetails');

  const getPortMappedValues = (
    from: number,
    to: number,
    isTooltip?: boolean
  ) => {
    return ports.slice(from, to).map((port, index) => (
      <span key={port.port_number} className='port-label'>
        <div>{t('serviceTableHeader.port')}</div>
        <div className='port-value'>
          <div className='port-number'>{port.port_number}</div>
          <div className='protocol-spacing'>{t(port.protocol)}</div>

          {index < ports.length - 1 && !isTooltip && (
            <span className='ports-spacing'></span>
          )}
        </div>
      </span>
    ));
  };

  const getServiceEndpointsCount = (serviceId: string) => {
    return serviceEndpointsData?.filter(
      serviceEndpoint => serviceEndpoint.service_id === serviceId
    )?.length;
  };

  const getServiceEndpointTypeCount = (serviceId: string) => {
    const filteredServiceEndpoints = serviceEndpointsData?.filter(
      endpoint => endpoint.service_id === serviceId
    );
    const typeCount: { [key: string]: number } = {};

    filteredServiceEndpoints?.forEach(endpoint => {
      const deployment = deploymentsData?.find(
        depl => depl.id === endpoint.deployment_id
      );
      if (deployment) {
        const type = deployment.type as string;
        typeCount[type] = (typeCount[type] || 0) + 1;
      }
    });
    return typeCount;
  };

  const getServiceEndpointErrors = (serviceId: string) => {
    const filteredServiceEndpoints = serviceEndpointsData?.filter(
      endpoint => endpoint.service_id === serviceId
    );

    const errorsEvents: Event[] = [];

    filteredServiceEndpoints?.forEach(endpoint => {
      const events = allEventsData?.filter(
        eventData => eventData.resource_instance === endpoint.resource_id
      );
      if (events) {
        errorsEvents.push(...events);
      }
    });

    return errorsEvents;
  };

  const getServiceEndpointCriticalMajorErrorsCount = (serviceId: string) => {
    const errorsEvents = getServiceEndpointErrors(serviceId);
    const criticalMajorErrorsCount: { [key: string]: number } = {};

    if (errorsEvents) {
      errorsEvents.forEach(event => {
        const severity = event.severity as string;
        criticalMajorErrorsCount[severity] =
          (criticalMajorErrorsCount[severity] || 0) + 1;
      });
    }
    return criticalMajorErrorsCount;
  };

  const renderPorts = () => {
    if (ports.length > 0) {
      return (
        <div className='port-pairs'>
          {getPortMappedValues(0, 4)}
          {ports.length > 4 ? (
            <TooltipDefinition
              tooltipText={getPortMappedValues(4, ports.length, true)}
              direction='bottom'
            >
              {`+${ports.slice(4).length}`}
            </TooltipDefinition>
          ) : (
            ''
          )}
        </div>
      );
    }
  };

  const renderEndpoints = () => {
    const externalCount =
      getServiceEndpointTypeCount(serviceId)['External'] ?? 0;
    const clusterCount = getServiceEndpointTypeCount(serviceId)['Cluster'] ?? 0;
    return (
      <div className='endpoint-pair'>
        <div className='endpoint-header'>{t('servicesTile.endpoints')}</div>
        <div className='endpoint-count'>
          <div className='total-count'>
            {getServiceEndpointsCount(serviceId) ?? 0}
          </div>
          <div className='external-cluster-count'>
            <span className='external-count'>
              {t('servicesTile.external', { externalCount })}
            </span>

            <span> {t('servicesTile.cluster', { clusterCount })}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderEventErrors = () => {
    const criticalCount =
      getServiceEndpointCriticalMajorErrorsCount(serviceId)['critical'] ?? 0;
    const majorCount =
      getServiceEndpointCriticalMajorErrorsCount(serviceId)['major'] ?? 0;
    const eventResourceName =
      getServiceEndpointErrors(serviceId)[0]?.resource_name;
    return (
      <div className='event-error-pair'>
        <div className='event-error-header'>{t('servicesTile.error')}</div>
        <div className='event-error-count'>
          <Link
            className='total-count'
            to={`/event?resource_name=${eventResourceName}`}
          >
            <GenericTruncateString
              str={String(getServiceEndpointErrors(serviceId).length) ?? '0'}
              maxLength={23}
              limit={9}
              tableView={false}
            />
          </Link>
          <div className='critical-major-count'>
            <span className='critical-count'>
              {images.criticalStatusIcon()}{' '}
              {t('servicesTile.critical', { criticalCount })}
            </span>

            <span>
              {' '}
              {images.majorStatusIcon()}{' '}
              {t('servicesTile.major', { majorCount })}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderLabels = () => {
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (!labels) {
      return <div className='labels no-labels' />;
    }
    if (labels.length > 3) {
      return (
        <div className='labels'>
          {labels.slice(0, 3).map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
          <Tag type='green'>{`+${labels.slice(3).length}`}</Tag>
        </div>
      );
    } else {
      return (
        <div className='labels'>
          {labels.map(label => (
            <Tag type='green'>{label}</Tag>
          ))}
        </div>
      );
    }
  };
  return (
    <Tile
      className={
        'service-tile' +
        (labels && labels && labels.length > 0 ? ' labels' : '')
      }
      {...rest}
    >
      <div className='service-name'>
        <div> {name}</div>
        <div className='action-buttons'>
          <CarbonLink
            className='edit-link'
            onClick={() => openEditModal(serviceId)}
          >
            <Edit16 className='edit-svg' />
          </CarbonLink>
          {Array.isArray(policiesList) &&
          policiesList.filter(
            policy => policy?.to?.service?.service_id === serviceId
          ).length > 0 ? (
            <TooltipDefinition
              className='tooltipDeleteService'
              tooltipText={t('servicesTile.deletePolicyText') as string}
              direction='top'
            >
              <CarbonLink className='delete-link disabled'>
                <TrashCan16 className='trash-can-svg' />
              </CarbonLink>
            </TooltipDefinition>
          ) : (
            <CarbonLink
              className='delete-link'
              onClick={() => removeService(serviceId, name)}
            >
              <TrashCan16 className='trash-can-svg' />
            </CarbonLink>
          )}
        </div>
      </div>
      <div className='service-body'>
        <div className='service-ports'>{renderPorts()}</div>
        <div className='service-endpoints'>{renderEndpoints()}</div>
        {getServiceEndpointErrors(serviceId).length > 0 && (
          <div className='service-errors'>{renderEventErrors()}</div>
        )}
      </div>
      <div className='service-labels'>
        <div className='label-header'>{t('servicesTile.label')}</div>
        <div className='label-body'> {renderLabels()}</div>
      </div>
    </Tile>
  );
};

export default ServiceTile;
