import React, { useState } from 'react';
import { Gateway, NetworkSegment, ResourceGroup } from '../../../models/master';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dropdown,
  FormLabel,
  Checkbox,
  TextInput,
  Tooltip,
  Grid,
  Column,
  Accordion,
  AccordionItem,
  TextArea,
} from '@carbon/react';
import { Information16 } from '@carbon/icons-react';
import AddServiceEndpoint from './AddServiceEndpoint';
import { EndpointEntry } from './RegisterExternalService';
import AddLabels from '../../../components/AddLabels/AddLabels';
import { Toggletip } from '@carbon/react';
import { ToggletipButton } from '@carbon/react';
import { ToggletipContent } from '@carbon/react';

const RegisterExternalServiceForm = ({
  formData,
  onChange,
  resourceGroupList,
  networkSegments,
  gateways,
  mode,
}: {
  formData: any;
  onChange: (type: string, payload: any) => void;
  resourceGroupList: ResourceGroup[];
  networkSegments: NetworkSegment[];
  gateways: Gateway[];
  mode: 'addFromDetailsPage' | 'addFromContainerPage' | 'EDIT';
}) => {
  const { t } = useTranslation('registerExternalService');

  return (
    <Grid className='register-external-service-form'>
      <Column lg={16}>
        <div className='section'>
          <div className='section-header'>{t('applicationDetails.header')}</div>
          <div className='section-subheader'>
            {t('applicationDetails.subheader')}
          </div>
          <Grid>
            <Column lg={6}>
              <Dropdown
                light
                readOnly={mode === 'addFromDetailsPage'}
                className='form-item network-segment-dropdown'
                id='select-network-segment-dropdown'
                items={networkSegments}
                itemToString={(item: any) => item?.name ?? ''}
                onChange={(e: any) =>
                  onChange('networkSegment', e.selectedItem)
                }
                size='md'
                titleText={
                  t('applicationDetails.networkSegment.label') as string
                }
                invalid={formData?.networkSegment.error}
                invalidText={t('applicationDetails.networkSegment.emptyError')}
                selectedItem={formData?.networkSegment.value}
                label={
                  t('applicationDetails.networkSegment.placeholder') as string
                }
              />
              <div className='form-item'>
                <div className='form-item-header'>
                  <FormLabel className='label'>
                    {t('applicationDetails.app.label')}
                  </FormLabel>
                  {mode === 'addFromContainerPage' && (
                    <Tooltip
                      label={t('applicationDetails.app.tooltip')}
                      className='info-tooltip'
                    >
                      <Information16 />
                    </Tooltip>
                  )}
                </div>
                {mode === 'addFromContainerPage' && (
                  <Checkbox
                    id='app-name-check'
                    className='app-name-checkbox'
                    checked={formData.application.sameAsService}
                    labelText={t('applicationDetails.app.checkbox')}
                    onChange={(value: any) => {
                      console.log('checkbox change', value);
                      onChange(
                        'applicationNameCheckbox',
                        !formData.application.sameAsService
                      );
                    }}
                  />
                )}
                {!formData.application.sameAsService && (
                  <TextInput
                    readOnly={mode === 'addFromDetailsPage'}
                    id='application-name-input'
                    light
                    labelText=''
                    invalid={formData?.application.error}
                    invalidText={formData?.application.errorMessage}
                    placeholder={t('applicationDetails.app.placeholder')}
                    className='app-name-input'
                    value={formData?.application.name}
                    onChange={(e: any) =>
                      onChange('applicationName', e?.target?.value)
                    }
                  />
                )}
              </div>
            </Column>{' '}
          </Grid>
        </div>
        <div className='section'>
          <div className='section-header'>{t('serviceDetails.header')}</div>
          <div className='section-subheader'>
            {t('serviceDetails.subheader')}
          </div>

          <div className='form-item'>
            <div className='form-item-header'>
              <FormLabel className='label'>
                {t('serviceDetails.service.label')}
              </FormLabel>
              <Toggletip align='right' className='toggletip-svc-name'>
                <ToggletipButton>
                  <Information16 />
                </ToggletipButton>
                <ToggletipContent>
                  <Trans
                    i18nKey={t('serviceDetails.service.tooltip')}
                    components={[
                      <div className='service-name-tooltip-content'>
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href={'https://datatracker.ietf.org/doc/html/rfc1035'}
                        ></a>
                      </div>,
                    ]}
                  />
                </ToggletipContent>
              </Toggletip>
            </div>
            <Grid>
              <Column lg={6}>
                <TextInput
                  id='service-name-input'
                  light
                  labelText=''
                  placeholder={t('serviceDetails.service.placeholder')}
                  value={formData?.service.name}
                  invalid={formData?.service.error}
                  invalidText={formData?.service.errorMessage}
                  onChange={(e: any) =>
                    onChange('serviceName', e?.target?.value)
                  }
                />
              </Column>
            </Grid>
          </div>

          <div className='form-item'>
            <div className='section-header endpoints'>
              {t('serviceDetails.endpoints.header')}
            </div>
            <div className='section-subheader'>
              {t('serviceDetails.endpoints.subheader')}
            </div>

            <AddServiceEndpoint
              serviceEndpoints={formData.service.endpoints}
              updateEndpoints={(seps: EndpointEntry[]) =>
                onChange('endpoints', seps)
              }
              gatewayList={gateways}
            />
          </div>
        </div>

        <div className='section'>
          <div className='section-header'>
            {t('optionalInformation.header')}
          </div>
          <Grid>
            <Column lg={12}>
              <Accordion className='additional-info-sections' size='lg'>
                <AccordionItem title={t('optionalInformation.addtlAppDetails')}>
                  <div className='expanded-section'>
                    <Dropdown
                      readOnly={mode === 'addFromDetailsPage'}
                      id='rg-dropdown'
                      light
                      titleText={t('optionalInformation.appResourceGroup')}
                      items={resourceGroupList}
                      itemToString={(item: ResourceGroup) =>
                        item ? item.name : ''
                      }
                      onChange={(e: any) =>
                        onChange('resourceGroup', e?.selectedItem)
                      }
                      selectedItem={formData?.application?.resourceGroup}
                    />
                    <AddLabels
                      id='app-labels-input'
                      labelText={t('optionalInformation.appLabels')}
                      btnText={t('optionalInformation.addText')}
                      placeholder={t('optionalInformation.addLabels')}
                      onChange={(labels: string[]) =>
                        onChange('applicationLabels', labels)
                      }
                      defaultValues={formData.application.labels}
                    />
                    <TextArea
                      className='text-area'
                      light
                      enableCounter
                      maxCount={300}
                      labelText={t('optionalInformation.description')}
                      onChange={(e: any) =>
                        onChange('description', e?.target?.value)
                      }
                      placeholder={t(
                        'optionalInformation.descriptionPlaceholder'
                      )}
                      value={formData?.application?.description}
                    />
                  </div>
                </AccordionItem>
                <AccordionItem title={t('optionalInformation.addtlSvcDetails')}>
                  <div className='expanded-section'>
                    <AddLabels
                      id='svc-labels-input'
                      labelText={t('optionalInformation.svcLabels')}
                      btnText={t('optionalInformation.addText')}
                      placeholder={t('optionalInformation.addLabels')}
                      onChange={(labels: string[]) =>
                        onChange('serviceLabels', labels)
                      }
                      defaultValues={formData.service.labels}
                    />
                  </div>
                </AccordionItem>
              </Accordion>
            </Column>
          </Grid>
        </div>
      </Column>
    </Grid>
  );
};

export default RegisterExternalServiceForm;
