import React from 'react';
import images from '../images/images';
import { SideNav, SideNavItems, SideNavLink } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, To } from 'react-router-dom';
import {
  removeFiltersFromStorage,
  removeViewStateFromStorage,
} from '../lib/utils';

import './NavSidePanel.scss';
import useAnalytics from '../lib/useAnalytics';
import analyticsData from '../lib/analyticsEventData';

const NavSidePanel: React.FC<{
  onClickSideNavExpand: (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isLeftPanelOpen: boolean;
  onToggle: (
    event: React.FocusEvent<HTMLElement, Element> | null,
    focus: boolean
  ) => void;
}> = ({ onClickSideNavExpand, isLeftPanelOpen, onToggle }) => {
  const { t } = useTranslation('navSidePanel');
  const navigate = useNavigate();
  const { trackButtonClicked } = useAnalytics();

  //assigning location variable
  const location = useLocation();
  const { pathname } = location;

  const handleNavigate = (to: To) => {
    removeFiltersFromStorage();
    removeViewStateFromStorage();
    navigate(to);
    onToggle(null, false);
  };

  return (
    <SideNav
      aria-label='Side navigation'
      isRail
      onOverlayClick={onClickSideNavExpand}
      onToggle={onToggle}
      className={'side-navigation ' + (isLeftPanelOpen ? 'nav-expanded' : '')}
    >
      <SideNavItems>
        <SideNavLink
          renderIcon={images.homeIcon}
          onClick={() => {
            handleNavigate('/');
          }}
          aria-current={pathname === '/' ? 'page' : 'false'}
          title={t('home').length > 21 ? t('home') : ''}
        >
          {t('home')}
        </SideNavLink>
        {localStorage.getItem('SELECTED_ACCOUNT') === 'Prod-Tenant2' && (
          <SideNavLink
            renderIcon={images.edgeApplication}
            onClick={() =>
              window.open(
                'https://cp-console.ieam42-edge-8e873dd4c685acf6fd2f13f4cdfb05bb-0000.us-south.containers.appdomain.cloud/edge',
                '_blank',
                'noreferrer'
              )
            }
            title={t('appPlacement').length > 21 ? t('appPlacement') : ''}
          >
            {t('appPlacement')}
          </SideNavLink>
        )}
        <SideNavLink
          renderIcon={images.topologyIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.topologyNav.props,
              analyticsData['Side Navigation'].events.topologyNav.event
            );
            handleNavigate('/topologies');
          }}
          aria-current={pathname === '/topologies' ? 'page' : 'false'}
          title={t('topology').length > 21 ? t('topology') : ''}
        >
          {t('topology')}
        </SideNavLink>

        <hr className='side__nav__divider' />

        <SideNavLink
          renderIcon={images.CAPolicyIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.policyNav.props,
              analyticsData['Side Navigation'].events.policyNav.event
            );
            handleNavigate('/connectionAccessPolicies');
          }}
          aria-current={pathname.includes('/connection') ? 'page' : 'false'}
          title={t('policies').length > 21 ? t('policies') : ''}
        >
          {t('policies')}
        </SideNavLink>

        <SideNavLink
          renderIcon={images.applicationIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.appNavLink.props,
              analyticsData['Side Navigation'].events.appNavLink.event
            );
            handleNavigate('/applications');
          }}
          aria-current={pathname.includes('/application') ? 'page' : 'false'}
          title={
            t('applicationAndServices').length > 21
              ? t('applicationAndServices')
              : ''
          }
        >
          {t('applicationAndServices')}
        </SideNavLink>

        <SideNavLink
          renderIcon={images.deploymentEnvIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.environmentNav.props,
              analyticsData['Side Navigation'].events.environmentNav.event
            );
            handleNavigate('/deploymentenvironments');
          }}
          aria-current={pathname.includes('/deployment') ? 'page' : 'false'}
          title={
            t('deploymentEnvironments').length > 22
              ? t('deploymentEnvironments')
              : ''
          }
        >
          {t('deploymentEnvironments')}
        </SideNavLink>

        <SideNavLink
          renderIcon={images.cloudIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.cloudNav.props,
              analyticsData['Side Navigation'].events.cloudNav.event
            );
            handleNavigate('/clouds');
          }}
          aria-current={pathname.includes('/cloud') ? 'page' : 'false'}
          title={t('cloud').length > 21 ? t('cloud') : ''}
        >
          {t('cloud')}
        </SideNavLink>

        <SideNavLink
          renderIcon={images.LocationIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.locationNav.props,
              analyticsData['Side Navigation'].events.locationNav.event
            );
            handleNavigate('/locations');
          }}
          aria-current={pathname.includes('/location') ? 'page' : 'false'}
          title={t('locations').length > 21 ? t('locations') : ''}
        >
          {t('locations')}
        </SideNavLink>
        <SideNavLink
          renderIcon={images.EdgeGatewayIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.gatewayNav.props,
              analyticsData['Side Navigation'].events.gatewayNav.event
            );
            handleNavigate('/gateways');
          }}
          aria-current={pathname.includes('/gateway') ? 'page' : 'false'}
          title={t('gateways').length > 21 ? t('gateways') : ''}
        >
          {t('gateways')}
        </SideNavLink>

        <SideNavLink
          renderIcon={images.NetworkSegmentIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.nwSegmentsNav.props,
              analyticsData['Side Navigation'].events.nwSegmentsNav.event
            );
            handleNavigate('/networkSegments');
          }}
          aria-current={pathname.includes('/network') ? 'page' : 'false'}
          title={t('networkSegments').length > 21 ? t('networkSegments') : ''}
        >
          {t('networkSegments')}
        </SideNavLink>

        <hr className='side__nav__divider' />

        <SideNavLink
          renderIcon={images.eventIcon}
          onClick={() => {
            trackButtonClicked(
              analyticsData['Side Navigation'].events.eventsNav.props,
              analyticsData['Side Navigation'].events.eventsNav.event
            );
            handleNavigate('/event');
          }}
          aria-current={pathname.includes('/event') ? 'page' : 'false'}
          title={t('events').length > 21 ? t('events') : ''}
        >
          {t('events')}
        </SideNavLink>
      </SideNavItems>
    </SideNav>
  );
};

export default NavSidePanel;
