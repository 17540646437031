import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Link, Modal } from 'carbon-components-react';
import { CheckmarkOutline16 } from '@carbon/icons-react';
import images from '../../images/images';
import WalkMeModalPng from '../../images/walkmeModal.png';

import './ProgressStepper.scss';

interface Props {
  functionalCookies: boolean;
  icon: JSX.Element;
  title: string;
  text: string;
  link: string;
  url: string;
  useModal?: boolean;
}

const ProgressStep: React.FC<Props> = ({
  functionalCookies,
  icon,
  title,
  text,
  link,
  url,
  useModal,
}) => {
  const { t } = useTranslation('progressStepper');

  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='section'>
      <div className='icon'>{icon}</div>
      <div className='step'>
        <strong>{title}</strong>: {text}
      </div>
      {
        // Disabling switching based on functionalCookies
        // !functionalCookies ? (
        false ? (
          <div className='link link-dis'>
            <Link href={url} disabled renderIcon={() => images.rightArrowSvg()}>
              {link}
            </Link>
          </div>
        ) : (
          <div className='link'>
            {!useModal ? (
              <Button
                kind='ghost'
                href={url}
                renderIcon={() => images.rightArrowSvg()}
              >
                {link}
              </Button>
            ) : (
              <Button
                kind='ghost'
                href={url}
                onClick={() => {
                  setModalOpen(true);
                }}
                renderIcon={() => images.rightArrowSvg()}
              >
                {link}
              </Button>
            )}
          </div>
        )
      }
      <Modal
        className='walkme-modal'
        primaryButtonText={t('modal.begin')}
        open={modalOpen}
        onRequestSubmit={() => {
          navigate('/gateways');
        }}
      >
        <img className='walkme-modal-img' src={WalkMeModalPng} />
        <div className='modal-title'>{t('modal.title')}</div>
        <div className='modal-text'>{t('modal.text')}</div>
        <div className='modal-subtitle'>{t('modal.subtitle')}</div>
        <div className='modal-checklist'>
          <CheckmarkOutline16 />
          {t('modal.checklist.item1')}
        </div>
        <div className='modal-checklist'>
          <CheckmarkOutline16 />
          {t('modal.checklist.item2')}
        </div>
        <div className='modal-checklist'>
          <CheckmarkOutline16 />
          {t('modal.checklist.item3')}
        </div>
        <div className='modal-checklist'>
          <CheckmarkOutline16 />
          {t('modal.checklist.item4')}
        </div>
      </Modal>
    </div>
  );
};

export default ProgressStep;
