import { TFunction } from 'react-i18next';
import {
  NetworkSegment,
  Service,
  PartitionData,
  Port,
} from '../../../models/master';
import { ReactComponent as PartitionIcon } from '../../../images/partition.svg';
import { ChartNetwork16, EdgeService16 } from '@carbon/icons-react';
import images from '../../../images/images';
import { ConnectionSelectedTypes, PolicyDataType } from '../../../lib/enums';

export type PolicyOptionTableResourceTypes =
  | PolicyDataType.SERVICE
  | PolicyDataType.NAMESPACE;

export type PolicyOptionFromTypes =
  | PolicyDataType.NETWORKSEGMENT
  | PolicyDataType.NAMESPACE;

export type PolicyOptionTableDataTypes =
  | ServiceData[]
  | PartitionData[]
  | []
  | null;

export type ConnectionSelectedType =
  | ConnectionSelectedTypes.FROM
  | ConnectionSelectedTypes.TO;

export type ConnectionOptionTypes =
  | PolicyDataType.NAMESPACE
  | PolicyDataType.NETWORKSEGMENT
  | PolicyDataType.SERVICE;

export interface DefaultResourceGroup {
  resource_id: string;
  name: string;
}
export interface Item {
  id: string;
  parentId?: string;
  parentName?: string;
  label: string;
  type: ConnectionOptionTypes;
  cloudName?: string;
  cloudId?: string;
  locationName?: string;
  resourceGroupName?: string;
  resourceGroupId?: string;
  ports?: Port[];
  protocol?: string;
  labels?: string[];
  networkSegmentId?: string;
  serviceSync?: boolean;
  name?: string;
}

export interface ServiceData extends Service {
  id: string;
  applicationName: string;
  network_segment_id?: string;
}

export interface FormDataStep1 {
  name: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  description: {
    value: string;
    error: boolean;
    errorMessage: string;
  };
  resourceGroup: {
    value: {
      resource_id: string;
      name: string;
    };
    error: boolean;
    errorMessage: string;
  };
  labels: {
    value: string[];
    error: boolean;
    errorMessage: string;
  };
}

export interface NetworkSegmentData extends NetworkSegment {
  id: string;
  resourceGroupName?: string;
}

export interface NamespacesData {
  id: string;
  resource_group_id: string;
  resourceGroups?: string;
  resourceGroupName?: string;
  deploymentEnvId: string;
  cloud_name: string;
  location_name: string;
  environment: string;
  deployment_env_name?: string;
}

export type ConnectionSelectedItemsTypes =
  | ServiceData[]
  | NetworkSegmentData[]
  | NamespacesData[]
  | []
  | null;

export interface ConnectionSearchItem {
  type: PolicyDataType;
  suggestionTitle: string;
  suggestionOptions: Item[];
  icon?: any;
  suggestionDisplayKey?: string;
  filterKey?: string;
}

export const getConnectionConfig = (
  type: ConnectionSelectedType,
  t: TFunction
) => {
  const tabConfig = {
    from: [
      {
        icon: ChartNetwork16,
        label: t('nwSegments'),
        name: PolicyDataType.NETWORKSEGMENT,
      },
      {
        icon: PartitionIcon,
        label: t('namespaces'),
        name: PolicyDataType.NAMESPACE,
      },
    ],
    to: [
      {
        icon: EdgeService16,
        label: t('services'),
        name: PolicyDataType.SERVICE,
      },
    ],
  };

  return tabConfig[type];
};
export const getConnectionOptionsConfig = (
  type: ConnectionSelectedType,
  t: TFunction
) => {
  const tabConfig = {
    from: [
      {
        icon: PartitionIcon,
        label: t(PolicyDataType.NAMESPACE),
        name: PolicyDataType.NAMESPACE,
        valueColumn: 'name',
      },
    ],
    to: [
      {
        icon: EdgeService16,
        label: t(PolicyDataType.SERVICE),
        name: PolicyDataType.SERVICE,
        valueColumn: 'name',
      },
    ],
  };

  return tabConfig[type];
};
export const getTableHeaders = (
  type: PolicyOptionTableResourceTypes,
  t: TFunction
) => {
  const headerConfig = {
    namespace: [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'formattedName',
        originalKey: 'formattedName',
        header: t('table.partitions.name'),
        sort: 'sortByName',
      },
      {
        key: 'cloudName',
        originalKey: 'cloudName',
        header: t('table.partitions.cloud'),
      },
      {
        key: 'locationName',
        originalKey: 'locationName',
        header: t('table.partitions.location'),
      },
      {
        key: 'typeName',
        originalKey: 'typeName',
        header: t('table.partitions.type'),
      },
      {
        key: 'resourceGroupName',
        originalKey: 'resourceGroupName',
        header: t('table.partitions.resourceGroup'),
        sort: 'sortByResourceGroupName',
      },
    ],
    service: [
      {
        key: 'checkbox',
        originalKey: 'checkbox',
        header: '',
      },
      {
        key: 'formattedName',
        originalKey: 'formattedName',
        header: t('table.services.name'),
        sort: 'sortByName',
      },
      {
        key: 'parentName',
        originalKey: 'parentName',
        header: t('table.services.applicationName'),
        sort: 'sortByApplicationName',
      },
      {
        key: 'portCount',
        header: t('table.services.port'),
      },
      {
        key: 'protocol',
        header: t('table.services.protocol'),
      },
      {
        key: 'labels',
        header: t('table.services.labels'),
      },
    ],
  };

  return headerConfig[type];
};

export const getEmptyStateValue = (
  type: PolicyOptionTableResourceTypes,
  t: TFunction,
  navigate: TFunction
) => {
  const emptyStateConfig = {
    namespace: {
      icon: images.noPartitionsIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.partitions.emptyContainerHeader'),
      emptyStateDescription: t(
        'emptyState.partitions.emptyContainerDescription'
      ),
      link: '/',
      buttonText: t('emptyState.partitions.buttonText'),
      click: () => {},
    },
    service: {
      icon: images.noServicesLargeIcon(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.services.emptyContainerHeader'),
      emptyStateDescription: t('emptyState.services.emptyContainerDescription'),
      link: false,
      buttonText: t('emptyState.services.buttonText'),
      click: () => {},
    },

    networkSegments: {
      icon: images.NotFoundSmall(),
      notFoundIcon: images.NotFoundSmall(),
      emptyStateHeader: t('emptyState.nwSegments.emptyContainerHeader'),
      emptyStateDescription: t(
        'emptyState.nwSegments.emptyContainerDescription'
      ),
      link: false,
      buttonText: t('emptyState.nwSegments.buttonText'),
      click: () => {},
    },
  };

  return emptyStateConfig[type];
};

export const getTableFilters = (
  type: PolicyOptionTableResourceTypes,
  t: TFunction
) => {
  const filters = {
    service: [
      {
        id: 'applications',
        columnKey: 'parentName',
        label: t('table.services.applicationName'),
        type: 'single',
        key: 'parentName',
      },
      {
        id: 'port',
        columnKey: 'ports',
        label: t('table.services.port'),
        type: 'multi',
        key: 'ports',
      },
      {
        id: 'labels',
        columnKey: 'labels',
        label: t('table.services.labels'),
        type: 'multi',
        key: 'labels',
      },
    ],
    namespace: [
      {
        id: 'resourceGroupName',
        columnKey: 'resourceGroupName',
        label: t('table.partitions.resourceGroups'),
        type: 'multi',
        key: 'resourceGroupName',
      },
      {
        id: 'locationName',
        columnKey: 'locationName',
        label: t('table.partitions.location'),
        type: 'multi',
        key: 'locationName',
      },
      {
        id: 'type',
        columnKey: 'type',
        label: t('table.partitions.type'),
        type: 'single',
        key: 'type',
      },
    ],
    networkSegments: [],
  };

  return filters[type];
};
