import React, { useEffect, useState } from 'react';
import { TextInput } from '@carbon/react';
import './APIKeyCustomModal.scss';
import {
  Button,
  ButtonSet,
  InlineLoading,
  PasswordInput,
} from 'carbon-components-react';
import { Toggletip } from '@carbon/react';
import { ToggletipButton } from '@carbon/react';
import { ToggletipContent } from '@carbon/react';
import { Checkmark16, Copy16, ErrorFilled16 } from '@carbon/icons-react';

interface APIKeyModalProps {
  open: boolean;
  customClassName: string;
  apiKey: string;
  apiKeyLabel: string;
  apiKeyHelperText?: string;
  showAPIKeyLabel: string;
  hideAPIKeyLabel: string;
  copyButtonText: string;
  copyIconTooltipText?: string;
  hasAPIKeyVisibilityToggle?: boolean;
  closeButtonText: string;
  cancelButtonText: string;
  generateSuccessTitle: string;
  generateButtonText: string;
  generateSuccessBody?: any;
  modalLabel?: string;
  modalTitle: string;
  modalBody: string;
  nameHelperText?: string;
  nameLabel: string;
  namePlaceholder: string;
  nameRequired?: boolean;
  loading: boolean;
  loadingText: string;
  onRequestGenerate: (keyName: string) => void;
  onClose: () => void;
  onCancel: () => void;
  error?: boolean;
  errorText?: string;
}

const APIKeyCustomModal = ({
  open,
  customClassName = '',
  apiKey = '',
  apiKeyLabel,
  apiKeyHelperText = '',
  showAPIKeyLabel,
  hideAPIKeyLabel,
  copyButtonText,
  copyIconTooltipText,
  hasAPIKeyVisibilityToggle = true,
  closeButtonText,
  cancelButtonText,
  modalLabel,
  modalTitle,
  modalBody,
  generateSuccessTitle,
  generateButtonText,
  generateSuccessBody,
  nameHelperText,
  nameLabel,
  namePlaceholder,
  nameRequired = false,
  loading,
  loadingText,
  onRequestGenerate,
  onClose,
  onCancel,
  error,
  errorText,
}: APIKeyModalProps) => {
  const [apiKeyInput, setApiKeyInput] = useState<string>('');
  const [isKeyGenerated, setIsKeyGenerated] = useState<boolean>(false);

  useEffect(() => {
    setApiKeyInput('');
    setIsKeyGenerated(false);
  }, [open]);

  useEffect(() => {
    apiKey.length > 0 && setIsKeyGenerated(!isKeyGenerated);
  }, [apiKey]);

  const copyApiKey = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
    } catch (err) {
      console.log(err);
    }
  };

  if (open)
    return (
      <>
        <div
          className={`api-key-modal-overlay ${
            open ? 'open-modal' : 'modal-closed'
          }`}
        ></div>
        <div className={`api-key-modal-custom-component ${customClassName}`}>
          <div className='api-key-modal-container'>
            <div className='api-key-modal-content'>
              {!isKeyGenerated && (
                <div className='modal-label'>{modalLabel}</div>
              )}
              <div className='modal-title'>
                {isKeyGenerated ? generateSuccessTitle : modalTitle}
              </div>
              {!isKeyGenerated && (
                <>
                  <div className='modal-body'>{modalBody}</div>
                  <div className='modal-api-name-input-field-container'>
                    {
                      <TextInput
                        id='api-key-modal-name-input'
                        type='text'
                        labelText={nameLabel}
                        helperText={nameHelperText}
                        placeholder={namePlaceholder}
                        value={apiKeyInput}
                        disabled={loading}
                        onChange={(e: any) => {
                          setApiKeyInput(e.target.value);
                        }}
                      />
                    }
                  </div>
                  {error && (
                    <div className='api-key-generate-error-message'>
                      <span className='error-icon'>{<ErrorFilled16 />}</span>
                      <span className='error-message'>{errorText}</span>
                    </div>
                  )}
                </>
              )}
              {isKeyGenerated && (
                <>
                  <div className='modal-api-generated-api-key-field-container'>
                    {
                      <PasswordInput
                        id='api-key-modal-password-input'
                        className={`api-key-modal-password-input ${
                          hasAPIKeyVisibilityToggle
                            ? 'show-visibility-toggle'
                            : 'hide-visibility-toggle'
                        }`}
                        labelText={apiKeyLabel}
                        helperText={apiKeyHelperText}
                        value={apiKey}
                        showPasswordLabel={showAPIKeyLabel}
                        hidePasswordLabel={hideAPIKeyLabel}
                        tooltipPosition={'left'}
                        light={false}
                      />
                    }
                  </div>
                  <div className='generate-success-download-body-text'>
                    {generateSuccessBody}
                  </div>
                </>
              )}
            </div>
            <div className='button-container'>
              <ButtonSet>
                <Button
                  kind='secondary'
                  onClick={isKeyGenerated ? onClose : onCancel}
                >
                  {isKeyGenerated ? closeButtonText : cancelButtonText}
                </Button>
                {loading ? (
                  <InlineLoading
                    className='generate-key-loading-button'
                    style={{
                      marginLeft: '1rem',
                    }}
                    description={loadingText}
                    status={loading ? 'active' : 'finished'}
                  />
                ) : !isKeyGenerated ? (
                  <Button
                    kind='primary'
                    onClick={() => onRequestGenerate(apiKeyInput)}
                    disabled={nameRequired && apiKeyInput.length === 0}
                  >
                    {generateButtonText}
                  </Button>
                ) : (
                  <Toggletip align={'top'} defaultOpen={false}>
                    <ToggletipButton>
                      <Button
                        kind='primary'
                        className='copy-button'
                        onClick={() => copyApiKey()}
                        renderIcon={Copy16}
                      >
                        {copyButtonText}
                      </Button>
                    </ToggletipButton>
                    <ToggletipContent>
                      <div className='tooltip-message-wrapper'>
                        <span className='icon'>
                          <Checkmark16 />
                        </span>
                        <span className='message'>{copyIconTooltipText}</span>
                      </div>
                    </ToggletipContent>
                  </Toggletip>
                )}
              </ButtonSet>
            </div>
          </div>
        </div>
      </>
    );

  return <></>;
};

export default APIKeyCustomModal;
