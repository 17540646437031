import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineNotification } from 'carbon-components-react';
import images from '../../images/images';
import './ProgressStepper.scss';
import ProgressStep from './ProgressStep';

interface Props {
  functionalCookies: boolean;
}

const ProgressStepper: React.FC<Props> = ({ functionalCookies }) => {
  const { t } = useTranslation('progressStepper');

  return (
    <div className='progressStepper'>
      <div className='progressHeader'>{t('header')}</div>
      <div className='progressBody'>
        {!functionalCookies ? (
          <InlineNotification
            kind='info'
            lowContrast
            className='progressNotification'
            subtitle={t('notification.subtitle')}
            title={t('notification.title')}
          />
        ) : null}
        <div className='sections'>
          <ProgressStep
            functionalCookies={functionalCookies}
            icon={images.EdgeGatewayIcon()}
            title={t('step1.title')}
            text={t('step1.text')}
            link={t('step1.link')}
            url='#'
            useModal={true}
          />
          <ProgressStep
            functionalCookies={functionalCookies}
            icon={images.PolicyConnIcon()}
            title={t('step2.title')}
            text={t('step2.text')}
            link={t('step2.link')}
            url='/gateways'
          />
          <ProgressStep
            functionalCookies={functionalCookies}
            icon={images.topologyIcon()}
            title={t('step3.title')}
            text={t('step3.text')}
            link={t('step3.link')}
            url='/topologies'
          />
          <ProgressStep
            functionalCookies={functionalCookies}
            icon={images.CAPolicyIcon()}
            title={t('step4.title')}
            text={t('step4.text')}
            link={t('step4.link')}
            url='/connectionAccessPolicies'
          />
          <ProgressStep
            functionalCookies={functionalCookies}
            icon={images.topologyIcon()}
            title={t('step5.title')}
            text={t('step5.text')}
            link={t('step5.link')}
            url='/topologies'
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressStepper;
