import React, { useContext, useEffect, useState } from 'react';
import {
  Application,
  ApplicationData,
  Deployment,
  Gateway,
  NetworkSegment,
  ResourceGroup,
  Service,
  ServiceEndPoint,
} from '../../../models/master';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import { useTranslation } from 'react-i18next';
import RegisterExternalServiceForm from './RegisterExternalServiceForm';
import { AxiosError } from 'axios';
import { getGateways } from '../../../controllers/gateawayApis';
import {
  domainNameValidation,
  ipRegexPattern,
  nameRegexPattern,
} from '../../../lib/regex';

import './RegisterExternalService.scss';
import {
  addApplication,
  addApplicationService,
  addDeployment,
  addDeploymentServiceEndPoint,
  getDeployments,
} from '../../../controllers/applicationApis';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import { InlineNotification } from '@carbon/react';

export interface EndpointEntry {
  index: number;
  resource_id?: string;
  deploymentId: string;
  gateway: {
    id: string;
    name: string;
    depl_env_id?: string;
    partition_id?: string;
  };
  isHostname: boolean;
  targets: string;
  originalTargets?: string;
  ports: string;
  gatewayError: boolean;
  targetsError: boolean;
  targetsErrorMessage: string;
  portsError: boolean;
  portsErrorMessage: string;
}

interface FormData {
  networkSegment: {
    value: NetworkSegment | null;
    error: boolean;
  };
  application: {
    id: string;
    name: string;
    sameAsService: boolean;
    error: boolean;
    errorMessage: string;
    resourceGroup: {
      resource_id: string;
      name: string;
    };
    labels: string[];
    description: string;
  };
  service: {
    id: string;
    name: string;
    endpoints: EndpointEntry[];
    formattedPorts: { port_number: string; protocol: string }[];
    labels: string[];
    error: boolean;
    errorMessage: string;
  };
}

let defaultFormData = {
  networkSegment: {
    value: null,
    error: false,
  },
  application: {
    id: '',
    name: '',
    sameAsService: true,
    error: false,
    errorMessage: '',
    resourceGroup: {
      name: 'Default_Application_Group',
      resource_id: 'default-app',
    },
    labels: [],
    description: '',
  },
  service: {
    id: '',
    name: '',
    labels: [],
    endpoints: [
      {
        index: 0,
        deploymentId: '',
        isHostname: true,
        gateway: {
          id: '',
          name: '',
          depl_env_id: '',
          partition_id: '',
        },
        targets: '',
        ports: '',
        gatewayError: false,
        targetsError: false,
        targetsErrorMessage: '',
        portsError: false,
        portsErrorMessage: '',
      },
    ],
    formattedPorts: [],
    error: false,
    errorMessage: '',
  },
};

const RegisterExternalService = ({
  applicationData,
  applicationsData,
  networkSegmentData,
  resourceGroupData,
  open,
  onClose,
  successCallback,
  mode,
  selectedNetworkSegment,
}: {
  applicationData: ApplicationData | null;
  applicationsData?: ApplicationData[] | null;
  networkSegmentData?: NetworkSegment[] | null;
  resourceGroupData?: ResourceGroup[] | null;
  selectedNetworkSegment?: NetworkSegment;
  open: boolean;
  onClose: () => void;
  successCallback: (service?: Service, deployments?: Deployment[]) => void;
  mode: 'addFromDetailsPage' | 'addFromContainerPage';
}) => {
  const { t } = useTranslation('registerExternalService');
  const notification = useContext(NotificationContext);
  const [loading, setLoading] = useState(false);
  const [gatewayData, setGatewayData] = useState<Gateway[] | null>(null);
  const [formData, setFormData] = useState<FormData>(defaultFormData);
  const [errorNotification, setErrorNotification] = useState<{
    display: boolean;
    title: string;
    subtitle: string;
  } | null>(null);
  const [validatingForm, setValidatingForm] = useState(false);

  const validateTargets = (str: string) => {
    if (str === '') {
      return {
        error: true,
        isHostname: true,
        errorMessage: t('serviceDetails.endpoints.emptyError'),
      };
    }
    const ipRegexPatt = ipRegexPattern();
    const hostnameRegexPatt = domainNameValidation();
    // must sanitize by removing whitespace first
    const inputArray = str.replace(/\s/g, '').split(',');
    let isHostname = true;
    inputArray.forEach((address: string) => {
      if (!hostnameRegexPatt.test(address)) {
        // if hostname check fails, proceed with ip check
        if (isHostname) isHostname = false;
        if (!ipRegexPatt.test(address)) {
          // if ip check also fails, return an error
          return {
            error: true,
            isHostname,
            errorMessage: t('serviceDetails.endpoints.targets.invalidError'),
          };
        }
      } else {
        // if we have been reading ip targets and the hostname check passes, return error
        if (!isHostname) {
          return {
            error: true,
            isHostname,
            errorMessage: t('serviceDetails.endpoints.targets.duplicateError'),
          };
        }
      }
    });

    return { error: false, isHostname, errorMessage: '' };
  };

  const validatePorts = (str: string) => {
    let error = false;
    let errorMessage = '';
    let ports: { port_number: string; protocol: string }[] = [];
    if (str === '') {
      return {
        errorMessage: t('serviceDetails.endpoints.emptyError'),
        error: true,
        ports,
      };
    }
    const inputArray = str.replace(/\s/g, '').split(',');
    inputArray.forEach((port: string) => {
      const portNumber = Number(port);
      if (!(portNumber > 1 && portNumber < 65535)) {
        error = true;
        errorMessage = t('serviceDetails.endpoints.ports.invalidError');
      }

      ports.push({
        port_number: port,
        protocol: 'TCP',
      });
    });

    return {
      ports,
      error,
      errorMessage,
    };
  };

  const validateAppAndSvcNames = (appName: string, svcName: string) => {
    let appNameError = false;
    let svcNameError = false;
    let appNameErrorMessage = '';
    let svcNameErrorMessage = '';

    if (appName === '' || svcName === '') {
      return {
        appNameError: appName === '',
        svcNameError: svcName === '',
        appNameErrorMessage:
          appName === '' ? t('applicationDetails.app.emptyError') : '',
        svcNameErrorMessage:
          svcName === '' ? t('serviceDetails.service.emptyError') : '',
      };
    }

    if (applicationData !== null) {
      // if we are creating service from app details, only validate service name
      const appServices = applicationsData?.find(
        (app: Application) =>
          app.resource_id === applicationData?.resource_id &&
          app.network_segment_id === applicationData?.network_segment_id
      )?.services;
      if (appServices) {
        if (appServices.filter((svc: Service) => svc.name === svcName)) {
          svcNameError = true;
          svcNameErrorMessage = t('serviceDetails.service.uniqueError');
        }
      }
    } else {
      const matchedApplication = applicationsData?.find(
        (app: Application) =>
          app.name === appName &&
          app.network_segment_id === formData?.networkSegment.value?.resource_id
      );

      if (matchedApplication) {
        appNameError = true;
        appNameErrorMessage = t('applicationDetails.app.uniqueError');
      }
    }

    const nameRegex = nameRegexPattern();

    if (!nameRegex.test(svcName)) {
      svcNameError = true;
      svcNameErrorMessage = t('serviceDetails.service.regexError');
    }

    return {
      appNameError,
      svcNameError,
      appNameErrorMessage,
      svcNameErrorMessage,
    };
  };

  const validateEndpoints = (endpoints: EndpointEntry[]) => {
    let valid = true;
    let formattedPorts: { port_number: string; protocol: string }[] = [];
    const validatedEndpoints = endpoints.map(
      (endpoint: EndpointEntry, index: number) => {
        // check that all fields are filled
        if (
          endpoint.gateway.id === '' &&
          endpoint.targets === '' &&
          endpoint.ports === '' &&
          index === 0
        ) {
          return {
            ...endpoint,
            gatewayError: endpoints.length === 1,
            targetsError: endpoints.length === 1,
            portsError: endpoints.length === 1,
          };
        }

        const {
          error: targetsError,
          isHostname,
          errorMessage: targetsErrorMessage,
        } = validateTargets(endpoint.targets);

        let portsError = false;
        let portsErrorMessage = '';
        if (index === 0) {
          const { ports, error, errorMessage } = validatePorts(endpoint.ports);
          formattedPorts = ports;
          portsError = error;
          portsErrorMessage = errorMessage;
        }

        if ((targetsError || portsError) && valid !== false) {
          valid = false;
        }

        return {
          ...endpoint,
          gatewayError: endpoint.gateway.id === '',
          targetsError,
          isHostname,
          targetsErrorMessage,
          portsError,
          portsErrorMessage,
        };
      }
    );

    return {
      valid,
      validatedEndpoints,
      formattedPorts,
    };
  };

  const validateForm = () => {
    setValidatingForm(true);
    const {
      appNameError,
      appNameErrorMessage,
      svcNameError,
      svcNameErrorMessage,
    } = validateAppAndSvcNames(
      formData?.application?.name,
      formData?.service?.name
    );
    const {
      validatedEndpoints,
      valid: endpointsValid,
      formattedPorts,
    } = validateEndpoints(formData?.service?.endpoints);

    setFormData((prev: FormData) => ({
      ...prev,
      networkSegment: {
        ...prev.networkSegment,
        error: prev.networkSegment.value === null ? true : false,
      },
      application: {
        ...prev.application,
        error: appNameError,
        errorMessage: appNameErrorMessage,
      },
      service: {
        ...prev.service,
        endpoints: validatedEndpoints,
        error: svcNameError,
        errorMessage: svcNameErrorMessage,
        formattedPorts,
      },
    }));

    let noEndpoints =
      validatedEndpoints.length === 1 &&
      validatedEndpoints[0].gateway.id === '' &&
      validatedEndpoints[0].targets === '' &&
      validatedEndpoints[0].ports === '';

    if (appNameError || svcNameError || !endpointsValid) {
      setErrorNotification({
        display: true,
        title: t('errorNotification.missingInfo.title'),
        subtitle: t('errorNotification.missingInfo.subtitle'),
      });
      setValidatingForm(false);
    } else if (noEndpoints) {
      setErrorNotification({
        display: true,
        title: t('errorNotification.noEndpoints.title'),
        subtitle: t('errorNotification.noEndpoints.subtitle'),
      });
    }

    if (!appNameError && !svcNameError && endpointsValid && !noEndpoints) {
      handleSubmit(validatedEndpoints, formattedPorts);
    }
  };

  const handleOnChange = (type: string, payload: any) => {
    setErrorNotification((prev: any) => ({
      ...prev,
      display: false,
    }));
    switch (type) {
      case 'networkSegment':
        setFormData((prev: any) => ({
          ...prev,
          networkSegment: { value: payload, error: false },
        }));
        break;
      case 'applicationNameCheckbox':
        setFormData((prev: any) => ({
          ...prev,
          application: {
            ...prev.application,
            sameAsService: payload,
            name: prev.service.name,
          },
        }));
        break;
      case 'applicationName':
        setFormData((prev: any) => ({
          ...prev,
          application: {
            ...prev.application,
            name: payload,
            error: false,
            errorMessage: '',
          },
        }));
        break;
      case 'serviceName':
        if (formData?.application?.sameAsService) {
          setFormData((prev: any) => ({
            ...prev,
            application: {
              ...prev?.application,
              name: payload,
            },
            service: {
              ...prev.service,
              name: payload,
              error: false,
              errorMessage: '',
            },
          }));
        } else {
          setFormData((prev: any) => ({
            ...prev,
            service: {
              ...prev.service,
              name: payload,
              error: false,
              errorMessage: '',
            },
          }));
        }
        break;
      case 'endpoints':
        setFormData((prev: any) => ({
          ...prev,
          service: {
            ...prev.service,
            endpoints: payload,
          },
        }));
        break;
      case 'resourceGroup':
      case 'description':
        setFormData((prev: any) => ({
          ...prev,
          application: {
            ...prev.application,
            [type]: payload,
          },
        }));
        break;
      case 'serviceLabels':
        setFormData((prev: any) => ({
          ...prev,
          service: {
            ...prev.service,
            labels: payload,
          },
        }));
        break;
      case 'applicationLabels':
        setFormData((prev: any) => ({
          ...prev,
          application: {
            ...prev.application,
            labels: payload,
          },
        }));
    }
  };

  const createApplication = async () => {
    try {
      const appData = {
        name: formData?.application?.name,
        network_segment_id: formData?.networkSegment.value?.resource_id,
        resource_group_id: formData?.application?.resourceGroup?.resource_id,
        labels: formData?.application.labels,
        description: formData?.application.description,
      };

      const createdApp = await addApplication(appData);

      return createdApp;
    } catch (error) {
      const err = error as AxiosError;
      return null;
    }
  };

  const createService = async (
    appId: string,
    formattedPorts: { port_number: string; protocol: string }[]
  ) => {
    try {
      const svcData = {
        name: formData?.service.name,
        labels: formData?.service.labels,
        ports: formattedPorts,
      };

      const createdService = await addApplicationService(appId, svcData);
      return createdService;
    } catch (error) {
      const err = error as AxiosError;
      return null;
    }
  };

  const closeTearsheet = () => {
    setFormData(defaultFormData);
    onClose();
  };

  const createResources = async (
    endpoint: EndpointEntry,
    existingDeployments: Deployment[],
    appId: string,
    service: Service
  ) => {
    let deployment: Deployment;
    let newDeployment = false;
    const matchedDeployment = existingDeployments.find(
      (depl: Deployment) =>
        depl.depl_env_id === endpoint.gateway.depl_env_id &&
        depl.partition_id === endpoint.gateway.partition_id &&
        depl.application_id === appId
    );

    // if the endpoint chosen gateway does not have a deployment, then we must create one first before register SEP
    if (!matchedDeployment) {
      newDeployment = true;
      let deploymentData = {
        depl_env_id: endpoint.gateway.depl_env_id,
        partition_id: endpoint.gateway.partition_id,
        type: 'external',
        application_id: appId,
      };

      const createdDeployment = await addDeployment(appId, deploymentData);
      deployment = createdDeployment;
    } else {
      deployment = matchedDeployment;
    }

    // finally, register SEP
    const sepData = {
      service_id: service?.resource_id,
      deployment_id: deployment?.resource_id,
      depl_env_id: deployment?.depl_env_id,
      hostname_targets: endpoint.isHostname ? endpoint.targets : '',
      ip_targets: endpoint.isHostname ? '' : endpoint.targets,
      local_service_ip_address: '',
    };
    const createdSEP = await addDeploymentServiceEndPoint(
      appId,
      deployment?.resource_id,
      sepData
    );

    return {
      createdSEP,
      deployment,
      newDeployment,
    };
  };

  const handleSubmit = async (
    endpoints: EndpointEntry[],
    formattedPorts: { port_number: string; protocol: string }[]
  ) => {
    try {
      // create app if needed
      let appId = '';
      if (applicationData) {
        appId = applicationData?.resource_id;
      } else {
        const createdApp = await createApplication();
        appId = createdApp?.resource_id;
      }

      // use app to create service
      const createdService = await createService(appId, formattedPorts);

      const createdDeployments: Deployment[] = [];

      // go through endpoints and create deployment if needed
      if (
        endpoints.length > 0 &&
        endpoints[0].gateway.id !== '' &&
        endpoints[0].targets !== '' &&
        endpoints[0].ports !== ''
      ) {
        const existingDeployments = await fetchAppDeployments(appId);
        const createdEndpoints: ServiceEndPoint[] = [];

        for (const sep of endpoints) {
          const { createdSEP, deployment, newDeployment } =
            await createResources(
              sep,
              existingDeployments,
              appId,
              createdService
            );
          createdEndpoints.push(createdSEP);
          if (newDeployment) {
            createdDeployments.push(deployment);
          }
        }
      }

      if (mode === 'addFromDetailsPage') {
        successCallback(createdService, createdDeployments);
      } else {
        successCallback();
      }
      closeTearsheet();
    } catch (error) {
      const err = error as AxiosError;
      console.log('There was an error with register external service.', err);
    }
  };

  const fetchGateways = async () => {
    try {
      const gateways = await getGateways();

      setGatewayData(gateways);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAppDeployments = async (appId: string) => {
    try {
      const deployments = await getDeployments(appId);
      return deployments;
    } catch (error) {
      const err = error as AxiosError;
      return null;
    }
  };

  const filterGateways = (gateways: Gateway[] | null) => {
    if (gateways) {
      return gateways.filter(
        gateway =>
          gateway.deployed_in_depl_env_id && gateway.deployed_in_partition_id
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (
      applicationData &&
      mode === 'addFromDetailsPage' &&
      selectedNetworkSegment
    ) {
      setFormData({
        ...defaultFormData,
        networkSegment: {
          value: selectedNetworkSegment ? selectedNetworkSegment : null,
          error: false,
        },
        application: {
          ...defaultFormData.application,
          id: applicationData?.resource_id,
          name: applicationData?.name,
          sameAsService: false,
          resourceGroup: {
            resource_id: applicationData?.resource_group_id,
            name:
              resourceGroupData?.find(
                (rg: ResourceGroup) =>
                  rg.resource_id === applicationData?.resource_group_id
              )?.name ?? '',
          },
          labels: applicationData?.labels,
          description: applicationData?.description,
        },
      });
    }
  }, [applicationData, selectedNetworkSegment]);

  useEffect(() => {
    fetchGateways();
  }, []);

  return (
    <WideTearsheet
      className='register-external-service-tearsheet'
      title={t('title')}
      description={t('description')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('submitButtonText'),
          onClick: () => validateForm(),
          loading: loading,
          disabled: errorNotification?.display ? true : false,
        },
        {
          kind: 'secondary',
          label: t('cancelButtonText'),
          onClick: () => closeTearsheet(),
        },
      ]}
    >
      <RegisterExternalServiceForm
        formData={formData}
        onChange={handleOnChange}
        resourceGroupList={resourceGroupData ?? []}
        networkSegments={networkSegmentData ?? []}
        gateways={filterGateways(gatewayData) ?? []}
        mode={mode}
      />
      {errorNotification?.display && (
        <InlineNotification
          kind='error'
          title={errorNotification?.title}
          subtitle={errorNotification?.subtitle}
          onClose={() =>
            setErrorNotification({
              display: false,
              title: '',
              subtitle: '',
            })
          }
        />
      )}
    </WideTearsheet>
  );
};

export default RegisterExternalService;
