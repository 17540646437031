import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _, { update } from 'lodash';
import { TextInput, Column, Grid, FormLabel, Tooltip } from '@carbon/react';
import { Information16 } from '@carbon/icons-react';
import {
  DeploymentData,
  Gateway,
  ServiceData,
  ServiceEndPoint,
} from '../../../models/master';
import AddLabels from '../../../components/AddLabels/AddLabels';
import WideTearsheet from '../../../components/WideTearsheet/WideTearsheet';
import AddServiceEndpoint from '../../ApplicationsContainer/RegisterExternalService/AddServiceEndpoint';
import { EndpointEntry } from '../../ApplicationsContainer/RegisterExternalService/RegisterExternalService';
import {
  addDeployment,
  addDeploymentServiceEndPoint,
  deleteDeploymentServiceEndPoint,
  updateApplicationService,
  updateDeploymentServiceEndPoint,
} from '../../../controllers/applicationApis';

import './EditExternalService.scss';
import { domainNameValidation, ipRegexPattern } from '../../../lib/regex';
import { AxiosError } from 'axios';

const EditExternalService = ({
  applicationId,
  applicationName,
  service,
  networkSegmentName,
  labels,
  externalEndpoints,
  clusterEndpoints,
  open,
  gateways,
  deployments,
  closeTearsheet,
}: {
  applicationId: string;
  applicationName: string;
  service: ServiceData;
  networkSegmentName: string;
  labels: string[];
  externalEndpoints: ServiceEndPoint[];
  clusterEndpoints: ServiceEndPoint[];
  open: boolean;
  gateways: Gateway[];
  deployments: DeploymentData[];
  closeTearsheet: () => void;
}) => {
  const { t } = useTranslation('registerExternalService');
  const [loading, setLoading] = useState(false);
  const [endpointsData, setEndpointsData] = useState<EndpointEntry[]>([]);
  const [labelsData, setLabelsData] = useState<string[]>([...labels]);

  const handleChange = (type: string, payload: any) => {
    if (type === 'labels') {
      setLabelsData(payload);
    } else if (type === 'endpoints') {
      if (endpointsData.length === 0 && payload.length === 1) {
        setEndpointsData([
          {
            ...payload[0],
            ports: service?.ports
              ?.map(
                (port: { port_number: string; protocol: string }) =>
                  port.port_number
              )
              .join(', '),
          },
        ]);
      } else {
        setEndpointsData([...payload]);
      }
    }
  };

  const enableForm = () => {
    if (!_.isEqual(labels, labelsData)) {
      return true;
    }

    if (endpointsData.length === 0 && externalEndpoints?.length > 0) {
      return true;
    }

    let nonEmptyEntry = false;

    for (const endpoint of endpointsData) {
      // skip over empty entries of endpoints
      if (endpoint.gateway.id === '' && endpoint.targets === '') {
        continue;
      }
      nonEmptyEntry = true;
      const oldEndpoint = externalEndpoints[endpoint.index];
      if (!oldEndpoint) {
        // a new endpoint has been created
        return true;
      } else {
        let targetsToCheck = endpoint.isHostname
          ? oldEndpoint?.hostname_targets
          : oldEndpoint?.ip_targets;
        if (targetsToCheck !== endpoint.targets) {
          return true;
        }
      }
    }

    return nonEmptyEntry;
  };

  const formatEndpoints = () => {
    let takenGateways: string[] = [];
    const formattedEndpoints = externalEndpoints.map(
      (endpoint: ServiceEndPoint, index: number) => {
        if (endpoint.gatewayId) {
          takenGateways.push(endpoint.gatewayId);
        }
        return {
          index,
          resource_id: endpoint.resource_id,
          deploymentId: endpoint.deployment_id,
          gateway: {
            id: endpoint.gatewayId ?? '',
            name: endpoint.gatewayName ?? '',
          },
          isHostname: endpoint.hostname_targets !== '',
          originalTargets:
            (endpoint.hostname_targets !== ''
              ? endpoint.hostname_targets
              : endpoint.ip_targets) ?? '',
          targets:
            (endpoint.hostname_targets !== ''
              ? endpoint.hostname_targets
              : endpoint.ip_targets) ?? '',
          ports: service?.ports.map((port: any) => port.port_number).join(','),
          gatewayError: false,
          targetsError: false,
          targetsErrorMessage: '',
          portsError: false,
          portsErrorMessage: '',
        };
      }
    );
    setEndpointsData(formattedEndpoints);
  };

  const checkFormValid = () => {
    let someError = false;
    const validatedEndpoints = endpointsData.map(
      (sep: EndpointEntry, index: number) => {
        const { error, isHostname, errorMessage } = validateTargets(
          sep.targets
        );
        if (error) {
          someError = true;
        }
        return {
          ...sep,
          targetsError: error,
          isHostname: isHostname,
          targetsErrorMessage: errorMessage,
        };
      }
    );
    return {
      someError,
      validatedEndpoints,
    };
  };

  const validateTargets = (str: string) => {
    if (str === '') {
      return {
        error: true,
        isHostname: true,
        errorMessage: t('serviceDetails.endpoints.emptyError'),
      };
    }
    const ipRegexPatt = ipRegexPattern();
    const hostnameRegexPatt = domainNameValidation();
    // must sanitize by removing whitespace first
    const inputArray = str.replace(/\s/g, '').split(',');
    let isHostname = true;
    for (const address of inputArray) {
      if (!hostnameRegexPatt.test(address)) {
        // if hostname check fails, proceed with ip check
        if (!ipRegexPatt.test(address)) {
          // if ip check also fails, return an error
          return {
            error: true,
            isHostname,
            errorMessage: t('serviceDetails.endpoints.targets.invalidError'),
          };
        } else {
          if (isHostname && inputArray.length > 1) {
            return {
              error: true,
              isHostname,
              errorMessage: t(
                'serviceDetails.endpoints.targets.duplicateError'
              ),
            };
          }
        }
        if (isHostname) isHostname = false;
      } else {
        // if we have been reading ip targets and the hostname check passes, return error
        if (!isHostname) {
          return {
            error: true,
            isHostname,
            errorMessage: t('serviceDetails.endpoints.targets.duplicateError'),
          };
        }
      }
    }

    return { error: false, isHostname, errorMessage: '' };
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const { someError, validatedEndpoints } = checkFormValid();
      setEndpointsData(validatedEndpoints);
      if (!someError) {
        let originalEndpointIds = externalEndpoints;
        if (!_.isEqual(labels, labelsData)) {
          await updateApplicationService(applicationId, service?.id, {
            labels: labelsData,
          });
        }
        for (const endpoint of validatedEndpoints) {
          if (endpoint.resource_id) {
            // existing endpoint
            // filter out to check progress
            originalEndpointIds = originalEndpointIds.filter(
              (sep: ServiceEndPoint) => sep.resource_id !== endpoint.resource_id
            );
            if (endpoint.targets !== endpoint.originalTargets) {
              let obj = endpoint.isHostname
                ? {
                    hostname_targets: endpoint.targets,
                  }
                : { ip_targets: endpoint.targets };
              // update existing endpoint
              await updateDeploymentServiceEndPoint(
                applicationId,
                endpoint.deploymentId,
                endpoint.resource_id,
                obj
              );
            }
          } else {
            // new endpoint
            // check app deployments for exsting deployment for GW
            let deployment = null;
            const matchedDeployment = deployments?.find(
              (depl: DeploymentData) =>
                depl.environment.id === endpoint.gateway.depl_env_id &&
                depl.partitionId === endpoint.gateway.partition_id &&
                depl.type === 'External'
            );

            if (!matchedDeployment) {
              // must create deployment for new SEP
              deployment = await addDeployment(applicationId, {
                type: 'external',
                depl_env_id: endpoint.gateway.depl_env_id,
                partition_id: endpoint.gateway.partition_id,
              });
            } else {
              deployment = matchedDeployment;
            }

            if (deployment && (deployment.resource_id || deployment.id)) {
              const sepData = {
                service_id: service?.id,
                depl_env_id: deployment?.depl_env_id
                  ? deployment.depl_env_id
                  : deployment?.environment?.id,
                hostname_targets: endpoint.isHostname ? endpoint.targets : '',
                ip_targets: endpoint.isHostname ? '' : endpoint.targets,
                local_service_ip_address: '',
              };
              await addDeploymentServiceEndPoint(
                applicationId,
                deployment.resource_id ? deployment.resource_id : deployment.id,
                sepData
              );
            }
          }
        }

        // check remaining original endpoints that were removed by user and delete them
        await Promise.all(
          originalEndpointIds.map((sep: ServiceEndPoint) =>
            deleteDeploymentServiceEndPoint(
              applicationId,
              sep.deployment_id,
              sep.resource_id
            )
          )
        );

        // close tearsheet and fetch new data
        closeTearsheet();
      }
    } catch (error) {
      const err = error as AxiosError;
      console.log('There was an error updating the service', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    formatEndpoints();
  }, [gateways]);

  return (
    <WideTearsheet
      className='edit-external-service-tearsheet'
      title={t('editService.title', { name: service?.name })}
      description={t('editService.subtitle')}
      open={open}
      actions={[
        {
          kind: 'primary',
          label: t('editService.save'),
          onClick: () => handleSave(),
          loading: loading,
          disabled: !enableForm(),
        },
        {
          kind: 'secondary',
          label: t('editService.cancel'),
          onClick: () => closeTearsheet(),
        },
      ]}
    >
      <Grid className='edit-service-form'>
        <Column lg={12}>
          <div className='section'>
            <div className='section-header'>
              {t('editService.serviceHeader')}
            </div>
            <Grid className='row'>
              <Column lg={6}>
                <TextInput
                  id='edit-svc-name'
                  labelText={t('editService.serviceName')}
                  value={service?.name}
                  readOnly
                />
              </Column>
            </Grid>
            <Grid className='row'>
              <Column lg={6}>
                <TextInput
                  id='edit-svc-network-segment-name'
                  labelText={t('editService.networkSegmentName')}
                  value={networkSegmentName}
                  readOnly
                />
              </Column>
              <Column lg={6}>
                <TextInput
                  id='edit-svc-app-name'
                  labelText={t('editService.applicationName')}
                  value={applicationName}
                  readOnly
                />
              </Column>
            </Grid>

            <AddLabels
              id='service-labels-input'
              labelText={t('optionalInformation.svcLabels')}
              placeholder={t('optionalInformation.addLabels')}
              btnText={t('optionalInformation.addText')}
              onChange={(labels: string[]) => handleChange('labels', labels)}
              defaultValues={labelsData}
            />
          </div>
          <div className='section'>
            <div className='section-header'>
              {t('editService.endpointsHeader')}
            </div>
            <div className='endpoints-section'>
              <div className='endpoints-header'>
                {t('serviceDetails.endpoints.header')}
              </div>
              <AddServiceEndpoint
                serviceEndpoints={endpointsData}
                gatewayList={gateways}
                updateEndpoints={(seps: EndpointEntry[]) =>
                  handleChange('endpoints', seps)
                }
                editMode={true}
              />
            </div>
            {clusterEndpoints && clusterEndpoints.length > 0 && (
              <div className='endpoints-section'>
                <div className='endpoints-header'>
                  {t('editService.clusterEndpoints.header')}
                  <Tooltip
                    label={t('editService.clusterEndpoints.tooltip')}
                    className='info-tooltip'
                  >
                    <Information16 />
                  </Tooltip>
                </div>
                <div className='endpoints-table'>
                  {clusterEndpoints?.map((endpoint: ServiceEndPoint) => (
                    <Grid>
                      <Column lg={3}>
                        <FormLabel className='label'>
                          {t('serviceDetails.endpoints.gateways.label')}
                        </FormLabel>
                        <div className='value'>{endpoint.gatewayName}</div>
                      </Column>
                      <Column lg={4}>
                        <FormLabel className='label'>
                          {t('editService.clusterEndpoints.selector')}
                        </FormLabel>
                        <div className='value'>
                          {endpoint.selector_target || '—'}
                        </div>
                      </Column>
                      <Column lg={4}>
                        <FormLabel className='label'>
                          {t('serviceDetails.endspoints.ports.label')}
                        </FormLabel>
                        <div className='value'>
                          {service?.ports
                            .map((port: any) => port.port_number)
                            .join(', ')}
                        </div>
                      </Column>
                    </Grid>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Column>
      </Grid>
    </WideTearsheet>
  );
};

export default EditExternalService;
