import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dateUtils from '../../lib/dates';
import {
  DataTable,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  TableToolbarContent,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableProps,
  Pagination,
} from 'carbon-components-react';
import { ApplicationData, DeploymentData, Gateway } from '../../models/master';
import {
  DataTableHeader,
  DataTableRow,
  DataTableCell,
} from '../../models/dataTable';
import images from '../../images/images';
import GenericTruncateString from '../GenericTruncateString/GenericTruncateString';
import './InstancesTable.scss';
import { VerticalEmptyState } from '../EmptyState/EmptyState';
import Error403Card from '../ErrorState/Error403Card';
interface PropsInterface {
  applicationId?: string;
  deploymentData?: DeploymentData[] | null;
  appDetailsData?: ApplicationData | null;
  //the actural type is DeploymentInstance
  rows: any[];
  headers: DataTableHeader[];

  loading: boolean;
  gateways: Gateway[] | null;
  style?: any;
  id: any;
  fetchDeploymentsData?: (id: any, flag: string) => void;
  openAddAppDeployment?: () => void;
  onSearchCallBack: (searchVal: string) => void;
  sortRows(arg0: unknown, direction: string): void;
  error403Flag?: boolean;
}

interface MergeEnvironmentIDInstance {
  resource_id: string;
  application_id: string;
  consumer_ip: string;
  deployment_id: string;
  ip_address: string;
  provider_ip: string;
  created_at: string;
  updated_at: string;
  pod_name: string;
  environment_id: string;
}

const InstancesTable = (Props: PropsInterface) => {
  const {
    appDetailsData,
    rows,
    headers,
    deploymentData,

    gateways,
    loading,

    onSearchCallBack,
    sortRows,
    error403Flag,
  } = Props;

  const { t } = useTranslation('applicationDetails');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );

  const mapToDataTableRows = (
    instanceofDeployment: MergeEnvironmentIDInstance
  ): DataTableRow => {
    return {
      id: instanceofDeployment.resource_id,
      cells: [
        {
          id: instanceofDeployment.resource_id + ':pod_name',
          value: instanceofDeployment.pod_name,
          isEditable: false,
          isEditing: false,
          isValid: true,
          errors: [],
          info: {
            header: 'pod_name',
          },
        },
        {
          id: instanceofDeployment.resource_id + ':ip_address',
          value: instanceofDeployment.ip_address,
          isEditable: false,
          isEditing: false,
          isValid: true,
          errors: [],
          info: {
            header: 'ip_address',
          },
        },
        {
          id: instanceofDeployment.resource_id + ':deploymentType',
          value: getAppDeploymentType(instanceofDeployment.deployment_id),
          isEditable: false,
          isEditing: false,
          isValid: true,
          errors: [],
          info: {
            header: 'deploymentType',
          },
        },
        {
          id: instanceofDeployment.resource_id + ':appDeployment',
          value: `${
            getAppDeploymentGatewayName(
              instanceofDeployment.environment_id || ''
            ) || '-'
          } ${getAppDeploymentName(instanceofDeployment.deployment_id)}`,

          isEditable: false,
          isEditing: false,
          isValid: true,
          errors: [],
          info: {
            header: 'appDeployment',
          },
        },
        {
          id: instanceofDeployment.resource_id + ':updated_at',
          value: !!instanceofDeployment?.updated_at
            ? dateUtils.getUserFriendlyDate(instanceofDeployment.updated_at)
            : '-',
          isEditable: false,
          isEditing: false,
          isValid: true,
          errors: [],
          info: {
            header: 'updated_at',
          },
        },
      ],
      disabled: false,
      isExpanded: false,
      isSelected: false,
    };
  };

  //to merge environment_id to each row of instanceDatas[]
  const mergeEnvironmentIDRows = rows.map(row => {
    const matchedDeployment = deploymentData?.find(
      deployment => deployment.id === row.deployment_id
    );

    return { ...row, environment_id: matchedDeployment?.environment.id };
  });

  const getFilteredRows = () => {
    if (!searchText) {
      return [...mergeEnvironmentIDRows].map(row => mapToDataTableRows(row));
    }
    return mergeEnvironmentIDRows
      ?.filter(deplInstance =>
        deplInstance?.pod_name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase())
      )
      .map(row => mapToDataTableRows(row));
  };

  const handleChangeSort = (index: number) => {
    const newSortDirection =
      sortKey === headers[index].originalKey
        ? sortDirection === 'ASC'
          ? 'DESC'
          : 'ASC'
        : 'ASC';
    setSortDirection(newSortDirection);
    const sortedKey =
      headers[index]?.originalKey !== undefined
        ? headers[index]?.originalKey ?? ''
        : '';
    setSortKey(sortedKey);
    sortRows(
      {
        id: headers[index].originalKey,
        text: headers[index].header,
      },
      newSortDirection
    );
  };

  const handlePagination = (change: any) => {
    setPage(change.page);
    setPageSize(change.pageSize);
  };

  const applySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (e && e.target) onSearchCallBack(e?.target?.value ?? '');
  };

  const getAppDeploymentName = (deployment_id: string) => {
    return deploymentData?.find(row => row.id === deployment_id)?.environment
      .name;
  };

  const getAppDeploymentType = (deployment_id: string) => {
    return deploymentData?.find(row => row.id === deployment_id)?.type;
  };

  const getAppDeploymentGatewayName = (environment_id: string) => {
    return (
      gateways?.find(
        gateways => gateways.deployed_in_depl_env_id === environment_id
      )?.name ?? ''
    );
  };

  const emptyState = () => {
    if (getFilteredRows().length === 0 && !loading) {
      if (searchText.length) {
        return (
          <VerticalEmptyState
            icon={images.NotFoundLarge()}
            header={t('notFound.header')}
            description={t('notFound.description')}
            buttonText={t('notFound.text') ?? ''}
            link={false}
            additionalClassName='small'
            click={() => {}}
          />
        );
      } else {
        return (
          <VerticalEmptyState
            icon={images.NoApplicationDeploymentSmall()}
            header={t('noApplicationInstances')}
            description={t('noApplicationInstancesDes', {
              applicationName: appDetailsData?.name,
            })}
            link={false}
            additionalClassName='small'
          />
        );
      }
    } else {
      return null;
    }
  };

  const render403Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error403Card />
        </div>
      </div>
    );
  };
  return (
    <div className='instances-table-container'>
      <DataTable rows={getFilteredRows()} headers={headers} isSortable>
        {({
          applicationName,
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getTableProps,
          getToolbarProps,
          getTableContainerProps,
        }: {
          applicationName: string;
          rows: DataTableRow[];
          headers: DataTableHeader[];
          getTableProps: () => TableProps;
          getHeaderProps: any;
          getRowProps: any;
          getToolbarProps: any;
          getTableContainerProps: any;
        }) => (
          <TableContainer {...getTableContainerProps()}>
            {!error403Flag && (
              <TableToolbar {...getToolbarProps()}>
                <span className='application-deployments-description'>
                  {t('instances.header.intro')}
                </span>
                <TableToolbarContent>
                  <TableToolbarSearch
                    expanded
                    onChange={e => applySearch(e)}
                    placeholder={t('instances.toolbarSearchPlaceholder')}
                  />
                </TableToolbarContent>
              </TableToolbar>
            )}
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header: DataTableHeader, index: number) => (
                    <TableHeader
                      key={header.key}
                      {...getHeaderProps({
                        header,
                        isSortable: Boolean(header.sort),
                      })}
                      onClick={() =>
                        header.sort ? handleChangeSort(index) : undefined
                      }
                      isSortHeader={Boolean(header.sort)}
                      sortDirection={
                        headers[index].originalKey === sortKey
                          ? sortDirection
                          : 'NONE'
                      }
                      data-testid={`app-depl-table-head-${header?.key}`}
                      style={header?.style ? { ...header.style } : {}}
                    >
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              {rows.length > 0 && (
                <TableBody>
                  {getFilteredRows().length > 0 &&
                    getFilteredRows().map(
                      (row: DataTableRow, index: number) => (
                        <TableRow {...getRowProps({ row })} key={row.id}>
                          {row.cells.map((cell: DataTableCell) => {
                            if (cell.info.header === 'pod_name') {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            } else if (cell.info.header === 'ip_address') {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            } else if (cell.info.header === 'deploymentType') {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            } else if (cell.info.header === 'appDeployment') {
                              return (
                                <TableCell key={cell.id}>
                                  <div className='gateway-field'>
                                    <GenericTruncateString
                                      str={cell.value.split(' ')[0] || '-'}
                                      tableView={true}
                                    />
                                  </div>
                                  <div className='appDeployment-field'>
                                    <GenericTruncateString
                                      str={
                                        cell.value.split(' ').slice(2) || '-'
                                      }
                                      tableView={true}
                                    />
                                  </div>
                                </TableCell>
                              );
                            } else if (cell.info.header === 'updated_at') {
                              return (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      )
                    )}
                </TableBody>
              )}
            </Table>
            {rows.length > 0 && (
              <Pagination
                totalItems={rows.length}
                itemsPerPageText={t('deployments.pagination.itemsPerPage')}
                itemRangeText={(min, max, totalLength) => {
                  return t('deployments.pagination.itemKey', {
                    min: min,
                    max: max,
                    total: totalLength,
                  });
                }}
                pageSize={pageSize}
                page={page}
                pageRangeText={(current, total) =>
                  t('deployments.pagination.pageKey', { sum: total })
                }
                pageSizes={[10, 25, 50, 100, 250]}
                onChange={change => handlePagination(change)}
              />
            )}
          </TableContainer>
        )}
      </DataTable>
      {error403Flag ? render403Container() : null}
      {!error403Flag && getFilteredRows().length === 0 ? emptyState() : null}
    </div>
  );
};

export default InstancesTable;
