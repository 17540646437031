import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { Grid32, List32 } from '@carbon/icons-react';
import { FlexGrid } from '@carbon/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Column,
  ContentSwitcher,
  Row,
  SkeletonPlaceholder,
} from 'carbon-components-react';

import sortData from '../../lib/tableSort';
import { manageFiltersFromStorage } from '../../lib/utils';
import useAnalytics from '../../lib/useAnalytics';
import analyticsData from '../../lib/analyticsEventData';
import { QueryKeys, ResourceTypes } from '../../lib/enums';
import images from '../../images/images';
import {
  AppliedFilter,
  CloudData,
  ResourceGroup,
  Error500Type,
  CloudType,
} from '../../models/master';

import { useCloudTypes, useCloudsData } from '../../hooks/useClouds';
import { useResourceGroupsData } from '../../hooks/useResourceGroups';
import { defaultStaleTime } from '../../hooks/queryDefaults';

import FindAndFilterBar from '../../components/FindAndFilterBar/FindAndFilterBar';
import Header from '../../components/Header/Header';
import SortDropDown from '../../components/SortDropdown/SortDropDown';
import { CardEmptyState } from '../../components/CardEmptyState/CardEmptyState';
import Error403Card from '../../components/ErrorState/Error403Card';
import Error500Card from '../../components/ErrorState/Error500Card';
import CloudCards from './CloudCards/CloudCards';
import CloudTable from './CloudTable/CloudTable';
import RegisterCloud from './RegisterCloud/RegisterCloud';

import './CloudContainer.scss';

type LocationState = {
  resourceType: string;
  navigateBack: boolean;
};

type View = 'table' | 'card';

const CloudContainer = () => {
  const { t } = useTranslation('cloudsContainer');
  const location = useLocation();
  const state = location.state as LocationState;
  const { trackButtonClicked, pageViewed } = useAnalytics();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let viewStateValue = localStorage.getItem('VIEW_STATE') as View;
  const [view, setView] = useState<View>(
    viewStateValue ? viewStateValue : 'table'
  );
  const [currentPageNumber, setPageNumber] = useState(1);
  const [currentPageSize, setPageSize] = useState(25);
  const [cloudData, setcloudData] = useState<CloudData[] | null>(null);
  // const [cloudTypes, setCloudTypes] = useState<CloudType[] | null>(null);
  const [filteredData, setFilteredData] = useState<CloudData[] | []>([]);
  const [filterApplied, setFilterApplied] = useState<AppliedFilter[] | []>([]);
  const [sortKey, setSortKey] = useState('');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | 'NONE'>(
    'NONE'
  );
  const [cloudRefetchingState, setCloudRefetchingState] = useState(false);
  const [registerTearsheetOpen, setRegisterTearsheetOpen] = useState(false);
  let error500: null | Error500Type = null;

  let defaultPermissionMap = {
    resourceGroup: true,
    clouds: true,
  };

  // Queries resource groups
  const {
    data: resourceGroupsData,
    error: resourceGroupError,
    isError: isResourceGroupError,
    isLoading: isResourceGroupListLoading,
    refetch: refreshResourceGroupData,
  } = useResourceGroupsData({
    refetchOnWindowFocus: false,
  });

  if (isResourceGroupError) {
    const error = resourceGroupError as AxiosError;
    defaultPermissionMap.resourceGroup =
      error?.response?.status === 403 ? false : true;
  }

  // Queries cloud types
  const { data: cloudTypes, refetch: refreshCloudTypes } = useCloudTypes();

  // Queries clouds
  const {
    data: clouds,
    isLoading: isCloudListLoading,
    error: cloudError,
    isError: isCloudError,
    refetch: refreshCloudData,
    isRefetching: isCloudListRefetching,
  } = useCloudsData({
    refetchOnWindowFocus: false,
    staleTime: defaultStaleTime,
  });

  if (isCloudError) {
    const error = cloudError as AxiosError;
    defaultPermissionMap.clouds =
      error?.response?.status === 403 ? false : true;
    if (error.response!?.status >= 500) {
      error500 = error.response!?.status.toString() as Error500Type;
    }
  }

  const formatClouds = () => {
    const cloudItems = clouds?.map((item: any) => ({
      ...item,
      autodiscoverValue: item?.auto_discover ? t('infrastructure') : t('off'),
      resourceGroupsPermission: defaultPermissionMap['resourceGroup'],
      resource_name:
        resourceGroupsData && Array.isArray(resourceGroupsData)
          ? resourceGroupsData?.find(
              resource => resource.resource_id === item?.resource_group_id ?? ''
            )?.name ?? ''
          : '',
      cloud_type: item?.is_private ? t('onPrem') : t('public'),
    }));
    setcloudData(cloudItems);
    setFilterApplied(manageFiltersFromStorage());
  };

  const formatCloudList = useMemo(
    () => formatClouds(),
    [resourceGroupsData, clouds]
  );

  useEffect(() => {
    if (!isCloudListRefetching) setCloudRefetchingState(false);
  }, [isCloudListRefetching]);

  const refreshData = () => {
    setSortKey('');
    setCloudRefetchingState(true);
    setSortDirection('NONE');
    setFilterApplied(manageFiltersFromStorage());
    //clear previous 500 error & 403 error status
    if (error500) {
      error500 = null;
    }
    defaultPermissionMap = {
      resourceGroup: true,
      clouds: true,
    };

    refreshResourceGroupData();
    refreshCloudData();
    refreshCloudTypes();
  };

  const handleSort = (data: { id: string; text: string }) => {
    if (cloudData) {
      if (data.id === 'atoz') {
        setSortDirection('ASC');
        const sortedResourceGroups = Array.isArray(cloudData)
          ? cloudData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() > b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setcloudData([...sortedResourceGroups]);
      } else {
        setSortDirection('DESC');
        const sortedResourceGroups = Array.isArray(cloudData)
          ? cloudData.sort((a, b) =>
              a.name?.trim()?.toLowerCase() < b.name?.trim()?.toLowerCase()
                ? 1
                : -1
            )
          : [];
        setcloudData([...sortedResourceGroups]);
      }
    }
  };

  const renderFilter = () => {
    return (
      <FindAndFilterBar
        data={cloudData}
        filteredDataCallback={data => setFilteredData(data as CloudData[] | [])}
        filteredData={
          cloudData
            ? filterApplied?.length > 0
              ? filteredData
              : cloudData
            : null
        }
        filtersApplied={filterApplied as any}
        filtersAppliedCallback={data => setFilterApplied(data)}
        filters={getCloudFilter() as any}
        onRefresh={() => refreshData()}
        persistFilter
      />
    );
  };

  const setPageChange = (pageData: { page: number; pageSize: number }) => {
    setPageNumber(pageData.page);
    setPageSize(pageData.pageSize);
  };

  useEffect(() => {
    pageViewed('Cloud');
  }, []);

  useEffect(() => {
    if (state?.resourceType === ResourceTypes.CLOUD) {
      setRegisterTearsheetOpen(true);
    }
  }, [state]);

  const handleTableSort = (
    data: { id: string; text: string },
    sortDirection: 'ASC' | 'DESC' | 'NONE'
  ) => {
    setSortDirection(sortDirection);
    setSortKey(data.id);
  };

  const getCloudFilter = () => [
    {
      key: 'type',
      label: t('filters.provider'),
      placeHolderText: t('filters.placeholder'),
      type: 'single',
      values: getProvidersList(),
    },
  ];

  const learnMore = {
    url:
      navigator.language === 'fr' ||
      navigator.language === 'fr-FR' ||
      navigator.language === 'fr-BE' ||
      navigator.language === 'fr-CA' ||
      navigator.language === 'fr-CH'
        ? 'https://ibm.biz/mesh-reg-infra-resources-fr'
        : 'https://ibm.biz/mesh-reg-infra-resources',
    label: t('learnMore'),
  };

  const getProviderName = (type: string) => {
    const cloudProvider = cloudTypes?.find(
      (cloud: CloudType) => cloud.code === type
    );
    return cloudProvider?.name ?? '-';
  };

  const getProvidersList = () => {
    const providers: { value: string; label: string }[] = [];
    cloudData?.forEach(cloud => {
      if (!providers.find(item => item.value === cloud.type)) {
        providers.push({
          value: cloud.type,
          label: getProviderName(cloud.type),
        });
      }
    });
    return providers;
  };

  const openRegisterTearsheet = () => {
    trackButtonClicked(
      analyticsData['Cloud'].events.registerCloudBtn.props,
      analyticsData['Cloud'].events.registerCloudBtn.event
    );
    setRegisterTearsheetOpen(true);
  };

  const closeRegisterTearsheet = () => {
    setRegisterTearsheetOpen(false);
    if (state?.navigateBack) {
      navigate(-1);
    }
  };

  const isDataLoading =
    isResourceGroupListLoading || isCloudListLoading || cloudRefetchingState;

  return (
    <div className='cloud-container'>
      <Header
        title={t('header')}
        subTitle={t('subheader')}
        breadcrumbs={[
          {
            url: '/',
            label: t('home'),
          },
        ]}
        learnMoreLink={learnMore}
        actions={[
          {
            kind: 'primary',
            onClick: () => openRegisterTearsheet(),
            text: t('addCloud'),
          },
        ]}
      />
      <div className='page-content'>
        <div className='cloud-switcher'>
          {view !== 'table' ? (
            <>
              {isDataLoading ? (
                <div className='skeleton-sort-drop-down'>
                  <SkeletonPlaceholder className={'sorting-skeleton'} />
                </div>
              ) : (
                <SortDropDown
                  id='cloud-sort-dropdown'
                  size='lg'
                  onSort={handleSort}
                  sortDir={sortDirection}
                />
              )}
            </>
          ) : null}
          <ContentSwitcher className='view-switcher'>
            <Button
              className={
                'switch-button' + (view === 'table' ? ' selected' : '')
              }
              onClick={() => {
                setView('table');
                localStorage.setItem('VIEW_STATE', 'table');
              }}
              renderIcon={List32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('table')}
            />
            <Button
              className={'switch-button' + (view === 'card' ? ' selected' : '')}
              onClick={() => {
                setView('card');
                localStorage.setItem('VIEW_STATE', 'card');
              }}
              renderIcon={Grid32}
              hasIconOnly
              tooltipPosition='bottom'
              iconDescription={t('card')}
            />
          </ContentSwitcher>
        </div>
        <div className='body'>
          {view === 'table' ? (
            <CloudTable
              currentPageNumber={currentPageNumber}
              currentPageSize={currentPageSize}
              sortRows={(
                data: { id: string; text: string },
                direction: 'ASC' | 'DESC' | 'NONE'
              ) => handleTableSort(data, direction)}
              onPageChange={(pageData: { page: number; pageSize: number }) =>
                setPageChange(pageData)
              }
              rows={
                cloudData
                  ? filterApplied?.length > 0
                    ? sortData(filteredData, sortKey, sortDirection)
                    : sortData(cloudData, sortKey, sortDirection)
                  : null
              }
              elementCount={
                cloudData
                  ? filterApplied?.length > 0
                    ? filteredData?.length
                    : cloudData?.length
                  : 0
              }
              filteredDataSet={
                cloudData
                  ? filterApplied?.length > 0
                    ? filteredData
                    : cloudData
                  : null
              }
              filteredDataCallback={data => {
                data && setFilteredData(data as CloudData[] | []);
                setPageNumber(1);
              }}
              data={cloudData}
              filtersSelected={filterApplied as any}
              filtersAppliedCallback={data => setFilterApplied(data)}
              filters={getCloudFilter()}
              persistFilter
              onRefresh={() => refreshData()}
              resourceGroupsData={resourceGroupsData}
              error403Flag={!defaultPermissionMap['clouds']}
              error500Flag={!!error500}
              dataLoading={isDataLoading}
              cloudTypes={cloudTypes}
            />
          ) : !defaultPermissionMap['clouds'] ? (
            <Error403Card />
          ) : error500 ? (
            <Error500Card />
          ) : isDataLoading ? (
            <div>
              {renderFilter()}
              <Row className='skeleton-card-view'>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
                <Column>
                  <SkeletonPlaceholder className='resouce-group-skeleton' />
                </Column>
              </Row>
            </div>
          ) : (
            <div>
              {cloudData && cloudData?.length >= 0 ? renderFilter() : null}
              <div className='card-view'>
                <FlexGrid>
                  <Row className='resource-card-alignment'>
                    {cloudData ? (
                      (filterApplied?.length > 0 ? filteredData : cloudData)
                        ?.length > 0 ? (
                        (filterApplied?.length > 0
                          ? filteredData
                          : cloudData
                        ).map(cloud => (
                          <Column lg={4} md={4} className='resource-card'>
                            <CloudCards
                              cloudId={cloud?.resource_id}
                              type={cloud?.is_private}
                              provider={cloud?.type}
                              providerName={getProviderName(cloud?.type)}
                              autodiscover={cloud?.auto_discover}
                              discoveryStatus={cloud?.discovery_status}
                              cloudName={cloud?.name ?? ''}
                              updated={cloud.updated_at}
                              resourceGroupsPermission={
                                cloud?.resourceGroupsPermission
                              }
                              infrastructureGroup={
                                resourceGroupsData &&
                                Array.isArray(resourceGroupsData)
                                  ? resourceGroupsData?.find(
                                      resource =>
                                        resource.resource_id ===
                                          cloud?.resource_group_id ?? '—'
                                    )?.name ?? '—'
                                  : '—'
                              }
                            />
                          </Column>
                        ))
                      ) : (
                        <CardEmptyState
                          filterApplied={filterApplied}
                          emptyState={{
                            icon: images.NoCloudLarge(),
                            header: t('emptyState.emptyContainerHeader'),
                            description: t(
                              'emptyState.emptyContainerDescription'
                            ),
                          }}
                        />
                      )
                    ) : (
                      <div className='no-resource-group'></div>
                    )}
                  </Row>
                </FlexGrid>
              </div>
            </div>
          )}
        </div>
      </div>
      <RegisterCloud
        open={registerTearsheetOpen}
        closeTearsheet={closeRegisterTearsheet}
        refreshData={refreshData}
        cloudsData={cloudData}
        groups={resourceGroupsData?.filter(
          (group: ResourceGroup) => group.type === 'infrastructure'
        )}
      />
    </div>
  );
};

export default CloudContainer;
