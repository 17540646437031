import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import AppEN from './translation/en/App.json';
import LandingPageEN from './pages/translation/en/LandingPage.json';
import ResourceCardEN from './components/ApplicationCard/translation/en/ResourceCard.json';
// import ServicesTileEN from './pages/ApplicationDetails/ServicesTile/translation/en/ServicesTile.json';
import DetailsCardEN from './components/DetailsCard/translation/en/DetailsCard.json';
import ApplicationsContainerEN from './pages/ApplicationsContainer/translation/en/ApplicationsContainer.json';
import deploymentEnvsContainerEN from './pages/DeploymentEnvsContainer/translation/en/DeploymentEnvsContainer.json';
import ConnectionAccessPoliciesEN from './pages/ConnectionAccessPolicies/translation/en/ConnectionAccessPolicies.json';
import DeploymentEnvDetailsEN from './pages/DeploymentEnvDetails/translation/en/DeploymentEnvDetails.json';

import ConnectionAccessPolicyDetailsEN from './pages/ConnectionAccessPolicies/translation/en/ConnectionAccessPolicyDetails.json';
import NavSidePanelEN from './pages/translation/en/NavSidePanel.json';
import OverviewTileEN from './components/OverviewTile/translation/en/OverviewTile.json';
import ApplicationDetailsEN from './pages/ApplicationDetails/translation/en/ApplicationDetails.json';
import CreatePolicyEN from './pages/ConnectionAccessPolicies/CreateConnectionAccessPolicy/translation/en/CreatePolicy.json';

import CreatePolicyAllowConnectionsEN from './pages/ConnectionAccessPolicies/CreateConnectionAccessPolicy/PolicyAllowConnections/translation/en/PolicyAllowConnections.json';
import CreatePolicyOptionsEN from './pages/ConnectionAccessPolicies/CreateConnectionAccessPolicy/PolicyOptions/translation/en/PolicyOptions.json';
import SortDropDownEN from './components/SortDropdown/Translation/en/SortDropDown.json';
import FindAndFilterBarEN from './components/FindAndFilterBar/translation/en/FindAndFilterBar.json';
import GenericTableWithFiltersEN from './components/GenericTableWithFilters/translation/en/GenericTableWithFilters.json';
import ResourceGroupsContainerEN from './pages/ResourceGroups/translation/en/ResourceGroupsContainer.json';
import TopologyPageEN from './pages/TopologyPage/translation/en/TopologyPage.json';
import LoginPageEN from './pages/translation/en/LoginPage.json';
import CallBackHandlerEN from './pages/translation/en/CallBackHandler.json';
import RegisterDeplEnvEN from './pages/DeploymentEnvsContainer/RegisterDeploymentEnv/translation/en/RegisterDeploymentEnv.json';
import CardEmptyStateEN from './components/CardEmptyState/translation/en/CardEmptyState.json';
import LocationEN from './pages/Locations/translation/en/Locations.json';
import LocationDetailsEN from './pages/LocationDetails/translation/en/LocationDetails.json';
import RegisterLocationEN from './pages/Locations/RegisterLocation/translation/en/RegisterLocation.json';
import AddressSearchEN from './components/AddressSearch/translation/en/AddressSearch.json';
import ProgressStepperEN from './components/ProgressStepper/translation/en/ProgressStepper.json';
import CloudEN from './pages/Cloud/translation/en/Cloud.json';
import PartitionDetailsEN from './pages/PartitionDetails/translation/en/PartitionDetails.json';
import TopologyTooltipGatewayEN from './components/Topology/Tooltips/Gateway/translation/en/Gateway.json';
import TopologyTooltipApplicationEN from './components/Topology/Tooltips/Application/translation/en/Application.json';
import TopologyTooltipServiceEN from './components/Topology/Tooltips/Service/translation/en/Service.json';
import TopologyTooltipDeploymentEnvironmentEn from './components/Topology/Tooltips/DeploymentEnvironment/translation/en/DeploymentEnvironment.json';
import TopologyTooltipLocationEN from './components/Topology/Tooltips/Location/translation/en/Location.json';
import TopologyTooltipPartitionEN from './components/Topology/Tooltips/Partition/translation/en/Partition.json';
import TopologyTooltipDisabledEN from './components/Topology/Tooltips/Disabled/translation/en/Disabled.json';
import CustomSidePanelEN from './components/CustomSidePanel/translation/en/CustomSidePanel.json';
import TopologySidepanelApplicationDetailsEN from './components/Topology/SidePanels/ApplicationDetails/translation/en/ApplicationDetails.json';
import TopologySidepanelApplicationServiceEN from './components/Topology/SidePanels/ApplicationService/translation/en/ApplicationService.json';
import TopologySidePanelLocationDetailsEN from './components/Topology/SidePanels/LocationDetails/translation/en/LocationDetails.json';
import TopologySidePanelGatewayDetailsEN from './components/Topology/SidePanels/GatewayDetails/translation/en/GatewayDetails.json';
import TopologySidepanelDeploymentEnvEN from './components/Topology/SidePanels/DeploymentEnvironments/translation/en/DeploymentEnvironments.json';
import TopologySidepanelServiceDetailsEN from './components/Topology/SidePanels/ServiceDetails/translation/en/ServiceDetails.json';
import TopologySidepanelGatewayConnectionEN from './components/Topology/SidePanels/GatewayConnection/translation/en/GatewayConnection.json';
import TopologySidepanelPartitionDetailsEN from './components/Topology/SidePanels/PartitionDetails/translation/en/PartitionDetails.json';
import CloudDetailsEN from './pages/CloudDetails/translation/en/CloudDetails.json';
import RegisterCloudEN from './pages/Cloud/translation/en/RegisterCloud.json';
import EditPartitionEN from './pages/EditPartition/translation/en/EditPartition.json';
import AutofillSearchEN from '../src/components/AutofillSearch/translation/en/AutofillSearch.json';
import AddApplicationDeploymentEN from './pages/ApplicationDetails/AddApplicationDeployment/translation/en/AddApplicationDeployment.json';
import AddApplicationDeploymentDetailsEN from './pages/ApplicationDetails/AddApplicationDeployment/AddApplicationDeploymentDetails/translation/en/AddApplicationDeploymentDetails.json';
import APIKeyManagerEN from './pages/APIKeyManager/translation/en/APIKeyManager.json';
import Error403PageEN from './pages/Errors/translation/en/Error403.json';
import Error404PageEN from './pages/Errors/translation/en/Error404.json';
import Error500PageEN from './pages/Errors/translation/en/Error500.json';
import MetricComponentEn from './components/CustomSidePanel/MetricComponent/translation/en/MetricComponent.json';
import GatewayDetailsEN from './pages/GatewayDetails/translation/en/GatewayDetailsPage.json';
import RemoteConnectionDetailsEN from './pages/GatewayDetails/Remoteconnection/translation/en/RemoteconnectionDetails.json';
import GatewaysContainerEN from './pages/Gateways/translation/en/GatewaysContainer.json';
import EventDetailsEN from './pages/EventsDetails/translation/en/EventDetails.json';
import TopologyCanvasViewEn from './components/Topology/TopologyCanvasView/translation/en/TopologyCanvasView.json';
import RegisterPartitionEN from './pages/DeploymentEnvDetails/RegisterPartiton/translation/en/RegisterPartition.json';
import EventsEN from './pages/Events/translation/en/Events.json';
import SecretManagerEn from './pages/SecretManager/translation/en/SecretManager.json';
import EditEventEn from './pages/EditEvent/translation/en/EditEvent.json';
import TopologySidepanelApplicationServiceConnectionEN from './components/Topology/SidePanels/ApplicationServiceConnection/translation/en/ApplicationServiceConnection.json';
import IdentityDetailsEN from './pages/IdentityDetails/translation/en/IdentityDetails.json';
import LogoutEn from './pages/Logout/translation/en/Logout.json';
import ConnectEdgeGatewayEN from './components/ConnectEdgeGateway/translation/en/ConnectEdgeGateway.json';
import AddRolesIdentityEN from './pages/IdentityDetails/AddRoles/translation/en/AddRoles.json';
import identityEn from './pages/Identities/translation/en/Identity.json';
import RolesEN from './pages/Roles/translation/en/Roles.json';
import CreateRoleEN from './pages/Roles/translation/en/CreateRole.json';
import RoleDetailsEN from './pages/RoleDetails/translation/en/RoleDetails.json';
import EditIdentityDetailsEn from './pages/IdentityDetails/EditIdentityDetails/translation/en/EditIdentityDetails.json';
import GatewayStatusEN from './components/GatewayStatus/translation/en/GatewayStatus.json';
import Error403CardEN from './components/ErrorState/translation/en/Error403Card.json';
import Error500CardEN from './components/ErrorState/translation/en/Error500Card.json';
import GenericStatusFieldEN from './components/GenericStatusField/translation/en/GenericStatusField.json';
import NetworkSegmentsEN from './pages/NetworkSegmentsContainer/translation/en/NetworkSegmentsContainer.json';
import CreateNetworkSegmentEN from './pages/NetworkSegmentsContainer/translation/en/CreateNetworkSegment.json';
import NetworkSegmentDetailsEN from './pages/NetworkSegmentDetails/translation/en/NetworkSegmentDetails.json';
import HeaderEN from './components/Header/translation/en/Header.json';
import GatewayInstanceDetailsEN from './pages/GatewayDetails/GatewayInstance/translation/en/GatewayInstanceDetails.json';
import RegisterRemoteConnectionEN from './pages/GatewayDetails/Remoteconnection/RegisterRemoteConnection/translation/en/RegisterRemoteConnection.json';
import EditGatewayEN from './pages/Gateways/EditGateway/translation/en/EditGateway.json';
import LegendContainerEN from './components/Topology/LegendsContainer/translation/en/LegendContainer.json';
import CustomChartEN from './components/CustomSidePanel/CustomCharts/translation/en/CustomCharts.json';
import SubscriptionUsageEN from './pages/SubscriptionUsage/translation/en/SubscriptionUsage.json';
import NamespaceRegisterEN from './pages/NamespaceRegister/translation/en/NamespaceRegister.json';
import DeployGatewayEn from './pages/DeployGateway/translation/en/deployGateway.json';
import ProceduralActivityEN from './components/ProceduralActivity/translation/en/ProceduralActivity.json';
import ConnectGatewayClusterEN from './components/ConnectGatewayCluster/translation/en/ConnectGatewayCluster.json';
import GatewayOnboardingStatusDetailsEN from './components/GatewayOnboardingStatus/translation/en/GatewayOnboardingStatusDetails.json';
import EditNetworkSegmentEN from './pages/NetworkSegmentDetails/EditNetworkSegment/translation/en/EditNetworkSegment.json';

import AddressSearchFR from './components/AddressSearch/translation/fr/AddressSearch.json';
import ResourceCardFR from './components/ApplicationCard/translation/fr/ResourceCard.json';
import AutofillSearchFR from './components/AutofillSearch/translation/fr/AutofillSearch.json';
import CardEmptystateFR from './components/CardEmptyState/translation/fr/CardEmptyState.json';
import MetricComponentFR from './components/CustomSidePanel/MetricComponent/translation/fr/MetricComponent.json';
import CustomSidePanelFR from './components/CustomSidePanel/translation/fr/CustomSidePanel.json';
import DetailsCardFR from './components/DetailsCard/translation/fr/DetailsCard.json';
import FindAndFilterBarFR from './components/FindAndFilterBar/translation/fr/FindAndFilterBar.json';
import GenericTableWithFiltersFR from './components/GenericTableWithFilters/translation/fr/GenericTableWithFilters.json';
import OverviewTileFR from './components/OverviewTile/translation/fr/OverviewTile.json';
import TopologySidePanelAppDetailsFR from './components/Topology/SidePanels/ApplicationDetails/translation/fr/ApplicationDetails.json';
import TopologySidePanelAppServiceFR from './components/Topology/SidePanels/ApplicationService/translation/fr/ApplicationService.json';
import TopologySidePanelDeplEnvFR from './components/Topology/SidePanels/DeploymentEnvironments/translation/fr/DeploymentEnvironments.json';
import TopologySidePanelGatewayConnectionFR from './components/Topology/SidePanels/GatewayConnection/translation/fr/GatewayConnection.json';
import TopologySidePanelGatewayDetailsFR from './components/Topology/SidePanels/GatewayDetails/translation/fr/GatewayDetails.json';
import TopologySidePanelLocationDetailsFR from './components/Topology/SidePanels/LocationDetails/translation/fr/LocationDetails.json';
import TopologySidePanelServiceDetailsFR from './components/Topology/SidePanels/ServiceDetails/translation/fr/ServiceDetails.json';
import TopologyTooltipApplicationFR from './components/Topology/Tooltips/Application/translation/fr/Application.json';
import TopologyTooltipDeplEnvFR from './components/Topology/Tooltips/DeploymentEnvironment/translation/fr/DeploymentEnvironment.json';
import TopologyTooltipGatewayFR from './components/Topology/Tooltips/Gateway/translation/fr/Gateway.json';
import TopologyTooltipLocationFR from './components/Topology/Tooltips/Location/translation/fr/Location.json';
import APIKeyManagerFR from './pages/APIKeyManager/translation/fr/APIKeyManager.json';
import AddApplicationDeploymentDetailsFR from './pages/ApplicationDetails/AddApplicationDeployment/AddApplicationDeploymentDetails/translation/fr/AddApplicationDeploymentDetails.json';
import AddApplicationDeploymentFR from './pages/ApplicationDetails/AddApplicationDeployment/translation/fr/AddApplicationDeployment.json';
import ApplicationDetailsFR from './pages/ApplicationDetails/translation/fr/ApplicationDetails.json';
import ApplicationsContainerFR from './pages/ApplicationsContainer/translation/fr/ApplicationsContainer.json';
import CloudFR from './pages/Cloud/translation/fr/Cloud.json';
import RegisterCloudFR from './pages/Cloud/translation/fr/RegisterCloud.json';
import CloudDetailsFR from './pages/CloudDetails/translation/fr/CloudDetails.json';
import CreatePolicyFR from './pages/ConnectionAccessPolicies/CreateConnectionAccessPolicy/translation/fr/CreatePolicy.json';
import CreatePolicyOptionsFR from './pages/ConnectionAccessPolicies/CreateConnectionAccessPolicy/PolicyOptions/translation/fr/PolicyOptions.json';
import ConnectionAccessPoliciesFR from './pages/ConnectionAccessPolicies/translation/fr/ConnectionAccessPolicies.json';
import ConnectionAccessPolicyDetailsFR from './pages/ConnectionAccessPolicies/translation/fr/ConnectionAccessPolicyDetails.json';
import DeploymentEnvDetailsFR from './pages/DeploymentEnvDetails/translation/fr/DeploymentEnvDetails.json';
import RegisterDeplEnvFR from './pages/DeploymentEnvsContainer/RegisterDeploymentEnv/translation/fr/RegisterDeploymentEnv.json';
import DeploymentEnvsContainerFR from './pages/DeploymentEnvsContainer/translation/fr/DeploymentEnvsContainer.json';
import EditPartitionFR from './pages/EditPartition/translation/fr/EditPartition.json';
import Error403FR from './pages/Errors/translation/fr/Error403.json';
import LocationDetailsFR from './pages/LocationDetails/translation/fr/LocationDetails.json';
import RegisterLocationFR from './pages/Locations/RegisterLocation/translation/fr/RegisterLocation.json';
import LocationFR from './pages/Locations/translation/fr/Locations.json';
import PartitionDetailsFR from './pages/PartitionDetails/translation/fr/PartitionDetails.json';
import ResourceGroupsContainerFR from './pages/ResourceGroups/translation/fr/ResourceGroupsContainer.json';
import TopologyPageFR from './pages/TopologyPage/translation/fr/TopologyPage.json';
import CallBackHandlerFR from './pages/translation/fr/CallBackHandler.json';
import LandingPageFR from './pages/translation/fr/LandingPage.json';
import LoginPageFR from './pages/translation/fr/LoginPage.json';
import NavSidePanelFR from './pages/translation/fr/NavSidePanel.json';
import AppFR from './translation/fr/App.json';
import TopologyCanvasViewFR from './components/Topology/TopologyCanvasView/translation/fr/TopologyCanvasView.json';
import RegisterPartitionFR from './pages/DeploymentEnvDetails/RegisterPartiton/translation/fr/RegisterPartition.json';
import EventsFR from './pages/Events/translation/fr/Events.json';
import EventDetailsFR from './pages/EventsDetails/translation/fr/EventDetails.json';
import RemoteConnectionDetailsFR from './pages/GatewayDetails/Remoteconnection/translation/fr/RemoteconnectionDetails.json';
import GatewayDetailsFR from './pages/GatewayDetails/translation/fr/GatewayDetailsPage.json';
import GatewaysContainerFR from './pages/Gateways/translation/fr/GatewaysContainer.json';
import SecretManagerFR from './pages/SecretManager/translation/fr/SecretManager.json';
import SortDropDownFR from './components/SortDropdown/Translation/fr/SortDropDown.json';
import TopologyTooltipPartitionFR from './components/Topology/Tooltips/Partition/translation/fr/Partition.json';
import TopologyTooltipDisabledFR from './components/Topology/Tooltips/Disabled/translation/fr/Disabled.json';
import EditEventFR from './pages/EditEvent/translation/fr/EditEvent.json';
import TopologySidepanelApplicationServiceConnectionFR from './components/Topology/SidePanels/ApplicationServiceConnection/translation/fr/ApplicationServiceConnection.json';
import RegisterExternalServiceEN from './pages/ApplicationsContainer/RegisterExternalService/translation/en/RegisterExternalService.json';

const resources = {
  en: {
    app: AppEN,
    landingPage: LandingPageEN,
    resourceCard: ResourceCardEN,
    // servicesTile: ServicesTileEN,
    detailsCard: DetailsCardEN,
    applicationsContainer: ApplicationsContainerEN,
    deploymentEnvsContainer: deploymentEnvsContainerEN,
    connectionAccessPolicies: ConnectionAccessPoliciesEN,
    deploymentEnvDetails: DeploymentEnvDetailsEN,
    connectionAccessPolicyDetails: ConnectionAccessPolicyDetailsEN,
    navSidePanel: NavSidePanelEN,
    overviewTile: OverviewTileEN,
    applicationDetails: ApplicationDetailsEN,
    createPolicy: CreatePolicyEN,
    createPolicyAllowConnections: CreatePolicyAllowConnectionsEN,
    createPolicyOptions: CreatePolicyOptionsEN,
    sortDropDown: SortDropDownEN,
    findAndFilterBar: FindAndFilterBarEN,
    genericTableWithFilters: GenericTableWithFiltersEN,
    resourceGroupsContainer: ResourceGroupsContainerEN,
    topologyPage: TopologyPageEN,
    loginPage: LoginPageEN,
    callBackHandler: CallBackHandlerEN,
    registerDeploymentEnv: RegisterDeplEnvEN,
    cardEmptyState: CardEmptyStateEN,
    location: LocationEN,
    locationDetails: LocationDetailsEN,
    autofillSearch: AutofillSearchEN,
    registerLocation: RegisterLocationEN,
    addressSearch: AddressSearchEN,
    progressStepper: ProgressStepperEN,
    partitionDetails: PartitionDetailsEN,
    topologyTooltipGateway: TopologyTooltipGatewayEN,
    topologyTooltipApplication: TopologyTooltipApplicationEN,
    topologyTooltipService: TopologyTooltipServiceEN,
    topologyTooltipDeploymentEnvironment:
      TopologyTooltipDeploymentEnvironmentEn,

    topologyTooltipLocation: TopologyTooltipLocationEN,
    topologyTooltipPartition: TopologyTooltipPartitionEN,
    topologyTooltipDisabled: TopologyTooltipDisabledEN,
    customSidePanel: CustomSidePanelEN,
    topologySidePanelAppDetails: TopologySidepanelApplicationDetailsEN,
    topologySidePanelAppService: TopologySidepanelApplicationServiceEN,
    topologySidePanelLocationDetails: TopologySidePanelLocationDetailsEN,
    topologySidePanelGatewayDetails: TopologySidePanelGatewayDetailsEN,
    topologySidePanelDeploymentEnv: TopologySidepanelDeploymentEnvEN,
    topologySidePanelServiceDetails: TopologySidepanelServiceDetailsEN,
    topologySidePanelGatewayConnection: TopologySidepanelGatewayConnectionEN,
    topologySidepanelPartitionDetails: TopologySidepanelPartitionDetailsEN,
    registerCloud: RegisterCloudEN,
    editPartition: EditPartitionEN,
    cloudsContainer: CloudEN,
    cloudDetails: CloudDetailsEN,
    addApplicationDeployment: AddApplicationDeploymentEN,
    addApplicationDeploymentDetails: AddApplicationDeploymentDetailsEN,
    apiKeyManager: APIKeyManagerEN,
    error403Page: Error403PageEN,
    error404Page: Error404PageEN,
    error500Page: Error500PageEN,
    metricComponent: MetricComponentEn,
    gatewayDetails: GatewayDetailsEN,
    remoteConnectionDetails: RemoteConnectionDetailsEN,
    gatewaysContainer: GatewaysContainerEN,
    eventDetails: EventDetailsEN,
    topologyCanvasView: TopologyCanvasViewEn,
    registerPartition: RegisterPartitionEN,
    events: EventsEN,
    secretManager: SecretManagerEn,
    editEvent: EditEventEn,
    topologySidepanelApplicationServiceConnection:
      TopologySidepanelApplicationServiceConnectionEN,
    logout: LogoutEn,

    connectEdgeGateway: ConnectEdgeGatewayEN,
    identityDetails: IdentityDetailsEN,
    addRolesIdentity: AddRolesIdentityEN,
    identity: identityEn,
    roles: RolesEN,
    createRole: CreateRoleEN,
    roleDetails: RoleDetailsEN,
    editIdentityDetails: EditIdentityDetailsEn,
    gatewayStatus: GatewayStatusEN,
    error403Card: Error403CardEN,
    error500Card: Error500CardEN,
    genericStatusField: GenericStatusFieldEN,
    networkSegments: NetworkSegmentsEN,
    createNetworkSegment: CreateNetworkSegmentEN,
    networkSegmentDetails: NetworkSegmentDetailsEN,
    header: HeaderEN,
    gatewayInstanceDetails: GatewayInstanceDetailsEN,
    registerRemoteConnection: RegisterRemoteConnectionEN,
    editGateway: EditGatewayEN,
    legendContainer: LegendContainerEN,
    customChart: CustomChartEN,
    subscriptionUsage: SubscriptionUsageEN,
    namespaceRegister: NamespaceRegisterEN,
    deployGateway: DeployGatewayEn,
    proceduralActivity: ProceduralActivityEN,
    connectGatewayCluster: ConnectGatewayClusterEN,
    gatewayOnboardingStatusDetails: GatewayOnboardingStatusDetailsEN,
    editNetworkSegment: EditNetworkSegmentEN,
    registerExternalService: RegisterExternalServiceEN,
  },
  fr: {
    app: AppFR,
    addApplicationDeployment: AddApplicationDeploymentFR,
    addApplicationDeploymentDetails: AddApplicationDeploymentDetailsFR,
    addressSearch: AddressSearchFR,
    apiKeyManager: APIKeyManagerFR,
    applicationsContainer: ApplicationsContainerFR,
    applicationDetails: ApplicationDetailsFR,
    autofillSearch: AutofillSearchFR,
    callBackHandler: CallBackHandlerFR,
    cardEmptyState: CardEmptystateFR,
    cloudsContainer: CloudFR,
    cloudDetails: CloudDetailsFR,
    connectionAccessPolicies: ConnectionAccessPoliciesFR,
    connectionAccessPolicyDetails: ConnectionAccessPolicyDetailsFR,
    createPolicy: CreatePolicyFR,
    createPolicyOptions: CreatePolicyOptionsFR,
    customSidePanel: CustomSidePanelFR,
    deploymentEnvsContainer: DeploymentEnvsContainerFR,
    deploymentEnvDetails: DeploymentEnvDetailsFR,
    detailsCard: DetailsCardFR,
    editPartition: EditPartitionFR,
    error403Page: Error403FR,
    events: EventsFR,
    eventDetails: EventDetailsFR,
    editEvent: EditEventFR,
    findAndFilterBar: FindAndFilterBarFR,
    gatewayDetails: GatewayDetailsFR,
    gatewaysContainer: GatewaysContainerFR,
    genericTableWithFilters: GenericTableWithFiltersFR,
    landingPage: LandingPageFR,
    location: LocationFR,
    locationDetails: LocationDetailsFR,
    loginPage: LoginPageFR,
    metricComponent: MetricComponentFR,
    navSidePanel: NavSidePanelFR,
    overviewTile: OverviewTileFR,
    partitionDetails: PartitionDetailsFR,
    registerCloud: RegisterCloudFR,
    registerDeploymentEnv: RegisterDeplEnvFR,
    registerLocation: RegisterLocationFR,
    registerPartition: RegisterPartitionFR,
    remoteConnectionDetails: RemoteConnectionDetailsFR,
    resourceCard: ResourceCardFR,
    resourceGroupsContainer: ResourceGroupsContainerFR,
    secretManager: SecretManagerFR,
    topologyCanvasView: TopologyCanvasViewFR,
    topologyPage: TopologyPageFR,
    topologySidePanelAppDetails: TopologySidePanelAppDetailsFR,
    topologySidePanelAppService: TopologySidePanelAppServiceFR,
    topologySidePanelDeploymentEnv: TopologySidePanelDeplEnvFR,
    topologySidePanelLocationDetails: TopologySidePanelLocationDetailsFR,
    topologySidePanelGatewayDetails: TopologySidePanelGatewayDetailsFR,
    topologySidePanelGatewayConnection: TopologySidePanelGatewayConnectionFR,
    topologySidePanelServiceDetails: TopologySidePanelServiceDetailsFR,
    topologyTooltipApplication: TopologyTooltipApplicationFR,
    topologyTooltipDeploymentEnvironment: TopologyTooltipDeplEnvFR,
    topologyTooltipGateway: TopologyTooltipGatewayFR,
    topologyTooltipLocation: TopologyTooltipLocationFR,
    sortDropDown: SortDropDownFR,
    topologyTooltipPartition: TopologyTooltipPartitionFR,
    topologyTooltipDisabled: TopologyTooltipDisabledFR,
    topologySidepanelApplicationServiceConnection:
      TopologySidepanelApplicationServiceConnectionFR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources,
    debug: process.env.NODE_ENV === 'development',
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
