import {
  DataTable,
  DataTableSkeleton,
  InlineNotificationProps,
  Modal,
  OverflowMenu,
  OverflowMenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableExpandedRow,
  TableHead,
  TableHeader,
  TableRow,
  TooltipDefinition,
} from 'carbon-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dateUtils from '../../../lib/dates';

import { AxiosError } from 'axios';
import { CardEmptyState } from '../../../components/CardEmptyState/CardEmptyState';
import GenericStatusField from '../../../components/GenericStatusField/GenericStatusField';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';
import Notification from '../../../components/Notifications/Inline/Notification';
import { getResourceGroups } from '../../../controllers/resourceGroupApi';
import { deleteSecret } from '../../../controllers/tenantApis';
import images from '../../../images/images';
import { Secret, SecretManagerConfig } from '../../../models/master';
import RegisterSecret from '../RegisterSecret/RegisterSecret';
import { typeList } from '../constants';
import './SecretsTable.scss';
import useAnalytics from '../../../lib/useAnalytics';
import analyticsData from '../../../lib/analyticsEventData';
import Error500Card from '../../../components/ErrorState/Error500Card';

interface Props {
  setRegisterTearsheetOpen: (data: any) => void;
  secretsDetailsChanged: boolean;
  rowsData: Secret[] | null | any;
  refreshData: () => void;
  secretManagerConfig: SecretManagerConfig | null;
  error500Flag: boolean;
}

export const secretStorageTypes = ['external', 'internal'];
const defaultPermissionMap = {
  resourceGroup: true,
  secrets: true,
};

const SecretsTable = (props: Props) => {
  const { t } = useTranslation('secretManager');
  const {
    setRegisterTearsheetOpen,
    secretsDetailsChanged,
    rowsData,
    refreshData,
    secretManagerConfig,
  } = props;
  const [formattedRowData, setFormattedRowData] = useState<any>([]);
  const [resourceGroupList, setResourceGroupList] = useState<any>();
  const [secretDataLoading, toggleSecretDataLoading] = useState(false);

  const [EditSecretTearSheetOpen, setEditSecretTearSheetOpen] =
    useState<boolean>(false);

  const [editSecretData, setEditSecretData] = useState({});
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [dataToDelete, setDataToDelete] = useState<Secret | null>(null);
  const [notificationData, setNotificationData] =
    useState<InlineNotificationProps>({} as InlineNotificationProps);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);

  const notification = useContext(NotificationContext);
  const { trackButtonClicked } = useAnalytics();

  useEffect(() => {}, [secretsDetailsChanged, EditSecretTearSheetOpen]);

  useEffect(() => {
    formatSecretsData(rowsData);
  }, [rowsData]);

  const formatSecretsData = async (data: any) => {
    const secData: any = [];
    let resourceGroups: { resource_groups: any[] };
    let currentPermissionMap = permissionMap;
    try {
      toggleSecretDataLoading(true);
      resourceGroups = await getResourceGroups(true);
      setResourceGroupList(resourceGroups?.resource_groups);
      if (!currentPermissionMap.resourceGroup) {
        currentPermissionMap = {
          ...currentPermissionMap,
          resourceGroup: true,
        };
        setPermissionMap(currentPermissionMap);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (
        err?.response?.status === 403 &&
        err?.response?.statusText === 'Forbidden'
      ) {
        currentPermissionMap = {
          ...currentPermissionMap,
          resourceGroup: false,
        };
        setPermissionMap(currentPermissionMap);
      }
    }
    if (data != null && data?.length > 0) {
      data?.map(
        (item: {
          name: any;
          resource_group_id: any;
          backend: any;
          secrets_manager_type?: any;
          type: any;
          updated_at: string;
          auth: any;
          path: any;
          created_at: string;
        }) => {
          let obj = {
            id: item?.name,
            name: item?.name,
            infrastructureGroup: !currentPermissionMap['resourceGroup']
              ? 'notAuthorised'
              : resourceGroups?.resource_groups?.find(
                  (d: { resource_id: any }) =>
                    d?.resource_id === item?.resource_group_id
                )?.name,
            secretsManagerType: item?.backend
              ? '—'
              : item?.secrets_manager_type
              ? t(item?.secrets_manager_type)
              : '',
            secretsType:
              typeList.find(type => type.value === item?.type)?.label ?? '',

            lastUpdated: dateUtils.getUserFriendlyDate(item?.updated_at)
              ? dateUtils.getUserFriendlyDate(item?.updated_at)
              : '—',
            apiKey: /*item?.auth?.api_key*/ '****************************',
            secretId: item?.path,
            created: dateUtils.getUserFriendlyDate(item?.created_at)
              ? dateUtils.getUserFriendlyDate(item?.created_at)
              : '—',
          };

          secData.push(obj);
          setFormattedRowData([...secData]);
          toggleSecretDataLoading(false);
        }
      );
    } else {
      setFormattedRowData([]);
    }
  };

  const headers = [
    {
      header: t('tableHeaders.name'),
      key: 'name',
    },
    {
      header: t('tableHeaders.infrastructureGroup'),
      key: 'infrastructureGroup',
    },
    {
      header: t('tableHeaders.secretsManagerType'),
      key: 'secretsManagerType',
    },
    {
      header: t('tableHeaders.secretsType'),
      key: 'secretsType',
    },
    {
      header: t('tableHeaders.lastUpdated'),
      key: 'lastUpdated',
    },
  ];

  function getExpandedRow(row: any, header: any) {
    let selectedSecretData;
    if (row.isExpanded) {
      selectedSecretData = formattedRowData.filter(
        (d: { id: any }) => d.id === row.id
      );
    }

    return (
      row.isExpanded && (
        <TableExpandedRow colSpan={header.length + 2}>
          <div className='details-exp'>
            <div className='details-header'>{t('details')}</div>
            <div className='details-body'>
              <div className='details-table'>
                <div className='first-column'>
                  <div className='first-row'>{t('secretDetails.apiKey')}</div>
                  <div className='second-row'>
                    {t('secretDetails.secretPath')}
                  </div>
                  <div className='third-row'>{t('secretDetails.created')}</div>
                </div>
                <div className='second-column'>
                  <div className='first-row-key'>
                    {selectedSecretData[0]?.apiKey}
                  </div>
                  <div className='second-row-secret'>
                    {selectedSecretData[0]?.secretId}
                  </div>
                  <div className='third-row'>
                    {selectedSecretData[0]?.created}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TableExpandedRow>
      )
    );
  }

  const closeEditSecretTearsheet = () => {
    setEditSecretTearSheetOpen(false);
  };

  const onSecretEdit = () => {
    trackButtonClicked(
      analyticsData['Admin Secrets'].events.editSecret.props,
      analyticsData['Admin Secrets'].events.editSecret.event
    );
    setEditSecretTearSheetOpen(false);
    refreshData();
  };

  const editBtnClick = async (secretDataToEdit: any) => {
    setEditSecretData(secretDataToEdit);
    setEditSecretTearSheetOpen(true);
  };

  const openDeleteModal = (secret: any) => {
    trackButtonClicked(
      analyticsData['Admin Secrets'].events.deleteSecret.props,
      analyticsData['Admin Secrets'].events.deleteSecret.event
    );
    setDataToDelete(secret);
    setShowDeletePopup(true);
  };

  const deleteSecretFn = async () => {
    setShowDeletePopup(false);
    try {
      setDisableButton(true);
      const secretToBeDeleted = dataToDelete ? dataToDelete?.name : '';
      setDataToDelete(null);
      // toggleAppDataLoading(true);
      await deleteSecret(secretToBeDeleted);
      // Trigger success toastbar
      notification.onTrigger('TOAST', {
        title: t('deleteSecretNotificationMessages.successNotificationTitle'),
        subtitle: t(
          'deleteSecretNotificationMessages.removeNotificationMessage',
          {
            deleteSecretName: secretToBeDeleted,
          }
        ),
      });
      refreshData();
    } catch (error) {
      console.error(error);
      setNotificationData({
        kind: 'error',
        title: `${t(
          'deleteSecretNotificationMessages.errorNotificationTitle'
        )}`,
        subtitle: t('deleteSecretNotificationMessages.removeApplicationFail'),
      });
      setOpenErrorModal(true);
    } finally {
      setDisableButton(false);
      // toggleAppDataLoading(false);
    }
  };

  const onNotificationClose = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): any => {
    setOpenErrorModal(false);
  };

  const render500Container = () => {
    return (
      <div className='emptyContainer'>
        <div className='emptyContainerCol'>
          <Error500Card />
        </div>
      </div>
    );
  };

  return (
    <div className='secret-table-container'>
      {openErrorModal ? (
        <Notification
          kind={notificationData?.kind}
          subtitle={notificationData?.subtitle}
          title={notificationData?.title}
          onClose={onNotificationClose}
        ></Notification>
      ) : null}
      {props.error500Flag ? (
        render500Container()
      ) : Array.isArray(rowsData) && rowsData?.length <= 0 ? (
        <CardEmptyState
          filterApplied={[]}
          emptyState={{
            icon: images.NoSecretsIcon(),
            header: t('emptyState.emptyContainerHeader'),
            description: t('emptyState.emptyContainerDescription'),
            buttonText: t('emptyState.buttonText'),
            link: false,
          }}
        />
      ) : (
        <>
          {secretDataLoading ? (
            <div>
              <div className='skelton-header' />
              <DataTableSkeleton
                showHeader={false}
                showToolbar={false}
                rowCount={3}
                columnCount={6}
              />
            </div>
          ) : (
            <DataTable
              rows={formattedRowData}
              headers={headers}
              render={({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
              }) => (
                <TableContainer title=' '>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map(header => (
                          <TableHeader
                            {...getHeaderProps({ header })}
                            className='table-header'
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader className='table-header' />
                      </TableRow>
                    </TableHead>
                    <TableBody className='tbody'>
                      {rows.map((row, index) => (
                        <React.Fragment key={row.id}>
                          <TableRow {...getRowProps({ row })}>
                            {row.cells.map(cell => (
                              <TableCell key={cell.id}>
                                {!!cell.value ? (
                                  cell.value === 'notAuthorised' ? (
                                    <GenericStatusField status={cell.value} />
                                  ) : (
                                    cell.value
                                  )
                                ) : (
                                  '—'
                                )}
                              </TableCell>
                            ))}
                            {
                              <TableCell
                                className={`overflow-menu secret-actions ${
                                  !!rowsData[index]?.backend
                                    ? 'hide'
                                    : 'visible'
                                }`}
                              >
                                <OverflowMenu
                                  className='oveflowMenu'
                                  direction='bottom'
                                  flipped={true}
                                >
                                  {secretManagerConfig?.sm_type_strategy !=
                                    null &&
                                  secretManagerConfig?.sm_type_strategy !==
                                    '' &&
                                  secretManagerConfig?.sm_type_strategy !==
                                    rowsData[index]?.secrets_manager_type ? (
                                    <TooltipDefinition
                                      tooltipText={
                                        `${
                                          secretManagerConfig?.sm_type_strategy ===
                                          'internal'
                                            ? t(
                                                'disableEdit.interalStrategyisSet'
                                              )
                                            : t(
                                                'disableEdit.externalStrategyisSet'
                                              )
                                        }` as string
                                      }
                                      align='center'
                                      direction='top'
                                    >
                                      <OverflowMenuItem
                                        className='oveflowItem disabled'
                                        itemText={t('overflowMenu.edit')}
                                        onClick={() =>
                                          editBtnClick(rowsData[index])
                                        }
                                      />
                                    </TooltipDefinition>
                                  ) : (
                                    <OverflowMenuItem
                                      className='oveflowItem'
                                      itemText={t('overflowMenu.edit')}
                                      onClick={() =>
                                        editBtnClick(rowsData[index])
                                      }
                                    />
                                  )}
                                  <OverflowMenuItem
                                    className='oveflowItem'
                                    itemText={t('overflowMenu.delete')}
                                    onClick={() =>
                                      openDeleteModal(rowsData[index])
                                    }
                                  />
                                </OverflowMenu>
                              </TableCell>
                            }
                          </TableRow>
                          {/* {getExpandedRow(row, headers)} */}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
          )}
        </>
      )}
      {
        <Modal
          className='delete-secret-modal'
          danger
          modalHeading={t(
            'deleteSecretNotificationMessages.deleteModal.header'
          )}
          onRequestClose={() => setShowDeletePopup(false)}
          onRequestSubmit={() => dataToDelete && deleteSecretFn()}
          primaryButtonText={t(
            'deleteSecretNotificationMessages.deleteModal.confirm'
          )}
          secondaryButtonText={t(
            'deleteSecretNotificationMessages.deleteModal.cancel'
          )}
          open={showDeletePopup}
          size='sm'
          primaryButtonDisabled={disableButton}
        >
          {t('deleteSecretNotificationMessages.deleteModal.body', {
            secretName: dataToDelete?.name,
          })}
        </Modal>
      }
      {EditSecretTearSheetOpen && (
        <RegisterSecret
          open={EditSecretTearSheetOpen}
          mode='EDIT'
          onClose={() => closeEditSecretTearsheet()}
          onSecretCreate={() => onSecretEdit()}
          secretData={editSecretData}
          secrets={rowsData}
        />
      )}
    </div>
  );
};

export default SecretsTable;
