import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import useAnalytics from '../../../lib/useAnalytics';
import { removeExtraSpace } from '../../../lib/utils';
import analyticsData from '../../../lib/analyticsEventData';
import {
  ConnectionSelectedTypes,
  PartitionTypes,
  PolicyDataType,
} from '../../../lib/enums';
import {
  ConnectionOptionTypes,
  ConnectionSelectedType,
  Item,
  NetworkSegmentData,
  PolicyOptionFromTypes,
} from './config';
import {
  ApplicationData,
  NetworkSegment,
  PartitionData,
  PolicyData,
  ResourceGroup,
} from '../../../models/master';
import { addPolicy } from '../../../controllers/policyApi';
import { getNetworkSegments } from '../../../controllers/networksegmentsApi';
import { FormDataStep1, ServiceData } from './config';
import { getDeploymentEnvsPartitions } from '../../../controllers/deploymentEnv';
import { getApplications } from '../../../controllers/application';

import MultiStepTearSheet from '../../../components/MultiStepTearSheet/MultiStepTearSheet';
import InlineNotification from '../../../components/Notifications/Inline/Notification';
import { NotificationContext } from '../../../components/Notifications/Context/NotificationProvider';

import PolicyDefineDetails from '../CreateConnectionAccessPolicy/PolicyDefineDetails/PolicyDefineDetails';
import PolicyAllowConnections from './PolicyAllowConnections/PolicyAllowConnections';
import PolicyOptions from './PolicyOptions/PolicyOptions';

import './CreatePolicy.scss';

export interface Props {
  open: boolean;
  onClose: () => void;
  onAdd: () => void;
  resourceGroups: ResourceGroup[] | null;
  policyList: PolicyData[] | [] | null;
}

const CreatePolicy: React.FC<Props> = ({
  open,
  onClose,
  onAdd,
  resourceGroups,
  policyList,
}) => {
  const defaultPermissionMap = {
    application: true,
    service: true,
    resourceGroup: true,
    namespace: true,
    networkSegment: true,
  };
  const { t } = useTranslation('createPolicy');
  const { trackButtonClicked } = useAnalytics();
  const notification = useContext(NotificationContext);
  const [permissionMap, setPermissionMap] = useState(defaultPermissionMap);
  const defaultFormData = {
    name: {
      value: '',
      error: false,
      errorMessage: '',
    },
    description: {
      value: '',
      error: false,
      errorMessage: '',
    },
    resourceGroup: {
      value: {
        resource_id: 'default-app',
        name: 'Default_Application_Group',
      },
      error: false,
      errorMessage: '',
    },
    labels: {
      value: [],
      error: false,
      errorMessage: '',
    },
  };

  const [formData, setFormData] = useState<FormDataStep1>(defaultFormData);
  const [allPartitions, setAllPartitions] = useState<Item[] | null>(null);
  const [services, setServices] = useState<Item[] | null>(null);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [subTitleErrorMsg, setSubTitleErrorMsg] = useState('');
  const [authError, setAuthError] = useState(false);
  const [availableFromItems, setAvailableFromItems] = useState<Item[]>([]);
  const [selectedFromItems, setSelectedFromItems] = useState<Item[]>([]);
  const [availableToItems, setAvailableToItems] = useState<Item[]>([]);
  const [selectedToItems, setSelectedToItems] = useState<Item[]>([]);
  const [directionSelected, setDirectionSelected] =
    useState<ConnectionSelectedType>(ConnectionSelectedTypes.FROM);
  const [viewAllOptions, setViewAllOptions] = useState(false);
  const [isNwSegmentListLoading, setNwSegmentListLoading] = useState(false);
  const [policyOptionFromType, setPolicyOptionFromType] =
    useState<PolicyOptionFromTypes>(PolicyDataType.NAMESPACE);
  const [selectedNetworkSegment, setSelectedNetworkSegment] =
    useState<NetworkSegment | null>(null);
  const [networkSegmentList, setNetworkSegmentList] = useState<
    NetworkSegment[] | null
  >(null);

  const handleRefreshData = (type: ConnectionOptionTypes) => {
    return type === PolicyDataType.NAMESPACE
      ? refreshNamespaces()
      : type === PolicyDataType.SERVICE
      ? refreshServices()
      : [];
  };

  const refreshServices = () => {
    setServices(null);
    return fetchApplications();
  };

  const refreshNamespaces = () => {
    setAllPartitions(null);
    return fetchDeploymentEnvsPartitions();
  };

  const checkFieldValid = (name: string, value: any, networkSegment?: any) => {
    let errorMessage = '';

    switch (name) {
      case 'name':
        const valueEmpty =
          value === '' || value === null || value === undefined ? true : false;
        const filteredPolicyList =
          policyList?.filter(
            (policy: PolicyData) =>
              policy?.network_segment_id ===
                selectedNetworkSegment?.resource_id && policy.name === value
          ) ?? [];
        const notUnique = filteredPolicyList?.length > 0 ? true : false;
        errorMessage = valueEmpty
          ? t('policyDefineDetails.nameError')
          : notUnique
          ? t('policyDefineDetails.nameExists')
          : '';
        break;
      case 'resourceGroup':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('policyDefineDetails.resourceGroupError');
        break;
      case 'networkSegment':
        if (value === '' || value === null || value === undefined)
          errorMessage = t('policyDefineDetails.networkSegmentError');
        break;
    }

    return errorMessage;
  };

  const handleOnChange = (e: { target: { name: string; value: any } }) => {
    if (showErrorSnackbar) {
      setShowErrorSnackbar(false);
      setAuthError(false);
    }
    const name = e.target.name;
    const value = e?.target?.value;
    let errorMessage = checkFieldValid(name, value);
    if (name === 'networkSegment') {
      if (value === null) {
        setSelectedNetworkSegment(null);
        setAvailableFromItems([]);
        setSelectedFromItems([]);
        setAvailableToItems([]);
        setSelectedToItems([]);
      } else if (value.service_sync === true) {
        setSelectedFromItems([
          {
            id: e?.target?.value?.resource_id ?? '',
            label: e?.target?.value?.name ?? '',
            type: PolicyDataType.NETWORKSEGMENT,
          },
        ]);
        setPolicyOptionFromType(PolicyDataType.NETWORKSEGMENT);
        setSelectedNetworkSegment(value);
        setAvailableToItems(
          services
            ? services.filter(svc => svc.networkSegmentId === value.resource_id)
            : []
        );
        setSelectedToItems([]);
      } else if (value?.resource_id !== selectedNetworkSegment?.resource_id) {
        let filteredPartitions: Item[] =
          allPartitions?.filter(
            (partition: Item) =>
              partition.networkSegmentId === e?.target?.value?.resource_id
          ) ?? [];
        setAvailableFromItems(filteredPartitions);
        setSelectedFromItems([]);
        setAvailableToItems(
          services
            ? services?.filter(
                svc => svc.networkSegmentId === e.target.value.resource_id
              )
            : []
        );
        setSelectedToItems([]);
        setSelectedNetworkSegment(value);
      }
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: {
          value,
          error: !!errorMessage,
          errorMessage,
        },
      }));
    }
  };

  const checkFormValid = (step?: number) => {
    let isFormValid = false;
    if (step === 1) {
      let nameError = checkFieldValid('name', formData.name.value);
      let resourceGroupErr = checkFieldValid(
        'resourceGroup',
        formData.resourceGroup.value
      );
      if (!nameError.length && !resourceGroupErr) {
        isFormValid = true;
      }
    } else {
      if (
        selectedFromItems.length > 0 &&
        selectedToItems.length > 0 &&
        selectedNetworkSegment
      ) {
        isFormValid = true;
      }
    }

    return isFormValid;
  };

  const renderErrorSnackbar = () => (
    <div className='connection-access-policy-error-snackbar'>
      <InlineNotification
        kind='error'
        title={
          authError
            ? (t('error.authTitle') as string)
            : subTitleErrorMsg.length > 0
            ? t('error.genericTitle')
            : (t('error.title') as string)
        }
        subtitle={
          authError
            ? t('error.authSubtitle')
            : subTitleErrorMsg.length > 0
            ? subTitleErrorMsg
            : t('error.subtitle')
        }
        onClose={() => handleErrorBarClose() as any}
      />
    </div>
  );

  const handleErrorBarClose = () => {
    setShowErrorSnackbar(false) as any;
    setAuthError(false);
  };

  const onRemoveOptions = (data: Item, type: ConnectionSelectedType) => {
    if (type === ConnectionSelectedTypes.FROM) {
      setSelectedFromItems(prevItems =>
        prevItems.filter((item: Item) => item.id !== data.id)
      );
      setPolicyOptionFromType(PolicyDataType.NAMESPACE);
    } else {
      setSelectedToItems(items => items.filter(item => item.id !== data.id));
    }
  };

  const onAddOptions = (data: any, type: ConnectionOptionTypes) => {
    if (directionSelected === ConnectionSelectedTypes.FROM) {
      setSelectedFromItems(items => [...items, data]);
      setPolicyOptionFromType(type as PolicyOptionFromTypes);
    } else {
      setSelectedToItems(items => [...items, data]);
    }
  };

  const handleSelect = (type: ConnectionSelectedType) => {
    setDirectionSelected(type);
  };

  const fetchApplications = async () => {
    try {
      // We don't need to fetch the app deployment
      const response: ApplicationData[] = await getApplications(false, true);
      setPermissionMap({
        ...permissionMap,
        application: true,
        service: response?.[0]?.servicePermission ?? true,
      });
      const services = [];
      for (const application of response) {
        const applicationServices = application.services.map(srv => ({
          id: srv.resource_id,
          name: srv.name,
          label: srv.name,
          ports: srv.ports ?? [],
          protocol: srv.ports[0]?.protocol ?? '',
          parentName: application.name,
          parentId: application.resource_id,
          networkSegmentId: application?.network_segment_id,
          type: PolicyDataType.SERVICE,
          labels: srv.labels ?? [],
        }));
        services.push(...applicationServices);
      }
      setServices(services);
      return {
        applications: response,
        services,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        setPermissionMap(prevMap => ({
          ...prevMap,
          application: false,
          service: false,
        }));
      }
      console.error(error);
      return [];
    }
  };

  const fetchDeploymentEnvsPartitions = async () => {
    let currentPermissionMap = permissionMap;
    try {
      const envsData = await getDeploymentEnvsPartitions();
      if (!currentPermissionMap.namespace) {
        currentPermissionMap = {
          ...currentPermissionMap,
          namespace: true,
        };
        setPermissionMap(currentPermissionMap);
      }
      let partitions = [];
      for (const deplEnv of envsData) {
        const envPartitions = deplEnv.partitions?.map(
          (partition: PartitionData) => ({
            name: `${deplEnv.name}/${partition.name}`,
            id: partition.resource_id,
            parentId: deplEnv.resource_id,
            parentName: deplEnv.name,
            label: partition.name,
            type: PolicyDataType.NAMESPACE,
            cloudName: deplEnv.cloud_name,
            cloudId: deplEnv.cloud_id,
            locationName: deplEnv.location_name,
            resourceGroupName:
              resourceGroups?.find(
                (rg: ResourceGroup) =>
                  rg.resource_id === deplEnv.resource_group_id
              )?.name ?? '',
            resourceGroupId: deplEnv.resource_group_id,
            networkSegmentId: partition.network_segment_id,
          })
        );
        partitions.push(...envPartitions);
      }

      setAllPartitions(partitions as Item[]);
      return partitions;
    } catch (error) {
      const err = error as AxiosError;
      if (err?.response?.status === 403) {
        currentPermissionMap = {
          ...currentPermissionMap,
          namespace: false,
        };
        setPermissionMap(currentPermissionMap);
      }
      console.error(error);
      return [];
    }
  };

  const fetchLists = async () => {
    await fetchNwSegmentsList();
    await fetchDeploymentEnvsPartitions();
    await fetchApplications();
  };

  useEffect(() => {
    if (open) fetchLists();
  }, [open]);

  const fetchNwSegmentsList = async () => {
    try {
      setNwSegmentListLoading(true);
      const response = await getNetworkSegments();
      response.forEach((v: any) => {
        return v;
      });

      setNetworkSegmentList(response);
    } catch (error) {
      console.error(error);
      const err = error as AxiosError;

      if (err.response?.status === 403) {
        console.error(error);
      }
    } finally {
      setNwSegmentListLoading(false);
    }
  };

  const handlePolicySubmit = async () => {
    trackButtonClicked(
      analyticsData['Policies'].events.createPolicy.props,
      analyticsData['Policies'].events.createPolicy.event
    );

    try {
      // Clear error snackbar if present
      if (showErrorSnackbar) {
        setShowErrorSnackbar(false);
        setAuthError(false);
      }
      const value = formData.resourceGroup?.value;
      const type = policyOptionFromType;
      let modifiedFromData = {};
      if (type === PolicyDataType.NAMESPACE) {
        modifiedFromData = selectedFromItems?.map(item => {
          if (item.id) {
            return { namespace_id: item.id };
          }
        });
      } else if (type === PolicyDataType.NETWORKSEGMENT) {
        modifiedFromData = {
          network_segment: {
            network_segment_id: selectedNetworkSegment?.resource_id,
          },
          type: PolicyDataType.NETWORKSEGMENT,
        };
      }
      const policyDetails = {
        name: removeExtraSpace(formData?.name?.value),
        description: formData?.description?.value,
        // Note : Remove Resource Group for Experimental release.
        resource_group_id: value?.resource_id,
        resourceGroup: formData.resourceGroup.value,
        labels: formData?.labels?.value,

        network_segment_id: selectedNetworkSegment?.resource_id ?? '',
        from:
          type === PolicyDataType.NAMESPACE
            ? {
                namespaces: modifiedFromData,
                type: PolicyDataType.NAMESPACE,
              }
            : type === PolicyDataType.NETWORKSEGMENT
            ? modifiedFromData
            : {},
        to: {
          service: {
            service_id: selectedToItems[0]?.id,
          },
          type: PolicyDataType.SERVICE,
        },
        action: 'ALLOW',
      };

      const policy = await addPolicy(policyDetails);
      // Refreshes data on successful policy addition
      closeTearsheet();
      onAdd();

      // Display Toast notification on successful policy creation
      notification.onTrigger('TOAST', {
        title: t('toast.title'),
        subtitle: t('toast.subtitle', { name: policy.name }),
      });
    } catch (error: any) {
      const err = error as AxiosError;
      const errorMessage: string =
        error.response !== undefined
          ? error.response['customErrorMessage']
          : '';
      // TODO: We need to refactor error handling mechanism given below.
      // Right now only handling duplicate policy name assuming that all 422 errors are caused by it,
      // since we are not getting any proper unique error key.
      if (err.response?.status === 422) {
        setAuthError(false);
        setShowErrorSnackbar(true);
        errorMessage.length > 0 && setSubTitleErrorMsg(errorMessage);
        return Promise.reject(() => console.log(error));
      }

      if (err.response?.status === 403) {
        setAuthError(true);
        setShowErrorSnackbar(true);
        return Promise.reject(() => console.log(error));
      }

      errorMessage.length > 0
        ? setSubTitleErrorMsg(errorMessage)
        : setSubTitleErrorMsg(t('error.genericSubTitle'));

      setShowErrorSnackbar(true);
      setAuthError(false);
      return Promise.reject(() => console.log(error));
    }
  };

  const onCloseViewOptions = (saveOption: boolean = false) => {
    if (saveOption) {
      if (directionSelected === ConnectionSelectedTypes.FROM) {
        setDirectionSelected(ConnectionSelectedTypes.TO);
      }
    } else {
      if (directionSelected === ConnectionSelectedTypes.FROM) {
        setPolicyOptionFromType(PolicyDataType.NAMESPACE);
        setSelectedFromItems([]);
      } else {
        setSelectedToItems([]);
      }
    }

    setViewAllOptions(false);
  };

  const openViewOptions = (type: ConnectionSelectedTypes) => {
    if (type === ConnectionSelectedTypes.FROM) {
      if (
        policyOptionFromType === PolicyDataType.NAMESPACE &&
        selectedFromItems.length === 0
      ) {
        setAvailableFromItems(
          allPartitions?.filter(
            (partition: Item) =>
              partition.networkSegmentId === selectedNetworkSegment?.resource_id
          ) ?? []
        );
      }
    }
    setViewAllOptions(true);
  };

  const handleCheckbox = (value: boolean, data: any) => {
    if (directionSelected === ConnectionSelectedTypes.FROM) {
      value
        ? setSelectedFromItems(items => [...items, data])
        : setSelectedFromItems(items =>
            items.filter(item => item.id !== data.id)
          );
    } else {
      value
        ? setSelectedToItems([...[], data])
        : setSelectedToItems(items =>
            items.filter(item => item.id !== data.id)
          );
    }
  };

  const closeTearsheet = () => {
    setDirectionSelected(ConnectionSelectedTypes.FROM);
    setSelectedNetworkSegment(null);
    setSelectedFromItems([]);
    setAvailableFromItems([]);
    setSelectedToItems([]);
    setAvailableToItems([]);
    setFormData(defaultFormData);
    setPolicyOptionFromType(PolicyDataType.NAMESPACE);
    handleErrorBarClose();
    onClose();
  };

  const onRadioButtonChange = (type: PolicyOptionFromTypes) => {
    if (type === PolicyDataType.NAMESPACE && selectedNetworkSegment) {
      setSelectedFromItems([]);
      const filteredPartitions = allPartitions?.filter(
        (partition: Item) =>
          partition?.networkSegmentId === selectedNetworkSegment?.resource_id
      );
      setAvailableFromItems(filteredPartitions ? filteredPartitions : []);
    } else if (
      type === PolicyDataType.NETWORKSEGMENT &&
      selectedNetworkSegment
    ) {
      setSelectedFromItems([
        {
          id: selectedNetworkSegment.resource_id,
          label: selectedNetworkSegment.name,
          type: PolicyDataType.NETWORKSEGMENT,
        },
      ]);
      setAvailableFromItems([
        {
          id: selectedNetworkSegment.resource_id,
          label: selectedNetworkSegment.name,
          type: PolicyDataType.NETWORKSEGMENT,
        },
      ]);
    }
    setPolicyOptionFromType(type);
  };

  return (
    <>
      <MultiStepTearSheet
        open={open}
        onClose={closeTearsheet}
        onRequestSubmit={handlePolicySubmit}
        submitButtonText={t('submitButtonText')}
        cancelButtonText={t('cancelButtonText')}
        backButtonText={t('backButtonText')}
        nextButtonText={t('nextButtonText')}
        description={t('description')}
        title={t('title')}
        className='create-policy-tearsheet'
      >
        <PolicyDefineDetails
          resourceGroupList={resourceGroups ?? []}
          formData={formData}
          onChange={handleOnChange}
          formValid={checkFormValid(1)}
          heading={t('policyDefineDetails.title')}
        >
          {showErrorSnackbar && renderErrorSnackbar()}
        </PolicyDefineDetails>
        <PolicyAllowConnections
          formValid={checkFormValid()}
          fromItems={availableFromItems as any}
          toItems={availableToItems}
          selectedToItems={selectedToItems}
          selectedFromItems={selectedFromItems}
          selected={directionSelected}
          onViewAllOptions={openViewOptions}
          onRemoveOptions={onRemoveOptions}
          onAddOptions={onAddOptions}
          onSelect={handleSelect}
          onChange={handleOnChange}
          isNwSegmentListLoading={isNwSegmentListLoading}
          networkSegmentList={networkSegmentList ?? []}
          selectedNetworkSegment={selectedNetworkSegment}
          selectedFromType={policyOptionFromType}
        />
      </MultiStepTearSheet>
      {viewAllOptions && (
        <PolicyOptions
          open={viewAllOptions}
          availableData={
            directionSelected === ConnectionSelectedTypes.FROM
              ? availableFromItems
              : availableToItems
          }
          direction={directionSelected}
          selectedData={
            directionSelected === ConnectionSelectedTypes.FROM
              ? selectedFromItems
              : selectedToItems
          }
          onRefresh={(tabName: ConnectionOptionTypes) =>
            handleRefreshData(tabName)
          }
          onClose={onCloseViewOptions}
          selectedNetworkSegment={selectedNetworkSegment}
          permissionMap={permissionMap}
          onChangeFromType={onRadioButtonChange}
          onItemSelect={handleCheckbox}
          selectedFromType={policyOptionFromType}
        />
      )}
    </>
  );
};

export default CreatePolicy;
