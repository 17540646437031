import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComponentProps } from '../config';
import IconWithToolTip from '../../../IconWithToolTip/IconWithToolTip';
import images from '../../../../images/images';
import './ServiceTooltip.scss';

const ServiceTooltip: React.FC<ComponentProps> = ({ node, deploymentType }) => {
  const { t } = useTranslation('topologyTooltipService');

  const getHeaderTitle = () => {
    if (node?.is_discovered) {
      return (
        <div className='header'>
          <span className='header-title'>{node?.name}</span>
          <span className='page-title-icon'>
            <IconWithToolTip
              icon={images.AutoDiscoverdLockIcon()}
              iconDescription={t('autoDiscoveredAccessLimited')}
            />
          </span>
        </div>
      );
    }

    return (
      <div className='header'>
        <span className='header-title'>{node?.name}</span>
      </div>
    );
  };

  return (
    <div className='topology-tooltip-service'>
      {getHeaderTitle()}

      <div className='body'>
        <div className='label'>
          {t('type')} : {deploymentType && t(`${deploymentType as string}`)}
        </div>
      </div>
    </div>
  );
};

export default ServiceTooltip;
